import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { FilterContext, filterOperation } from './FilterForm'
import { setExclusiveValue } from '../../features/lists/stores/exclusiveValue.actions'

export function InputFilter({
	isBooleanCheckbox = false,
	checkbox,
	keyName,
	operation,
	children,
	exclusiveValue,
	exclusiveValueToMatch,
	exclusiveValueDefault,
	setValue,
	defaultValue,
	isAutoComplete,
	isMultiple,
	isDynamicKey = false,
	isTextField = false
}) {
	const filterctx = useContext(FilterContext)

	const filter = filterctx.getFilter()?.find((el) => {
		return isDynamicKey
			? el.key.startsWith(keyName) && el.operation === operation
			: el.key === keyName && el.operation === operation
	})

	const setFilterValue = (newVal) => {
		const mustReset =
			(newVal === '' && isDynamicKey) ||
			(newVal === '' && isTextField) ||
			newVal === undefined ||
			newVal === 'empty' ||
			(Array.isArray(newVal) && (newVal.length === 0 || newVal.includes('empty')))

		const otherFilters = filterctx.getFilter()?.filter((el) => {
			const isSimilarKey = isDynamicKey ? el.key.startsWith(`${keyName}.`) : el.key === keyName
			return !(isSimilarKey && el.operation === operation)
		})

		const nonExclusiveOtherFilters = otherFilters?.filter((el) => el.isExclusive === false)

		const addFilter = () => {
			const filtersToMerge = exclusiveValueToMatch ? nonExclusiveOtherFilters : otherFilters
			const key = isDynamicKey ? `${keyName}.${newVal}` : keyName
			const val = isDynamicKey ? false : newVal
			return filterctx.setFilter([
				...filtersToMerge,
				filterctx.makeFilter(key, operation, val, !!exclusiveValueToMatch)
			])
		}

		if (mustReset) filterctx.setFilter(otherFilters)
		else addFilter()
	}

	function onChange(e, autoCompleteValue) {
		const nextElement = isAutoComplete ? autoCompleteValue : e
		if (exclusiveValue && exclusiveValueToMatch && exclusiveValue !== exclusiveValueDefault)
			setExclusiveValue(exclusiveValueToMatch)
		if (nextElement?.target) {
			if (checkbox || isBooleanCheckbox) {
				let cheboxValue = nextElement.target.checked === true ? true : null
				if (isBooleanCheckbox && cheboxValue === null) cheboxValue = 'empty'
				if (exclusiveValueToMatch) setValue(cheboxValue)
				return setFilterValue(cheboxValue)
			} else {
				const value = nextElement.target.value
				if (exclusiveValueToMatch) setValue(value)
				return setFilterValue(value)
			}
		} else if (isAutoComplete) {
			const value = isMultiple ? nextElement.slice(-10).map((e) => e.value) : autoCompleteValue.value
			return setFilterValue(value)
		} else {
			return setFilterValue(e)
		}
	}

	const emptyValue = isTextField || isDynamicKey ? '' : 'empty'
	let startValue = defaultValue ?? emptyValue

	if (operation === filterOperation.IN_ARRAY || operation === filterOperation.NOT_IN_ARRAY) {
		startValue = defaultValue ? [startValue] : []
	}

	const value =
		filter && filter.value !== undefined
			? isDynamicKey
				? filter.key.split('.').slice(-1)[0]
				: filter.value
			: startValue

	const containedInput = React.cloneElement(React.Children.only(children), { value, onChange })
	return <>{containedInput}</>
}

InputFilter.propTypes = {
	keyName: PropTypes.string.isRequired,
	id: PropTypes.string,
	children: PropTypes.element.isRequired,
	operation: PropTypes.oneOf(Object.values(filterOperation)),
	checkbox: PropTypes.bool,
	exclusiveValue: PropTypes.any,
	exclusiveValueToMatch: PropTypes.any,
	exclusiveValueDefault: PropTypes.any,
	setValue: PropTypes.any,
	defaultValue: PropTypes.any,
	isAutoComplete: PropTypes.bool,
	isMultiple: PropTypes.bool
}

InputFilter.defaultProps = {
	operation: filterOperation.EQUAL,
	checkbox: false
}

export default InputFilter
