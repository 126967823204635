import MenuItem from '@mui/material/MenuItem'
import TextField from '@ui/core/TextField'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

export default function InputSelectTextField({
	label,
	isAssessor,
	isSelfKey,
	isAssessed,
	displayKey,
	multiple,
	children,
	items,
	required,
	control,
	errors,
	rules,
	name,
	defaultValue,
	isClearable = false
}) {
	const getValue = (obj, path) => {
		if (!path) return obj
		const properties = path.split('.')
		return getValue(obj[properties.shift()], properties.join('.'))
	}

	const displayItem = (item) => {
		if (isAssessor) {
			return (
				<MenuItem value={item.id} key={item.id}>
					{item.reverseFullName}
				</MenuItem>
			)
		} else if (isSelfKey) {
			return (
				<MenuItem value={item} key={item}>
					{item}
				</MenuItem>
			)
		} else if (isAssessed) {
			// Find a way to properly register an object value
			return (
				<MenuItem
					value={JSON.stringify({ id: item.userIntegrationId, type: item.offerIntegrationType })}
					key={item.userIntegrationId}
				>
					{`${item.lastname.toUpperCase()} ${item.firstname} - ${item.offerIntegrationLabel} (${
						item.offerIntegrationType
					})`}
				</MenuItem>
			)
		} else {
			return (
				<MenuItem value={item.id} key={item.id}>
					{getValue(item, displayKey)}
				</MenuItem>
			)
		}
	}

	return (
		<Controller
			control={control}
			rules={rules}
			name={name}
			defaultValue={defaultValue}
			render={({ onChange, value }) => {
				return (
					<TextField
						variant="outlined"
						size="small"
						SelectProps={{
							displayEmpty: true,
							multiple,
							endAdornment:
								isClearable && value !== '' ? (
									<IconButton
										sx={{ mr: 3, color: 'grays.gray2', position: 'absolute', right: 0 }}
										onClick={() => onChange('')}
										size="small"
									>
										<ClearIcon />
									</IconButton>
								) : null
						}}
						InputLabelProps={{ shrink: true }}
						select
						label={label}
						error={errors[name]}
						helperText={errors[name] ? errors[name].message : ''}
						onChange={onChange}
						value={value}
						fullWidth
						required={required}
					>
						{/* {labelAll && <MenuItem value=''>{labelAll}</MenuItem>} */}
						{children && children}
						{!children && items.map((item) => displayItem(item))}
					</TextField>
				)
			}}
		/>
	)
}

InputSelectTextField.propTypes = {
	comment: PropTypes.string,
	isEnglish: PropTypes.bool,
	label: PropTypes.string,
	isAssessor: PropTypes.bool,
	isSelfKey: PropTypes.any,
	isAssessed: PropTypes.bool,
	displayKey: PropTypes.string,
	multiple: PropTypes.bool,
	children: PropTypes.any,
	items: PropTypes.array,
	labelAll: PropTypes.string,
	control: PropTypes.any,
	errors: PropTypes.any,
	rules: PropTypes.any,
	name: PropTypes.any,
	defaultValue: PropTypes.any
}
