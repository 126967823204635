import { type ReactElement, useEffect, useState, type MutableRefObject } from 'react'
import { InterviewEvaluationCard } from '@shared/components/cards/InterviewEvaluationCard'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import InterviewFeedback from '../../../modals/pages/InterviewFeedback'
import { InterviewQuestions } from '@domains/interviews/details/InterviewQuestions'
import { Card, Portal, Stack } from '@mui/material'
import { useUpdateInterview } from '@features/details/hooks/useUpdateInterview'
import { useInterviewSkills } from '@features/details/hooks/useInterviewSkills'
import { type Track, type UserInterview, type UserOffer, type UserPosition } from '@gqlTypes'
import { useInterviewState } from '@domains/interviews/details/useInterviewState'
import { ExitFormWarning } from '@shared/components/ExitFormWarning'
import { InterviewGlobalEvaluationCard } from '@shared/components/cards/InterviewGlobalEvaluationCard'
import { ScoreCalculation } from '#types/ScoreCalculation'
import { InterviewTabBar } from '@domains/interviews/details/InterviewTabBar'
import { InterviewSection } from '@domains/interviews/details/InterviewSection'
import { type InterviewFormInput } from '@features/details/types/interviewsForm.type'
import { useScrollToError } from '@shared/hooks/form/useScrollToError'
import { ImageReturnDialog } from './ImageReturnDialog'
import { InterviewObjectives } from '@domains/interviews/objectives/InterviewObjectives'

interface Props {
	interview: UserInterview
	track: Track
	userIntegration: UserOffer | UserPosition
	tabBarContainer: MutableRefObject<null>
}

export const InterviewForm = ({ interview, track, userIntegration, tabBarContainer }: Props): ReactElement | null => {
	const hasObjectives = interview.template?.hasObjectives ?? false
	const { normalSkillList, orgaOnlySkillList, skillScores, skillComments, mandatorySkills } = useInterviewSkills({
		track,
		userIntegration,
		interview
	})
	const { isReadOnly, isNotReady, isEditable } = useInterviewState(interview)
	const hasSkill = normalSkillList.length > 0 || orgaOnlySkillList.length > 0
	const isInterviewSendingFeedback = (track.isInterviewSendingFeedback ?? false) && hasSkill
	const interviewId = interview.id ?? ''
	const globalManualScore = (interview.validatedScore ?? 0) * 4
	const globalCalculatedScore = (interview.calculatedScore ?? 0) * 4
	const scoreCalculation = globalManualScore === 0 ? ScoreCalculation.Average : ScoreCalculation.Manual
	const defaultValues: InterviewFormInput = {
		comment: interview?.comment ?? '',
		globalManualScore,
		globalCalculatedScore,
		skillScores,
		skillComments,
		scoreCalculation,
		isSendingFeedback: false,
		answers: {},
		isDraft: false
	}
	useEffect(() => {
		reset(defaultValues)
	}, [interview])
	const formMethods = useForm({ defaultValues })
	const {
		getValues,
		reset,
		trigger,
		errors,
		formState: { isDirty }
	} = formMethods
	useScrollToError({ errors })
	const { save, saveDraft, isSaving, isSavingDraft, setIsShareModalOpen, isShareModalOpen, handleSave } =
		useUpdateInterview({ interviewId, isInterviewSendingFeedback, getValues, trigger })
	const { t } = useTranslation()
	const [open, setOpen] = useState(false)
	const [skillId, setSkillId] = useState('')
	const onClick = (skillId: string): void => {
		setSkillId(skillId)
		setOpen(true)
	}

	const tabBar = (
		<Portal container={tabBarContainer?.current}>
			<InterviewTabBar
				interview={interview}
				isDirty={isDirty}
				isReadOnly={isReadOnly}
				saveDraft={saveDraft}
				isSaving={isSaving}
				isSavingDraft={isSavingDraft}
				handleSave={handleSave}
			/>
		</Portal>
	)
	if (isNotReady) return tabBar

	const mandatorySkillIds = Object.entries(mandatorySkills)
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		.filter(([_skillId, isMandatory]) => isMandatory)
		.map(([skillId]) => skillId)
	const filledMandatorySkill = mandatorySkillIds.filter((skillId) => skillScores[skillId] > 0).length
	const filledGlobalScore = globalManualScore + globalCalculatedScore > 0 ? 1 : 0
	const filledComments = interview.comment !== '' ? 1 : 0
	const progression =
		((filledMandatorySkill + filledGlobalScore + filledComments) / (mandatorySkillIds.length + 2)) * 100
	const interviewQuestionCategories = interview.questionCategories
	return (
		<FormProvider {...formMethods}>
			<ImageReturnDialog interviewId={interview.id ?? ''} skillId={skillId} open={open} setOpen={setOpen} />
			{tabBar}
			<ExitFormWarning isModified={isDirty} />
			<Stack spacing={3}>
				{hasSkill ? (
					<InterviewSection title={t('interview:skillEvaluation.section')} progression={progression}>
						<Stack spacing={3}>
							<Card elevation={3}>
								<InterviewEvaluationCard
									title={t('translation:interviewDetailPage.normalSkillName')}
									skillList={normalSkillList}
									isReadOnly={isReadOnly}
									onClick={onClick}
								/>
							</Card>
							<Card elevation={3}>
								<InterviewEvaluationCard
									title={t('translation:interviewDetailPage.orgaOnlyName')}
									skillList={orgaOnlySkillList}
									isReadOnly={isReadOnly}
									onClick={onClick}
								/>
								<InterviewGlobalEvaluationCard
									isReadOnly={isReadOnly}
									track={track}
									mandatorySkills={mandatorySkills}
								/>
							</Card>
						</Stack>
					</InterviewSection>
				) : null}
				{hasObjectives ? <InterviewObjectives interviewId={interview.id ?? ''} isReadOnly={isReadOnly} /> : null}
				<InterviewQuestions
					interviewQuestionCategories={interviewQuestionCategories}
					interviewAnswers={interview.answersArray}
					isEditable={isEditable}
				/>
			</Stack>
			<InterviewFeedback open={isShareModalOpen} setOpen={setIsShareModalOpen} onValidate={save} values={getValues} />
		</FormProvider>
	)
}
