import { getCandidacyTabs } from '@shared/components/layout/menus/menus'
import useDetails from '../../../features/details/hooks/useDetails'
import { INTEGRATION_NAME, INTEGRATION_TYPE } from '../../../constants/INTEGRATION_TYPE'

export default function useCandidatesDetails({ userOfferId }) {
	const candidateSubMenuTabs = getCandidacyTabs(userOfferId)
	const details = useDetails(userOfferId, candidateSubMenuTabs, INTEGRATION_TYPE.CANDIDACY)

	return { ...details, integrationName: INTEGRATION_NAME.CANDIDATE }
}
