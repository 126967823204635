import { type ReactElement } from 'react'
import { type GetUserOfferListQuery, useMeQuery } from '@gqlTypes'
import {
	Box,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Stack,
	Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserAvatar } from '@shared/components/UserAvatar'
import { useCandidateUpdate } from './useCandidateUpdate'
import { EditField } from '@domains/students/list/EditField'
import AssessorSelect from '@features/lists/components/cells/AssessorSelect'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'

interface Props {
	offer: GetUserOfferListQuery['getUserOfferList']['nodes'][0]
	handleClose: () => void
	isOpen: boolean
}

export const CandidateCardEditDialog = ({ offer, handleClose, isOpen }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { update } = useCandidateUpdate(offer.id)

	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })
	if (loading || data?.me == null) return null
	const organization = data.me.organization
	const availableStatus = organization.offerStatusObject.map((status) => ({ value: status.id, label: status.label }))
	const userRef = offer.userInfo
	if (userRef == null) return null

	return (
		<Dialog onClose={handleClose} open={isOpen}>
			<DialogCloseButton onClick={handleClose} />
			<DialogTitle color="primary" sx={{ mb: 3, fontWeight: 'bold' }}>
				{t('candidates:update.title')}
			</DialogTitle>
			<DialogContent sx={{ minWidth: 400 }}>
				<Stack direction="row" gap={2} sx={{ mb: 5 }} alignItems="center">
					<UserAvatar user={userRef} />
					<Box>
						<Typography sx={{ fontWeight: 'bold' }}>{userRef.displayName ?? ''}</Typography>
						<Typography>{offer.orgaOffer?.label}</Typography>
						<Typography style={{ fontWeight: 'bold', color: '#0A2E56' }}>{offer.offerStatus?.label ?? ''}</Typography>
					</Box>
				</Stack>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<Stack spacing={3}>
							<EditField
								value={offer.offerStatus?.id ?? ''}
								onSelect={(status) => {
									update({ status })
								}}
								noneValueLabel={t('candidates:cell.status.none')}
								options={availableStatus}
								label={t('candidates:columns.status')}
							/>
							<FormControlLabel
								control={<Checkbox defaultChecked={offer.english ?? false} />}
								label={t('candidates:columns.english')}
								onChange={(_, english) => {
									update({ english })
								}}
							/>
						</Stack>
					</Grid>
					<Grid item xs={12} sm={6}>
						<AssessorSelect
							value={Object.values((offer.assessors as Record<string, string>) ?? {})}
							onSelect={update}
							multiple
						/>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}
