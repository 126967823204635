import { type ReactElement } from 'react'

interface Props {
	page: string
}

export const ProfileResume = ({ page }: Props): ReactElement | null => {
	return (
		<iframe
			style={{
				height: 2800,
				border: 'none',
				width: '100%'
			}}
			src={page}
		></iframe>
	)
}
