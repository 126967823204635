import { type FilterInput } from '@gqlTypes'
import { setFilters as setFiltersFunction } from '@shared/store/actions/filters.actions'
import { type FilterStore, initialState } from '@shared/store/reducers/filters.reducer'
import { initializeSelectedRows as initializeSelectedRowsFunction } from '@shared/store/actions/selectedRows.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

interface UseFilters {
	filters: FilterInput[]
	defaultFilters: FilterInput[]
	setFilters: (filters: FilterInput[]) => void
	reset: (refetch: (variables: { filters: FilterInput[] }) => void) => Promise<void>
	initializeSelectedRows: () => void
	filtersCount: number
}

interface LocationState {
	routeFilters?: FilterInput[]
}

export const useFilters = (): UseFilters => {
	const location = useLocation<LocationState>()
	const dispatch = useDispatch()
	const storeFilters: FilterStore = useSelector(({ filters }: { filters: FilterStore }) => filters)
	const pathname = location.pathname.replaceAll('/', '')

	const currentFilters = storeFilters[pathname]
	const storeDefaultFilters = initialState[pathname]
	const setFilters = (filters: FilterInput[]): void => {
		dispatch(setFiltersFunction({ filters, location: pathname }))
	}
	const reset = async (refetch: (variables: { filters: FilterInput[] }) => void): Promise<void> => {
		setFilters(storeDefaultFilters)
		refetch({ filters: storeDefaultFilters })
	}
	const initializeSelectedRows = (): void => {
		dispatch(initializeSelectedRowsFunction())
	}

	const filtersCount = currentFilters.filter((filter: FilterInput) => filter.value !== 'empty').length

	return {
		filters: currentFilters,
		filtersCount,
		defaultFilters: storeDefaultFilters,
		setFilters,
		reset,
		initializeSelectedRows
	}
}
