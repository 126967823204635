import { Chip } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	value: string
	color: string
}

export const PercentageChip = ({ value, color }: Props): ReactElement | null => {
	const colorAlpha20 = `${color}33`
	if (value === '') return null

	return <Chip size="small" sx={{ mt: 1, color, fontWeight: 'bold', backgroundColor: colorAlpha20 }} label={value} />
}
