import { type ReactElement } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type TrackType, useGetIntegrationsProgressionsQuery } from '@gqlTypes'
import { InterviewCard } from '@domains/interviews/list/InterviewCard'
import { GraphRadar } from '@shared/components/GraphRadar'
import { Stack } from '@mui/system'
import { AutoEvaluationCard } from '@domains/interviews/details/AutoEvaluationCard'
import { ProgressionCard } from '@domains/interviews/Progression/ProgressionCard'
import { Legend } from '@shared/components/GraphRadar/components/Legend'
import { ProgressionSection } from '@domains/interviews/Progression/ProgressionSection'
import { ProgressionLegend } from '@domains/interviews/Progression/ProgressionLegend'

interface Props {
	infos: { integrationInfo: { id: string; userInfo: { displayName: string } } }
	trackType: TrackType
}
export default function IntegrationProgression({ infos, trackType }: Props): ReactElement | null {
	const { t } = useTranslation(['translation'])

	const userIntegrationId = infos.integrationInfo?.id
	const userName = infos.integrationInfo?.userInfo?.displayName

	useDocumentTitle(t('translation:candidates.interview.title', { name: userName }))

	const { data, loading: isLoading } = useGetIntegrationsProgressionsQuery({
		variables: { integrationId: userIntegrationId, trackType }
	})

	if (isLoading) return <LocalLoader />
	if (data?.getIntegrationsProgressions == null) return null
	const userInterviews = data.getIntegrationsProgressions?.interviews
	const autoEvaluations = data.getIntegrationsProgressions?.autoEvaluations
	const progressions = data.getIntegrationsProgressions?.progressions
	const skills = data.getIntegrationsProgressions?.skills

	return (
		<Stack>
			<ProgressionSection
				title={t('interview:progression.radar.title')}
				buttonLabel={t('interview:progression.radar.legend.display')}
				sx={{ mb: 2 }}
				legend={<Legend skills={skills} />}
			>
				<Stack direction="row" alignItems="start" flexWrap="wrap" gap={3}>
					{autoEvaluations.map((evaluation) => (
						<AutoEvaluationCard
							key={evaluation.id}
							userRef={evaluation.userRef}
							graphDimensions={evaluation.graphDimensions}
						/>
					))}

					{userInterviews.map((interview) => (
						<InterviewCard key={interview.id} interview={interview}>
							<GraphRadar dimensions={interview.graphDimensions} size="small" />
						</InterviewCard>
					))}
				</Stack>
			</ProgressionSection>

			<ProgressionSection
				title={t('interview:progression.curve.title')}
				buttonLabel={t('interview:progression.radar.legend.display')}
				sx={{ mt: 5, mb: 1 }}
				legend={<ProgressionLegend />}
			>
				<Stack direction="row" flexWrap="wrap" gap={3} mb={10}>
					{progressions.map((progression) => (
						<ProgressionCard key={progression.id} progression={progression} />
					))}
				</Stack>
			</ProgressionSection>
		</Stack>
	)
}
