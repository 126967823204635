import { firebaseAuth } from '../../../firebase/firebase.helper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { makeStyles } from 'tss-react/mui'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { ROUTE_LOGOUT } from '../../../router/routes'

const useStyles = makeStyles()(() => ({
	container: {
		height: '100vh',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	}
}))

export default function UnauthorizedComponent() {
	const { classes } = useStyles()
	const { t } = useTranslation()
	const history = useHistory()
	const email = firebaseAuth().currentUser.email

	return (
		<div className={classes.container}>
			<Stack spacing={3} alignItems="center">
				<Typography variant="h5">{t('login.accountNotFound.title', { email })}</Typography>
				<Typography>
					{t('login.accountNotFound.message')}{' '}
					<a href="https://emage-me.com/mobile">{t('login.accountNotFound.app')}</a>.
				</Typography>
				<Button variant="contained" onClick={() => history.push(ROUTE_LOGOUT)}>
					{t('login.disconnect')}
				</Button>
			</Stack>
		</div>
	)
}

UnauthorizedComponent.propTypes = {}

UnauthorizedComponent.defaultProps = {}
