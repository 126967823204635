import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import Typography from '@mui/material/Typography'
import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import Slider from '@ui/core/Slider'
import EcosystemMarker from '@shared/components/markers/EcosystemMarker'
import SelfEvaluationMarker from '@shared/components/markers/SelfEvaluationMarker'
import { Stack, TableCell, TableRow } from '@mui/material'
import theme from '@ui/theme'

export default function Indicators({
	indicators,
	color,
	delimiter = false,
	labelColor = '',
	title = '',
	treshhold = 0,
	isHideEmpty = false
}) {
	if (!indicators) return null

	const displayIndicators = indicators.filter((indicator) => !isHideEmpty || indicator.score > 0)

	return (
		<>
			{indicators.length > 0 && title !== '' ? (
				<TableRow>
					<TableCell colSpan={2}>
						<Typography variant="h6" sx={{ fontWeight: 'bold' }}>
							{title}
						</Typography>
					</TableCell>
				</TableRow>
			) : null}
			{displayIndicators.map((skill) => {
				const secondaryColor = theme.palette.secondary.main
				const warningColor = theme.palette.warning.main
				let trackColor = skill.score > skill.delimiter ? secondaryColor : warningColor
				if (treshhold > 0) trackColor = skill.score >= treshhold ? secondaryColor : warningColor
				if (skill.score === 0) trackColor = 'grey'

				return (
					<TableRow key={skill.id}>
						<InfoLayoutLabel light color={labelColor !== '' ? labelColor : color}>
							{skill.label}
						</InfoLayoutLabel>
						<InfoLayoutValue>
							<Stack spacing={1}>
								<Slider
									min={0}
									max={5}
									readOnly
									color={color}
									value={skill.score}
									delimiter={delimiter ? skill.delimiter : null}
									trackColor={trackColor}
									markers={
										<>
											<EcosystemMarker value={skill.restitutionScore} />
											<SelfEvaluationMarker value={skill.selfEvaluationScore} />
										</>
									}
								/>
							</Stack>
						</InfoLayoutValue>
					</TableRow>
				)
			})}
		</>
	)
}
