export default function buildQuery(queryRef, filterList) {
	let queryCopy = queryRef
	filterList.map((filter) => {
		if (filter.value !== undefined) {
			const key = filter.key === 'assessors' ? filter.key + `.${filter.value}` : filter.key
			let value
			if (filter.key === 'alignment') {
				value = buildAlignmentArray(filter.value)
			} else if (filter.key === 'read') {
				value = !filter.value
			} else {
				value = filter.value
			}
			queryCopy = queryCopy.where(key, filter.operation, value)
		}
		return null
	})
	return queryCopy
}

export function buildPayload(field, newValue) {
	const payload = {}
	payload[field] = newValue
	return payload
}

function buildAlignmentArray(value) {
	let arr = []
	for (let i = 0; i < value + 1; i++) {
		arr = [...arr, i]
	}
	return arr
}
