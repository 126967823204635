import { makeStyles } from 'tss-react/mui'
import { useState } from 'react'
import Slider from '@ui/core/Slider'
import IconButton from '@mui/material/IconButton'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'

const useDimensionValueStyles = makeStyles()((theme) => ({
	dimensionDescription: {
		marginTop: 15,
		marginBottom: 15
	},
	sliderContainer: {
		display: 'flex',
		justifyContent: 'stretch',
		alignItems: 'center'
	},
	slider: {
		flex: 1
	},
	expandButton: {
		marginLeft: 10,
		color: theme.palette.grays.gray2
	}
}))

export default function DimensionSlider({ score, startLabel, endLabel, description }) {
	const { classes } = useDimensionValueStyles()
	const [isExpanded, setExpanded] = useState(false)

	const min = -0.5
	const max = 0.5
	const value = [score, 0].sort()

	const gradientDirection = score < 0 ? 'left' : 'right'

	return (
		<div>
			<div className={classes.sliderContainer}>
				<div className={classes.slider}>
					<Slider
						value={value}
						readOnly
						min={min}
						max={max}
						startLabel={startLabel}
						endLabel={endLabel}
						gradientDirection={gradientDirection}
					/>
				</div>
				{description && (
					<IconButton className={classes.expandButton} size="small" onClick={() => setExpanded(!isExpanded)}>
						<HelpOutlineIcon />
					</IconButton>
				)}
			</div>
			<Collapse in={isExpanded}>
				<Typography variant="body2" class={classes.dimensionDescription}>
					{description}
				</Typography>
			</Collapse>
		</div>
	)
}
