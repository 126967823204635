import { makeStyles } from 'tss-react/mui'
import { useLocation, useHistory } from 'react-router-dom'
import { generateRoute } from '../../../../router/routes'
import { type ReactElement } from 'react'

interface Props {
	title: string
	url: string
}

const TrackButton = ({ title, url }: Props): ReactElement => {
	const location = useLocation().pathname
	const disabled = false
	const isCurrentUrl: boolean = url === location
	const { classes } = useStyle({ disabled, isCurrentUrl })
	const history = useHistory()

	const onClick = (): void => {
		if (disabled) return
		history.push(generateRoute(url) as string)
	}

	return (
		<div
			className={classes.root}
			onClick={() => {
				onClick()
			}}
		>
			{title}
		</div>
	)
}

export default TrackButton

interface Style {
	isCurrentUrl: boolean
	disabled: boolean
}
const useStyle = makeStyles<Style>()((theme, { disabled, isCurrentUrl }) => {
	const cursor = disabled ? undefined : 'pointer'
	const color = isCurrentUrl ? undefined : theme.palette.grays.gray2
	return {
		root: {
			cursor,
			color,
			marginLeft: 35,
			marginRight: 35
		}
	}
})
