import { type ReactElement } from 'react'
import { type GetUserOfferJobMatchingQuery } from '@gqlTypes'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { UserCard } from '@shared/components/cards/UserCard'
import { CurrentLocation } from '@domains/students/list/CurrentLocation'
import { JobMatchingStatus } from '../JobMatchingStatus'
import { generateRoute } from '../../../../../src/router/routes'
import { OffersPath } from '@domains/offers/OffersRouter'
import { UserAvatarProgress } from './UserAvatarProgress'
import { DurationLabel } from '../DurationLabel'

interface Props {
	jobMatching: GetUserOfferJobMatchingQuery['getUserOfferJobMatching'][0]['jobMatching']
	userOffer: GetUserOfferJobMatchingQuery['getUserOfferJobMatching'][0]['userOffer']
	jobSearch: GetUserOfferJobMatchingQuery['getUserOfferJobMatching'][0]['jobSearch']
	trackOfferId: string
	statusId: string
	isSelected?: boolean
	selectRow?: () => void
}

export const JobMatchingCard = ({ jobMatching, userOffer, jobSearch, trackOfferId, statusId }: Props): ReactElement => {
	const { t } = useTranslation()
	const school = userOffer.businessUnit !== '' ? userOffer.businessUnit : userOffer.organization?.name ?? ''
	const { jobDurationLabels, startMonthLabel } = jobSearch

	return (
		<UserCard
			userRef={userOffer.userInfo}
			actions={() => <JobMatchingStatus jobMatchingId={jobMatching?.id ?? ''} {...jobMatching.status} />}
			to={generateRoute(OffersPath.matchingBrowser, {
				trackOfferId,
				statusId: (statusId ?? '') === '' ? 'all' : statusId,
				jobMatchingId: jobMatching?.id,
				userId: userOffer?.userInfo?.userId
			})}
			linkLabel={t('offers:list.browserButton')}
			avatarComponent={<UserAvatarProgress userRef={userOffer.userInfo} progress={jobMatching.score} />}
		>
			<Typography sx={{ fontSize: '16px', color: 'grays.gray5' }}>{userOffer.orgaOffer?.label}</Typography>
			<CurrentLocation businessUnit={school} location={userOffer.location} />
			<DurationLabel jobDurationLabels={jobDurationLabels} startMonthLabel={startMonthLabel} />
		</UserCard>
	)
}
