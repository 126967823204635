import FilterForm from '@ui/filter/FilterForm'
import { useFilters } from '@shared/hooks/useFilters'

export default function FilterBarGQL({ refetch, children }) {
	const { filters, setFilters, initializeSelectedRows } = useFilters()

	return (
		<FilterForm
			onSubmit={() => {
				initializeSelectedRows()
				refetch({ filters })
			}}
			onChange={(filter) => setFilters(filter)}
			defaultValue={filters}
			value={filters}
		>
			{children}
		</FilterForm>
	)
}
