import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { TableLayout } from '@ui/core/TableLayout'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'
import Legend from './Legend'
import { StarLabel } from './StarLabel'
import Indicators from './Indicators'
import { Box, Typography } from '@mui/material'

export default function SoftskillsIndicatorsCard({
	isLoading,
	aboveIndicators,
	belowIndicators,
	unevaluatedIndicators,
	hasLegend,
	alignment,
	sharedSoftSkillsTest,
	sharedSoftSkillsReturn,
	interviewScore,
	isUnavailable,
	type,
	organizationSkillsAverage,
	title
}) {
	const { t } = useTranslation()

	return (
		<Box sx={{ pageBreakBefore: 'always' }}>
			<CardLayout title={title}>
				<LocalLoader load={isLoading}>
					{isUnavailable ? (
						<InfoCardContent>
							<Typography>{t('translation:candidates.profile.softSkills.unavailable')}</Typography>
						</InfoCardContent>
					) : (
						<TableLayout>
							{alignment && (
								<StarLabel
									title={t('translation:candidates.profile.softSkills.alignment')}
									value={alignment ? alignment?.length - 1 : null}
									isAvailable={sharedSoftSkillsTest}
									unAvailableLabel={t('translation:candidates.profile.softSkills.unshared')}
								/>
							)}
							{interviewScore && (
								<StarLabel
									title={t('translation:candidates.profile.softSkills.evaluation')}
									isAvailable
									value={interviewScore ?? null}
								/>
							)}
							{organizationSkillsAverage && (
								<StarLabel
									title={t('translation:candidates.profile.softSkills.synthesis')}
									isAvailable
									value={organizationSkillsAverage ?? null}
								/>
							)}
							<Indicators
								title={t('translation:candidates.profile.softSkills.mainStrength')}
								indicators={aboveIndicators}
								color="secondary"
								type={type}
								delimiter
							/>
							<Indicators
								title={t('translation:candidates.profile.softSkills.mainWeaknesses')}
								indicators={belowIndicators}
								color="primary"
								type={type}
								delimiter
							/>
							<Indicators
								title={t('translation:candidates.profile.softSkills.unevaluated')}
								indicators={unevaluatedIndicators}
								type={type}
								delimiter
							/>
						</TableLayout>
					)}
					{hasLegend && (
						<Legend isSoftskillReturnShared={sharedSoftSkillsReturn} isSoftskillTestShared={sharedSoftSkillsTest} />
					)}
				</LocalLoader>
			</CardLayout>
		</Box>
	)
}
