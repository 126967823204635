import { type InterviewAttachment, useAddInterviewAttachmentMutation } from '@gqlTypes'
import { useStorage } from '@shared/storage/hooks/useStorage'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useInterviewAttachmentFilePath } from './useInterviewAttachmentFilePath'
import { Severities } from '../../../../constants/Severities'
import { handleAsyncSubmit } from '#helpers/form.helper'

interface UseUploadInterviewAttachment {
	upload: (files: FileList | null) => void
}

interface Props {
	attachments: InterviewAttachment[]
	organizationId: string
	connectedUserId: string
	interviewId: string
}

export const useUploadInterviewAttachment = (props: Props): UseUploadInterviewAttachment => {
	const { attachments, organizationId, connectedUserId, interviewId } = props
	const { uploadFiles, getStorageUrl } = useStorage()
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const { filePath } = useInterviewAttachmentFilePath({ organizationId, connectedUserId, interviewId })
	const [addInterviewAttachment, { error: addAttachmentError }] = useAddInterviewAttachmentMutation()

	const upload = async (files: FileList | null): Promise<void> => {
		if (files === null) return

		const fileName = files[0].name
		const variables = { fileName, interviewId }
		const isFileNameTaken = attachments.some((attachment) => attachment.fileName === fileName)
		if (isFileNameTaken) {
			enqueueSnackbar(t('translation:attachment.error.uploadSameName', { fileName }), { variant: Severities.error })
			return
		}

		const updatedAttachments = attachments.concat([
			{
				fileUrl: getStorageUrl(`${filePath}/${fileName}`),
				fileName,
				authorId: connectedUserId,
				uploadDate: new Date(),
				__typename: 'InterviewAttachment'
			}
		])

		const attachmentsObject = updatedAttachments.map((attachment) => ({
			...attachment,
			__typename: 'InterviewAttachment' as const
		}))

		const [{ hasUploadingError }] = await Promise.all([
			uploadFiles(files, filePath),
			addInterviewAttachment({
				variables,
				optimisticResponse: {
					addInterviewAttachment: {
						__typename: 'UserInterview',
						id: interviewId,
						attachmentsObject
					}
				}
			})
		])

		if (hasUploadingError || addAttachmentError != null) {
			enqueueSnackbar(t('translation:attachment.error.upload', { fileName }), { variant: Severities.error })
		}
	}

	return {
		upload: (files) => {
			handleAsyncSubmit(upload, files)
		}
	}
}
