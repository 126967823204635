import { InfoCardContent } from '@ui/core/InfoCardContent'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'
import { type ReactElement } from 'react'

interface Props {
	profilingId: string
	profileId: string
}

export const CommunicationKeyCard = ({ profilingId, profileId }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const content = t(`profileProfiling:profileProfiling.${profilingId}-${profileId}.howToCommunicate`)

	if (profileId == null || profilingId == null) return null

	return (
		<CardLayout title={t('translation:candidates.profile.communicationKeys.title')}>
			<InfoCardContent>{content}</InfoCardContent>
		</CardLayout>
	)
}
