import { useGetAssessorsQuery } from '@gqlTypes'
import { Popper, Autocomplete, type PopperProps } from '@mui/material'
import TextField from '@ui/core/TextField'
import { useTranslation } from 'react-i18next'

interface Props {
	value: string | string[]
	onSelect: (select: Record<string, unknown>) => unknown
	firstname?: string
	lastname?: string
	multiple: boolean
}

interface Assessor {
	id: string
	reverseFullName: string
	firstname: string
	lastname: string
}

export default function AssessorSelect({
	value,
	onSelect,
	firstname = '',
	lastname = '',
	multiple = false
}: Props): JSX.Element {
	const { data } = useGetAssessorsQuery()
	const assessors = [...(data?.getAssessors ?? [])]

	const { t } = useTranslation()
	const computeArraySelection = (values: Assessor): Assessor[] => {
		const valueMap = values ?? []
		if (!Array.isArray(valueMap)) return []

		return valueMap.reduce((acc: Assessor[], nextValue: string) => {
			const result = assessors.find((assessor: Assessor) => assessor.id === nextValue)
			return result != null ? [...acc, result] : acc
		}, [])
	}

	let selectedAssessor = multiple
		? computeArraySelection(value)
		: assessors.find((assessor: Assessor) => assessor.id === value)

	if (selectedAssessor === undefined) {
		const defaultAssessor = { id: value, firstname, lastname, reverseFullName: `${firstname} ${lastname}` }
		assessors.push(defaultAssessor)
		selectedAssessor = defaultAssessor
	}

	assessors.sort((a, b) => a.reverseFullName.localeCompare(b.reverseFullName))

	const onChange = (newValue: Assessor): void => {
		onSelect({ juryId: newValue.id })
	}

	const onMultiChange = (newValue: Assessor[]): void => {
		const payload: Record<string, string> = {}
		newValue.map((assessor) => (payload[assessor.id] = assessor.id))
		onSelect({ assessors: payload })
	}

	const AutocompletePopper = (props: PopperProps): JSX.Element => {
		return <Popper {...props} style={{ maxWidth: 'fit-content' }} placement="bottom-start" />
	}

	return (
		<Autocomplete
			multiple={multiple}
			limitTags={-1}
			options={assessors}
			PopperComponent={AutocompletePopper}
			getOptionLabel={(option) => option.reverseFullName}
			onChange={(_: unknown, newValue: Assessor[]) => {
				multiple ? onMultiChange(newValue) : onChange(newValue)
			}}
			value={selectedAssessor}
			renderInput={(params) => <TextField {...params} label={t('interviews:columns.assessor')} variant="standard" />}
			style={{ width: 200 }}
			disableClearable
			renderOption={(props, option) => {
				return (
					<li {...props} key={option.id}>
						{option.reverseFullName}
					</li>
				)
			}}
		/>
	)
}
