import { makeStyles } from 'tss-react/mui'
import { useRef, useEffect } from 'react'
import clsx from 'clsx'

const useStyle = makeStyles()(() => ({
	root: {
		overflowY: 'auto',
		overflowX: 'hidden',
		paddingTop: 100
	}
}))

export function Conversation(props) {
	const { className } = props
	const { classes } = useStyle(props)
	const scrollContentRef = useRef(null)

	useEffect(() => {
		if (scrollContentRef.current) {
			const el = scrollContentRef.current
			el.scrollTop = el.scrollHeight
		}
	}, [props.children, scrollContentRef])

	return (
		<div {...props} ref={scrollContentRef} className={clsx(className, classes.root)}>
			{props.children}
		</div>
	)
}

export default Conversation
