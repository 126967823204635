import { Chip, Stack } from '@mui/material'
import { useOrganization } from '@domains/organization/useOrganization'

export default function OrgaSkillSection({ skills, setSkills }) {
	const { orgaSkillsData } = useOrganization()
	const isSelected = (skill) => skills[skill]

	const onSelect = (skill) => {
		const currentSkill = skills[skill]
		setSkills({ ...skills, [skill]: !currentSkill })
	}

	return (
		<Stack direction="row" gap={1} flexWrap="wrap">
			{orgaSkillsData.map((skill) => (
				<Chip
					key={skill.id}
					label={skill.label}
					color={isSelected(skill.id) ? 'primary' : 'default'}
					onClick={() => onSelect(skill.id)}
					variant="outlined"
				/>
			))}
		</Stack>
	)
}
