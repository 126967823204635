import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import TextField from '@ui/core/TextField'
import Button from '@mui/material/Button'
import { DialogSection } from '@shared/components/modal/dialogSection'

export default function CommentSection({ onValidate, userIds, label }) {
	const [comment, setComment] = useState('')
	const { t } = useTranslation(['userOfferStatus', 'lists'])

	const validate = () => {
		if (userIds) onValidate(userIds, comment)
		else onValidate('comment', comment)
	}

	return (
		<DialogSection vertical>
			<TextField
				label={label}
				variant="outlined"
				fullWidth
				multiline
				onChange={(event) => setComment(event.target.value)}
				rows={4}
			/>
			<div style={{ textAlign: 'right', width: '100%' }}>
				<Button disabled={!comment} onClick={() => validate()}>
					{t('modal:massUpdate.update')}
				</Button>
			</div>
		</DialogSection>
	)
}
