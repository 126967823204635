import { Card, CardContent, Typography, CircularProgress } from '@mui/material'
import { Logo } from '@ui/brand/Logo'
import type app from 'firebase/compat/app'
import { useAuthWithEmailVerificationCodeMutation, useSignInFromInterviewQuery } from '@gqlTypes'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { firebaseAuth } from '../../../firebase/firebase.helper'
import { ValidationCodeInput } from '../components/ValidationCodeInput'
import { useSnackbar } from 'notistack'
import { Severities } from '../../../constants/Severities'
import { useState } from 'react'

declare global {
	interface Window {
		recaptchaVerifier: app.auth.RecaptchaVerifier
	}
}

export const MobileAuth: React.FC = () => {
	const { interviewId } = useParams<{ interviewId: string }>()
	const [isCodeSent, setIsCodeSent] = useState(false)
	const { t } = useTranslation()
	const history = useHistory()
	const variables = { interviewId }
	const { data, loading: isLoading } = useSignInFromInterviewQuery({ variables })
	const [authWithEmailVerificationCode] = useAuthWithEmailVerificationCodeMutation()
	const { enqueueSnackbar } = useSnackbar()

	const sendCode = (code: string): void => {
		if (code.length < 6 || isCodeSent) return
		;(async () => {
			setIsCodeSent(true)
			const email = data?.signInFromInterview?.email ?? ''
			const result = await authWithEmailVerificationCode({ variables: { email, code } })
			const token: string = result.data?.authWithEmailVerificationCode ?? ''
			await firebaseAuth().signInWithCustomToken(token)
			const integrationType: string = data?.signInFromInterview?.integrationType ?? ''
			const userIntegrationId: string = data?.signInFromInterview?.userIntegrationId ?? ''

			history.push(`/interview/${integrationType}/${userIntegrationId}/${interviewId}`)
		})().catch(() => {
			setIsCodeSent(false)
			enqueueSnackbar(t('auth:errors.badVerificationCode.message'), { variant: Severities.error })
		})
	}

	return (
		<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 60 }}>
			<Logo />
			<Typography component="p" gutterBottom style={{ marginTop: 20 }}>
				{t('auth:codeValidation')}
			</Typography>
			<Card elevation={3} style={{ marginTop: 20, width: 332 }}>
				<CardContent style={{ alignItems: 'center' }}>
					<div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 20 }}>
						{isLoading ? <CircularProgress /> : <ValidationCodeInput sendCode={sendCode} />}
					</div>
				</CardContent>
			</Card>
		</div>
	)
}
