import { useTranslation } from 'react-i18next'
import { filterOperation } from '@ui/filter/FilterForm'
import InputFilter from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import moment from 'moment'

export const StartMonthFilter = (): ReactElement => {
	const { t } = useTranslation()

	const startMonthValues = Array.from(Array(12).keys()).map((_, index) => {
		const date = moment().add(index, 'months')
		return { value: date.format('YYYY-MM'), label: date.format('MMMM YYYY') }
	})

	return (
		<InputFilter keyName="startMonthIds" operation={filterOperation.EQUAL} isAutoComplete>
			<AutoCompleteFilter options={startMonthValues} label={t('lists:filter.startMonth.label')} />
		</InputFilter>
	)
}
