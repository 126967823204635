import { ConversationMessageContainer } from '@ui/conversation/ConversationMessageContainer'

import ConversationTimestamp from '@ui/conversation/ConversationTimestamp'
import { MESSAGE_TYPE } from '../constants/MESSAGE_TYPE'
import { ImageReturnMessage } from '@domains/discussions/tab/ImageReturnMessage'

export function buildDiscussion(discussionUser, messages, t, displayName) {
	if (messages.length === 0 || !discussionUser) {
		return [<div key={1}>{t('discussionDetailPage.noMessages', { displayName })}</div>]
	}

	const { userId, userRef, recipientRef } = discussionUser

	let latest = 0

	const messageContent = (message) => {
		switch (message.type) {
			case MESSAGE_TYPE.SOFTSKILL_REQUEST:
			case MESSAGE_TYPE.ORGASKILL_REQUEST:
				return t('discussionDetailPage.feedbackRequest')
			case MESSAGE_TYPE.ORGASKILL_RESPONSE:
				return (
					<ImageReturnMessage
						skillId={message.softSkillId}
						globalScore={message.score}
						globalComment={message.decrypted}
						imageReturnResponseId={message.imageReturnResponseId}
					/>
				)
			default:
				return message.decrypted
		}
	}

	return messages
		.filter((message) => message.type !== MESSAGE_TYPE.SOFTSKILL_RESPONSE)
		.map((message) => {
			let isTimeStamp = false
			// Check if there is a 12h difference between 2 messages
			if (latest === 0 || Math.abs(latest - message.creationDate.seconds) > 43200) {
				isTimeStamp = true
				latest = message.creationDate.seconds
			}

			const creationDate = message.creationDate.toDate()

			return (
				<div key={message.id}>
					{isTimeStamp && <ConversationTimestamp date={creationDate} />}
					<ConversationMessageContainer
						date={creationDate}
						variant={message.senderId === userId ? 'self' : 'other'}
						author={message.senderId === userId ? userRef.displayName : recipientRef.displayName}
					>
						{messageContent(message)}
					</ConversationMessageContainer>
				</div>
			)
		})
}
