import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { useCallback } from 'react'

const useStyle = makeStyles()((theme) => ({
	root: {
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		textTransform: 'none'
	},
	icon: {
		marginRight: 9,
		height: '1em',
		fontSize: '1.5em',
		lineHeight: 1,

		'$checked &': {
			color: theme.palette.success.main
		},
		'$unchecked &': {
			color: theme.palette.grays.gray2
		}
	},
	checked: {},
	unchecked: {},
	checkedText: { color: theme.palette.success.main },
	unCheckedText: { color: theme.palette.grays.gray2 }
}))

export function BooleanInput(props) {
	const { value } = props
	const { trueLabel, falseLabel, label, noLabel, readOnly, onChange, icon, checkedIcon } = props
	const { classes } = useStyle()
	const { t } = useTranslation(['translation'])

	let finalLabel = ''

	if (value) finalLabel = trueLabel || label || t('translation:booleanIndicator.trueLabel')
	else finalLabel = falseLabel || label || t('translation:booleanIndicator.falseLabel')

	const handleChange = useCallback(() => {
		if (onChange) onChange()
	}, [onChange])

	return (
		<div className={clsx(classes.root, value ? classes.checked : classes.unchecked)}>
			<Checkbox
				className={classes.icon}
				icon={icon || <CloseIcon fontSize="inherit" />}
				checkedIcon={checkedIcon || <CheckIcon fontSize="inherit" />}
				checked={value}
				disabled={readOnly}
				onChange={handleChange}
			/>
			<Typography component="div" className={clsx(value ? classes.checkedText : classes.unCheckedText)}>
				{!noLabel && finalLabel}
			</Typography>
		</div>
	)
}

BooleanInput.propTypes = {
	checkedIcon: PropTypes.element,
	falseLabel: PropTypes.node,
	icon: PropTypes.element,
	label: PropTypes.node,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool,
	trueLabel: PropTypes.node,
	noLabel: PropTypes.node,
	value: PropTypes.bool,
	IconComponent: PropTypes.elementType,
	CloseIconComponent: PropTypes.elementType
}

BooleanInput.defaultProps = {
	IconComponent: CheckIcon,
	CloseIconComponent: CloseIcon
}

export default BooleanInput
