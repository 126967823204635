import { Checkbox } from '@mui/material'
import { type ChangeEvent, type ReactElement } from 'react'

interface Props {
	value?: boolean | 'empty'
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export const CheckboxFilter = ({ value, onChange = () => null }: Props): ReactElement => {
	const isCkecked = value === 'empty' ? false : value
	return <Checkbox checked={isCkecked} value={value} onChange={onChange} />
}
