import { ErrorHandler } from '#helpers/error.helper'
import { type ApolloError } from '@apollo/client/errors'
import { type UpdateUserOfferInput, useUpdateUserOfferMutation } from '@gqlTypes'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Severities } from '../../../constants/Severities'

interface UseOfferUpdate {
	update: (userOfferInput: UpdateUserOfferInput) => void
}

export const useCandidateUpdate = (userOfferId: string): UseOfferUpdate => {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const [updateUserOffer] = useUpdateUserOfferMutation()

	const update = (userOfferInput: UpdateUserOfferInput): void => {
		;(async () => {
			const optimisticResponse = {
				updateUserOffer: {
					__typename: 'UserOffer' as const,
					id: userOfferId,
					...userOfferInput
				}
			}
			await updateUserOffer({ variables: { userOfferId, userOfferInput }, onCompleted, onError, optimisticResponse })
		})().catch(updateUserOffer)
	}

	const onCompleted = (): void => {
		enqueueSnackbar(t('candidates:update.success'), { variant: Severities.success })
	}
	const onError = (err: ApolloError): void => {
		enqueueSnackbar(t('candidates:update.error'), { variant: Severities.error })
		ErrorHandler(err)
	}

	return { update }
}
