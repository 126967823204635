import { type ReactElement, type ReactNode } from 'react'
import { Chip, Stack, Typography, ButtonBase } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useToggle } from '@shared/hooks/useToggle'
import { Box } from '@mui/system'

interface Props {
	progression?: number
	title: string
	isDisplay?: boolean
	children: ReactNode
	isLabelGreen?: boolean
}

export const InterviewSection = ({
	title,
	progression,
	children,
	isDisplay = true,
	isLabelGreen = false
}: Props): ReactElement | null => {
	const [isOpen, toggleOpen] = useToggle(true)
	const iconSize = { width: 28, height: 28 }
	const color = progression === 100 || isLabelGreen ? 'secondary' : 'warning'

	if (!isDisplay) return null

	return (
		<>
			<ButtonBase onClick={toggleOpen} disableRipple sx={{ alignSelf: 'start' }}>
				<Stack spacing={1} direction="row" alignItems="center">
					{isOpen ? (
						<KeyboardArrowDownIcon className="no-print" sx={iconSize} />
					) : (
						<ChevronRightIcon className="no-print" sx={iconSize} />
					)}
					<Typography sx={{ fontSize: '24px', color: 'primary.main', fontWeight: 'bold' }}>{title}</Typography>
					{progression !== undefined ? (
						<Chip variant="twoTone" label={`${Math.round(progression)}%`} size="small" color={color} />
					) : null}
				</Stack>
			</ButtonBase>
			<Box sx={{ display: isOpen ? 'block' : 'none' }}>{children}</Box>
		</>
	)
}
