import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import { LocalDate } from '../core/LocalDate'
import clsx from 'clsx'

const useStyle = makeStyles()((theme) => ({
	root: {
		textAlign: 'center',
		color: theme.palette.grays.gray2
	}
}))

export function ConversationTimestamp(props) {
	const { date, className } = props
	const { classes } = useStyle(props)

	return (
		<Typography component="div" variant="body2" className={clsx(className, classes.root)}>
			<LocalDate date={date} dateStyle="full" />
			{' • '}
			<LocalDate date={date} dateStyle="none" timeStyle="short" />
		</Typography>
	)
}

ConversationTimestamp.propTypes = {
	date: PropTypes.instanceOf(Date).isRequired
}

export default ConversationTimestamp
