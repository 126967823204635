import { type ReactElement } from 'react'
import { Stack, Typography } from '@mui/material'
import MDIIcon from '@shared/components/Icon/MDIIcon'

interface Props {
	assessorFullName: string
	isValid: boolean
}

export const CurrentExternalAssessor = ({ assessorFullName, isValid }: Props): ReactElement | null => {
	const color = isValid ? 'grays.gray5' : 'orange'

	if (assessorFullName === '') return null
	return (
		<Stack direction="row" gap={1} alignItems="center">
			<MDIIcon color={color} name="account-tie" size={18} />
			<Typography sx={{ fontSize: '16px', color }}>{assessorFullName}</Typography>
		</Stack>
	)
}
