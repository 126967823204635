import { type InterviewAttachment, useDeleteInterviewAttachmentMutation } from '@gqlTypes'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { Severities } from '../../../../constants/Severities'
import { handleAsyncSubmit } from '#helpers/form.helper'

interface UseDeleteInterviewAttachment {
	remove: (fileName: string) => void
}

interface Props {
	attachments: InterviewAttachment[]
	interviewId: string
}

export const useDeleteInterviewAttachment = (props: Props): UseDeleteInterviewAttachment => {
	const { attachments, interviewId } = props
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const [deleteInterviewAttachment, { error: deleteAttachmentError }] = useDeleteInterviewAttachmentMutation()

	const remove = async (fileName: string): Promise<void> => {
		const attachmentsObject = attachments.filter(({ fileName: attachmentFileName }) => attachmentFileName !== fileName)

		await deleteInterviewAttachment({
			variables: { interviewId, fileName },
			optimisticResponse: {
				deleteInterviewAttachment: {
					__typename: 'UserInterview',
					id: interviewId,
					attachmentsObject
				}
			}
		})

		if (deleteAttachmentError != null) {
			enqueueSnackbar(t('translation:attachment.error.delete', { fileName }), { variant: Severities.error })
		}
	}

	return {
		remove: (fileName) => {
			handleAsyncSubmit(remove, fileName)
		}
	}
}
