import { SharedReturn } from './SharedReturn'
import { useTranslation } from 'react-i18next'
import { Stack, Typography } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	sharedReturns: {
		id: string
		senderRef: {
			displayName: string
		}
		creationDate: string
		score: number
		relationType: {
			label: string
		}
		message: string
	}[]
}

export const SharedReturnList = ({ sharedReturns }: Props): ReactElement | null => {
	const { t } = useTranslation()
	if (sharedReturns.length === 0) return null

	return (
		<Stack spacing={5}>
			<Typography sx={{ fontWeight: 'bold', fontSize: 18, color: 'primary.main' }}>
				{t('sharedReturnTab:sharedReturnList.title', { count: sharedReturns.length })}
			</Typography>
			<Stack spacing={4}>
				{sharedReturns.map((imageReturn) => (
					<SharedReturn
						key={imageReturn.id}
						senderRef={imageReturn.senderRef}
						date={imageReturn.creationDate}
						score={imageReturn.score}
						relationType={imageReturn.relationType.label}
						comment={imageReturn.message}
					/>
				))}
			</Stack>
		</Stack>
	)
}
