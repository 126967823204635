import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import { useState, useRef, createContext, useEffect, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import Collapse from '@mui/material/Collapse'

const EXPANDED_CLASS = 'emageme-expanded'

const ShowMoreContext = createContext({
	collapsedHeight: 0,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setSummaryHeight(height) {}
})

const useStyle = makeStyles()(() => ({
	root: {},
	content: {
		position: 'relative',

		'& > *': {
			opacity: 0,
			transition: 'opacity 0.3s linear'
		},

		[`.${EXPANDED_CLASS} & > *`]: {
			opacity: 1
		}
	}
}))

export function ShowMore({ expandText, collapseText, boxStyle, textStyle, isExpandedByDefault, ...props }) {
	const { classes } = useStyle(props)
	const { t } = useTranslation(['translation'])
	const [isExpanded, setExpanded] = useState(isExpandedByDefault ?? false)
	const [summaryHeight, setSummaryHeight] = useState(0)

	return (
		<div className={clsx(props.className, classes.root, isExpanded ? EXPANDED_CLASS : null)} style={{ ...boxStyle }}>
			<ShowMoreContext.Provider value={{ collapsedHeight: summaryHeight, setSummaryHeight }}>
				<Collapse in={isExpanded} collapsedHeight={summaryHeight}>
					<div className={classes.content}>{props.children}</div>
				</Collapse>
				<Typography
					sx={{
						background: 'transparent',
						border: 'none',
						cursor: 'pointer',
						padding: 0,
						margin: 0,
						marginTop: 10
					}}
					className="no-print"
					variant="button"
					style={{ ...textStyle }}
					component="button"
					onClick={() => setExpanded(!isExpanded)}
				>
					{isExpanded
						? t(collapseText ?? 'translation:showMore.collapseLabel')
						: t(expandText ?? 'translation:showMore.expandLabel')}
				</Typography>
			</ShowMoreContext.Provider>
		</div>
	)
}

export default ShowMore

const useSummaryStyle = makeStyles()(() => ({
	root: {
		display: 'unset !important',
		opacity: '1 !important',
		transition: 'opacity 0.3s linear',

		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',

		[`.${EXPANDED_CLASS} &`]: {
			opacity: '0 !important',
			pointerEvents: 'none',
			transitionDelay: '0.5s'
		}
	}
}))

export function ShowMoreSummary(props) {
	const containerRef = useRef(null)
	const { classes } = useSummaryStyle(props)
	const ctx = useContext(ShowMoreContext)

	useEffect(() => {
		if (containerRef.current) {
			const height = containerRef.current.getBoundingClientRect().height
			if (height !== ctx.collapsedHeight) {
				ctx.setSummaryHeight(height)
			}
		}
	})

	return (
		<div className={clsx(props.className, classes.root)} ref={containerRef}>
			{props.children}
		</div>
	)
}
