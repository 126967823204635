import CardLayout from '../core/CardLayout'
import TextField from '../core/TextField'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import clsx from 'clsx'
import SendIcon from '@mui/icons-material/Send'
import Tooltip from '@mui/material/Tooltip'
import { useState } from 'react'
import Conversation from '../conversation/Conversation'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	container: {
		paddingLeft: 15,
		paddingRight: 15,
		flex: 1,
		overflow: 'auto'
	},
	form: {
		background: theme.palette.darkBlue.light,
		color: 'white',
		padding: '10px 25px',

		display: 'flex',
		alignItems: 'center',
		flexShrink: 0
	},
	textField: {
		background: 'rgba(255,255,255,0.9)',
		borderRadius: 5,
		flex: 1,
		marginRight: 25
	}
}))

export function ConversationFrame(props) {
	const { onNewMessage, className, loading } = props
	const { t } = useTranslation(['translation'])
	const { classes } = useStyle(props)
	const [typedMessage, setTypedMessage] = useState('')

	const divProps = { ...props }
	delete divProps.onNewMessage

	function handleSubmit(e) {
		e.preventDefault()

		if (typedMessage.length < 1) {
			return
		}

		if (onNewMessage) {
			onNewMessage(typedMessage)
		}

		setTypedMessage('')
	}

	return (
		<CardLayout {...divProps} className={clsx(classes.root, className)}>
			<Conversation className={classes.container}>{props.children}</Conversation>
			<form className={classes.form} onSubmit={handleSubmit}>
				<TextField
					disabled={loading}
					className={classes.textField}
					variant="outlined"
					fullWidth
					placeholder={t('translation:conversationFrame.sendMessage')}
					value={typedMessage}
					onChange={(e) => setTypedMessage(e.target.value)}
				/>
				<div style={{ width: 48, height: 48 }}>
					<LocalLoader load={loading}>
						<Tooltip title={t('translation:conversationFrame.sendMessageTooltip')}>
							<IconButton type="submit" disabled={loading} color="inherit">
								<SendIcon />
							</IconButton>
						</Tooltip>
					</LocalLoader>
				</div>
			</form>
		</CardLayout>
	)
}

export default ConversationFrame
