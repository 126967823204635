import { Button, Card, Grid, Stack, Typography } from '@mui/material'
import { type GetMenuTilesQuery } from '@gqlTypes'
import MDIIcon from '@shared/components/Icon/MDIIcon'
import { Link } from 'react-router-dom'
import { type ReactElement } from 'react'
import { TileIndicator } from './TileIndicator'

interface Props {
	tile: GetMenuTilesQuery['getMenuTiles'][0]
}

export const OrganizationTile = ({ tile: { title, icon, localizedUrl, color, indicators } }: Props): ReactElement => {
	const colorAlpha20 = `${color}33`

	return (
		<Link to={localizedUrl}>
			<Button sx={{ padding: 0, margin: '12px', fontWeight: 400, borderRadius: '25px' }}>
				<Card sx={{ height: 160, borderRadius: '25px', width: 310, padding: '20px' }} elevation={3}>
					<Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}>
						<Stack
							sx={{
								width: 50,
								height: 50,
								backgroundColor: colorAlpha20,
								borderRadius: '20px',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<MDIIcon color={color} name={icon} size={30} />
						</Stack>
						<Typography sx={{ color: '#000', fontSize: 20, fontWeight: 'bold' }}>{title}</Typography>
					</Stack>
					<Grid container sx={{ mt: 1, textAlign: 'left' }}>
						{indicators.map((indicator) => (
							<TileIndicator pathname={localizedUrl} indicator={indicator} key={title} />
						))}
					</Grid>
				</Card>
			</Button>
		</Link>
	)
}
