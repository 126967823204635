import { SET_EXCLUSIVE_VALUE } from './exclusiveValue.actions'
import { INTEGRATION_TYPE } from '../../../constants/INTEGRATION_TYPE'

const initialState = INTEGRATION_TYPE.CANDIDACY

const exclusiveValue = function (state = initialState, action) {
	switch (action.type) {
		case SET_EXCLUSIVE_VALUE: {
			return action.payload
		}
		default: {
			return state
		}
	}
}

export default exclusiveValue
