import { Divider, InputAdornment, MenuItem, Select, Typography } from '@mui/material'
import CountryCodes, { defaultCountryIndex, FavoriteCountryIndices, IndexByCode } from './data/coutry-codes'
import TextField from '../core/TextField'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Emoji } from './Emoji'
import { makeStyles } from 'tss-react/mui'

const useSelectStyle = makeStyles()((theme) => ({
	dialCode: {
		color: theme.palette.grays.gray2
	}
}))

function CountryCodeSelector(props) {
	const { classes } = useSelectStyle(props)

	function makeMenuItem(el, keyPrefix = '') {
		return (
			<MenuItem value={el.code} key={keyPrefix + '' + el.code}>
				<Emoji>
					<Typography>
						{el.emoji} {el.name} <span className={classes.dialCode}>{el.dialCode}</span>
					</Typography>
				</Emoji>
			</MenuItem>
		)
	}

	const favoritesItems = FavoriteCountryIndices.map((el) => CountryCodes[el]).map((el) => makeMenuItem(el, 'favorite'))
	const items = CountryCodes.filter((_, i) => FavoriteCountryIndices.indexOf(i) < 0).map((el) => makeMenuItem(el))

	function renderValue(val) {
		const index = IndexByCode[val]
		if (index && CountryCodes[index]) {
			const country = CountryCodes[index]
			return <Emoji>{country.emoji || country.name}</Emoji>
		}
		return <></>
	}

	return (
		<Select
			{...props}
			renderValue={renderValue}
			disableUnderline
			variant="outlined"
			sx={{
				boxShadow: 'none',
				'.MuiOutlinedInput-notchedOutline': { borderWidth: 0 },
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderWidth: 0 }
			}}
		>
			{favoritesItems}
			<Divider />
			{items}
		</Select>
	)
}

const useStyle = makeStyles()((theme) => ({
	dialCode: {
		color: theme.palette.grays.gray3
	}
}))

export function PhoneInput(props) {
	const { value = '', onChange } = props
	const { classes } = useStyle(props)

	const [country, setCountry] = useState(CountryCodes[defaultCountryIndex])

	let valueWithoutPrefix = value

	if (country.dialCode) {
		valueWithoutPrefix = valueWithoutPrefix.replace(new RegExp('^' + country.dialCode.replace('+', '\\+')), '')
	}

	function setValue(newValue, forcePrefix) {
		const prefix = forcePrefix || country?.dialCode || ''

		newValue = newValue.replace(/^0+/, '').replace(' ', '')

		let fullValue = prefix + '' + newValue

		if (newValue === '') {
			fullValue = newValue
		}

		if (onChange) {
			onChange(fullValue)
		}
	}

	function setNewCountry(countryCode) {
		const country = CountryCodes[IndexByCode[countryCode]]
		setValue(valueWithoutPrefix, country.dialCode)
		setCountry(country)
	}

	const startAdornment = (
		<InputAdornment position="start">
			<CountryCodeSelector value={country?.code || ''} onChange={(e) => setNewCountry(e.target.value)} />{' '}
			<span className={classes.dialCode}>{country.dialCode}</span>
		</InputAdornment>
	)

	return (
		<TextField
			{...props}
			InputProps={{ startAdornment }}
			onChange={(e) => setValue(e.target.value)}
			value={valueWithoutPrefix}
			sx={{ backgroundColor: 'blue' }}
		/>
	)
}

PhoneInput.propTypes = {
	...TextField.propTypes,
	onChange: PropTypes.func
}

PhoneInput.defaultProps = {
	label: 'Phone number'
}

export default PhoneInput
