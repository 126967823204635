import { InfoCardContent } from '@ui/core/InfoCardContent'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import CardLayout from '@ui/core/CardLayout'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme) => ({
	personalityProfileCard: {
		display: 'flex',

		'& .avatar': {
			width: 150,
			height: 150,
			marginBottom: 25,
			marginRight: 50,
			backgroundColor: theme.palette.secondary.main,
			fontSize: 50
		},

		'& .descriptionPanel': {
			flex: 1,

			'& > *': {
				marginBottom: 10
			}
		}
	}
}))

export default function PersonalityProfileCard(props) {
	const { avatar, profile, highestDimensions: traits } = props

	const { classes } = useStyles(props)
	const { t } = useTranslation(['translation', 'dimension', 'profile'])

	if (!profile) return null

	return (
		<CardLayout title={t('translation:candidates.profile.personalityProfile.title')}>
			<InfoCardContent className={classes.personalityProfileCard}>
				{avatar.url !== '' && (
					<div className="avatarPanel">
						<Avatar className="avatar" src={avatar.url} />
						<Typography>{avatar.name}</Typography>
						<Typography variant="body2">{avatar.description}</Typography>
					</div>
				)}
				<div className="descriptionPanel">
					<Typography variant="h6" sx={{ color: 'lightBlue.main' }}>
						{t('translation:candidates.profile.personalityProfile.profileLabel', { name: profile.name })}
					</Typography>
					<Typography>
						<strong>{t('translation:candidates.profile.personalityProfile.traits', { traits })}</strong>
					</Typography>
					<Typography>{profile.description}</Typography>
				</div>
			</InfoCardContent>
		</CardLayout>
	)
}
