import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

const useStyle = makeStyles()((theme) => ({
	root: {
		width: '100%'
	},
	notSupported: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',

		background: theme.palette.grays.gray1,
		borderRadius: 30,

		'& > *': {
			marginBottom: '0.5em'
		}
	},
	notSupportedIcon: {
		fontSize: '3em',
		color: theme.palette.grays.gray2
	}
}))

export function VideoEmbed(props) {
	const { t } = useTranslation(['translation'])
	const { classes } = useStyle(props)

	const { src } = props
	const url = new URL(src)

	let videoId = ''

	if (url.hostname.endsWith('youtube.com') && url.searchParams.has('v')) {
		videoId = url.searchParams.get('v')
	} else if (url.hostname.endsWith('youtube.com') && url.pathname.startsWith('/embed/')) {
		videoId = url.pathname.replace(/^\/embed\//, '')
	} else if (url.hostname === 'youtu.be') {
		videoId = url.pathname.replace(/^\//, '')
	}

	const embedUrl = `https://www.youtube.com/embed/${videoId}`

	return (
		<div {...props} className={classes.root}>
			{!videoId && (
				<div className={classes.notSupported}>
					<div className={classes.notSupportedIcon}>
						<VideocamOffIcon fontSize="inherit" />
					</div>
					<Typography>{t('translation:videoEmbed.notSupported')}</Typography>
					<Typography variant="body2" component="a" href={src} target="_blank">
						{t('translation:videoEmbed.notSupportedLink', {
							url: src
						})}
					</Typography>
				</div>
			)}
			{videoId && (
				<iframe
					title="video"
					src={embedUrl}
					width="100%"
					height="100%"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			)}
		</div>
	)
}

export default VideoEmbed
