import Slider from '@ui/core/Slider'
import theme from '@ui/theme'
import { Box, Stack, Typography } from '@mui/material'
import { ScoreEmoji } from './ScoreEmoji'

export default function DetailIndicator({ details }) {
	return details.map((detail) => {
		return (
			<Box key={detail.id}>
				<Typography sx={{ wordBreak: 'break-word', fontSize: 14, ml: 2, mr: '50px' }}>
					{detail.questionLabel}
				</Typography>
				<Stack direction="row" alignItems="center" spacing={2} sx={{ mb: 1, mt: -1, ml: 2 }}>
					<Slider
						sx={{ flex: 1, display: 'flex' }}
						min={0}
						max={5}
						readOnly
						value={detail.score}
						size={4}
						trackColor={theme.palette.grays.gray2}
					/>
					<ScoreEmoji score={detail.score} fontSize={14} textAlign="end" width={'42px'} />
				</Stack>
			</Box>
		)
	})
}
