import TextField from '@ui/core/TextField'

export default function FeedbackMessageSection({ label, message, setMessage }) {
	return (
		<TextField
			label={label}
			variant="outlined"
			fullWidth
			multiline
			onChange={(event) => setMessage(event.target.value)}
			rows={8}
			value={message}
		/>
	)
}
