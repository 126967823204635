import { useGetMenuTilesQuery } from '@gqlTypes'
import { OrganizationTile } from '../components/OrganizationTile'
import { type ReactElement } from 'react'

export const OrganizationTiles = (): ReactElement | null => {
	const { data, loading: isLoading } = useGetMenuTilesQuery({ fetchPolicy: 'cache-and-network' })
	if (isLoading || data?.getMenuTiles == null) return null
	const tiles = data?.getMenuTiles

	return (
		<>
			{tiles.map((tile) => (
				<OrganizationTile tile={tile} key={tile.id} />
			))}
		</>
	)
}
