import { useEffect, useState } from 'react'
import { Stack, ThemeProvider } from '@mui/material'
import theme from './ui/theme'
import GlobalLoader from './shared/components/layout/GlobalLoader'
import MyStoreProvider, { history } from './shared/store/MyStoreProvider'
import GlobalLoaderFeedback from './shared/components/layout/GlobalLoaderFeedback'
import EnvIndicator from './shared/components/EnvIndicator'
import RouterHandler from './router/RouterHandler'
import initTranslations from './translations/translation.helper'
import { LocalLoader } from './shared/components/layout/LocalLoader'
import ErrorBoundary from './shared/components/errors/ErrorBoundary'
import Auth from './features/login/Auth'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { ConnectedRouter } from 'connected-react-router'
import { version } from '../package.json'
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider, from, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { ErrorHandler } from './helper/error.helper'
import i18next from 'i18next'
import { firebaseAuth } from './firebase/firebase.helper'
import { MainToolbar } from './domains/mainToolbar/MainToolbar'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { MuiSnackbarProvider } from './shared/snackbar/MuiSnackbarProvider'
import { MinimumVersionProvider } from './application/version/MinimumVersionProvider'

const uri = import.meta.env.VITE_GRAPHQL_ENDPOINT
const httpLink = createHttpLink({ uri })
const removeTypenameLink = removeTypenameFromVariables()

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		const { operationName, variables } = operation
		Bugsnag.leaveBreadcrumb(`GQL ${operationName}`, { variables }, 'error')
		graphQLErrors.forEach(({ message, path, extensions }) => {
			console.error({ message, path })
			Bugsnag.notify(new Error(message, path), function (event) {
				event.errors[0].errorClass = `GraphQL ${path}`
				const stacktrace = extensions.stacktrace ?? []
				event.errors[0].stacktrace = stacktrace.map((row) => {
					return { file: row }
				})
			})
		})
	}
	if (networkError) ErrorHandler('Network error', networkError)
})

const bugsnagLink = new ApolloLink((operation, forward) => {
	const { operationName, variables } = operation
	Bugsnag.leaveBreadcrumb(`GQL ${operationName}`, { variables }, 'request')
	return forward(operation)
})

const authLink = setContext(async (_, { headers }) => {
	let token = ''
	const user = firebaseAuth().currentUser
	if (user) token = await user.getIdToken()

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
			language: i18next.language
		}
	}
})

const paginate = {
	merge(existing, incoming, { readField }) {
		const nodes = existing ? { ...existing.nodes } : {}
		incoming.nodes.forEach((node) => {
			nodes[readField('id', node)] = node
		})
		return {
			cursor: incoming.cursor,
			hasNextPage: incoming.hasNextPage,
			count: incoming.count,
			nodes
		}
	},

	read(existing) {
		if (existing) {
			return {
				cursor: existing.cursor,
				hasNextPage: existing.hasNextPage,
				nodes: Object.values(existing.nodes),
				count: existing.count
			}
		}
	}
}

const cache = new InMemoryCache({
	typePolicies: {
		SerializedSkillDetails: {
			keyFields: ['id', 'score', 'questionLabel']
		},
		MotivationQuestion: {
			keyFields: ['id', 'answer']
		},
		UserRef: {
			keyFields: ['userId']
		},
		Query: {
			fields: {
				getUserInterviewList: paginate,
				getEmployeesList: paginate,
				getCommunityMembersList: paginate,
				getStudentsList: paginate,
				getTrackOfferList: paginate,
				getTrackPositionList: paginate,
				getUserOfferList: paginate,
				getDiscussionUserList: paginate,
				getOrganizationUserList: paginate,
				getInterviewTemplateList: paginate,
				getOfferList: paginate,
				getJobSearchList: paginate
			}
		}
	}
})

const client = new ApolloClient({
	link: from([removeTypenameLink, authLink, bugsnagLink, errorLink, httpLink]),
	cache
})

Bugsnag.start({
	apiKey: 'abecf56eda38e52dc9828a4c92e03366',
	plugins: [new BugsnagPluginReact()],
	appVersion: version,
	releaseStage: import.meta.env.VITE_RELEASE_STAGE
})

export function getHeaderHeight() {
	return 64
}

function App() {
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		initTranslations().then(() => {
			setLoading(false)
		})
	}, [])

	if (loading) {
		return <LocalLoader />
	}
	return (
		<ApolloProvider client={client}>
			<ThemeProvider theme={theme}>
				<MyStoreProvider>
					<MuiSnackbarProvider>
						<ErrorBoundary>
							<ConnectedRouter history={history}>
								<MinimumVersionProvider>
									<Auth>
										<Stack direction="row" flex={1} gap={1}>
											<MainToolbar />
											<>
												<GlobalLoaderFeedback />
												<RouterHandler />
											</>
										</Stack>
									</Auth>
									<EnvIndicator />
								</MinimumVersionProvider>
							</ConnectedRouter>
							<GlobalLoader />
						</ErrorBoundary>
					</MuiSnackbarProvider>
				</MyStoreProvider>
			</ThemeProvider>
		</ApolloProvider>
	)
}

export default App
