import { useUpdateUserOfferMutation } from '@gqlTypes'
import useInformations from '@features/details/hooks/useInformations'

export const useCandidatesInformations = (integrationInfo) => {
	const [updateUserOffer] = useUpdateUserOfferMutation()
	const query = async (userOfferId, userOfferInput) => {
		await updateUserOffer({ variables: { userOfferId, userOfferInput } })
	}
	return useInformations(integrationInfo, query, 'orgaOffer')
}
