import { type ReactElement } from 'react'
import { Logo } from '@ui/brand/Logo'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/system'
import { Alert, AlertTitle, Card, CardContent } from '@mui/material'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import { ROUTE_REQUEST_PASSWORD } from '../../../router/routes'

export const InvalidPasswordToken = (): ReactElement => {
	const { t } = useTranslation()

	return (
		<Stack spacing={2} alignItems="center" mt={2}>
			<Logo />
			<Card elevation={3}>
				<CardContent>
					<Alert severity="error">
						<AlertTitle>
							<b>{t('auth:errors.invalidPasswordToken.title')}</b>
						</AlertTitle>
						{t('auth:errors.invalidPasswordToken.message')}
					</Alert>
				</CardContent>
			</Card>
			<Button variant="contained" component={Link} to={ROUTE_REQUEST_PASSWORD}>
				{t('translation:login.forgottenPwd.button')}
			</Button>
		</Stack>
	)
}
