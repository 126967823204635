import MassMessages from '../../../features/modals/pages/MassMessages'
import { MassReminders } from '../../../features/modals/pages/MassReminders'
import { CheckboxInput } from '../../../features/lists/components/Filters/CheckboxInput'
import { Fullname } from '../../../features/lists/components/Filters/Fullname'
import { useSelector } from 'react-redux'
import MassFeedback from '../../../features/modals/pages/MassFeedback'
import { TrackPositionFilter } from '../../../features/lists/components/Filters/TrackPositionFilter'
import BusinessUnitFilter from '../../../features/lists/components/Filters/BusinessUnitFilter'
import ManagersFilter from '../../../features/lists/components/Filters/ManagersFilter'
import { ExportGQL } from '../../../features/modals/components/actions/ExportGQL'
import {
	EmployeeExportDocument,
	IntegrationCategory,
	TrackType,
	useUpdateManyUserIntegrationsMutation
} from '@gqlTypes'
import { MassInterviewsCreation } from '@domains/interviews/createMany/MassInterviewsCreation'
import { InterviewTypeStateFilter } from '@features/lists/components/Filters/InterviewTypeStateFilter'
import { MassUpdatesGql } from '@features/modals/pages/MassUpdatesGql'
import { LeftBar } from '@features/lists/components/LeftBar'
import { Filters } from '@features/lists/components/Filters'
import { Divider } from '@mui/material'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import LocationFilter from '@features/lists/components/Filters/LocationsFilter'
import { useTranslation } from 'react-i18next'
import RiskFilter from '@features/lists/components/Filters/RiskFilter'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { useOrganization } from '@domains/organization/useOrganization'
import { useRole } from '@shared/hooks/useRole'

export default function EmployeesFilterBar({ isAllRowsSelected, refetch, dataList, count }) {
	const { t } = useTranslation()
	const { hasOrgaSkills } = useOrganization()
	const isRestrictedAccess = useSelector(({ userB }) => userB.isRestrictedAccess)
	const [updateManyUserIntegrations, { loading }] = useUpdateManyUserIntegrationsMutation()
	const { screens } = useRole()

	const massAction = {
		trackType: TrackType.Position,
		integrationCategory: IntegrationCategory.Company,
		isAllRowsSelected,
		dataList
	}

	return (
		<LeftBar>
			<MassInterviewsCreation {...massAction} />
			{screens.employees.hasAdminAccess ? (
				<MassUpdatesGql
					{...massAction}
					query={updateManyUserIntegrations}
					refetch={refetch}
					isLoading={loading}
					count={count}
				/>
			) : null}

			<MassMessages {...massAction} count={count} />
			<MassReminders {...massAction} />
			{hasOrgaSkills ? <MassFeedback {...massAction} count={count} /> : null}
			<ExportGQL isAllRowsSelected={isAllRowsSelected} dataList={dataList} QUERY={EmployeeExportDocument} />
			<Filters refetch={refetch}>
				<FiltersContent>
					<Fullname />
					<TextFilter keyName="userInfo.externalId" label={t('lists:filter.externalId')} />
				</FiltersContent>
				<Divider />
				<FiltersContent>
					<TrackPositionFilter keyName="orgaPosition.id" />
					<BusinessUnitFilter />
					{!isRestrictedAccess && <ManagersFilter />}
					<CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" />
					<CheckboxInput keyName="isActive" translationKey="lists:filter.isActive" />
					<InterviewTypeStateFilter />
					<LocationFilter label={t(`lists:filter.site.label`)} allValueLabel={t(`lists:filter.site.allLabel`)} />
					<RiskFilter />
				</FiltersContent>
			</Filters>
		</LeftBar>
	)
}
