import { useState } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import InterviewsFilterBar from './InterviewsFilterBar'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { ScreenId, useGetUserInterviewListQuery, useMeQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { Container } from '@mui/material'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import { CardList } from '@features/lists/components/CardList'
import { InterviewCard } from './InterviewCard'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { searchQuery } from '@features/lists/components/Search'

export default function Interviews() {
	const { data: meData } = useMeQuery()
	const { t } = useTranslation(['lists'])
	useDocumentTitle(t('translation:interviewList.title'))
	useCheckRoleSecurity(ScreenId.Interviews)
	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { filters } = useFilters()
	const variables = {
		limit: 12,
		filters: filters.filter((filter) => filter.value !== 'empty'),
		cursor: '',
		searchQuery: searchQuery()
	}
	const isInterviewLabelAvailable = meData?.me.organization.isInterviewLabelAvailable ?? false

	const { loading, data, refetch, fetchMore } = useGetUserInterviewListQuery({ variables })
	useDefaultFilter({ refetch })

	let nodes = []
	let cursor = ''
	let hasNextPage = false
	let count = 0
	if (data && data.getUserInterviewList) ({ nodes, cursor, hasNextPage, count } = data.getUserInterviewList)

	return (
		<Container maxWidth="xl">
			<CardList
				emptyStateLabel={t('interviews:dataGrid.empty')}
				renderItem={(item, isSelected, selectRow) => (
					<InterviewCard
						key={item.id}
						interview={item}
						isSelected={isSelected}
						selectRow={selectRow}
						isInterviewLabelAvailable={isInterviewLabelAvailable}
					/>
				)}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				refetch={refetch}
				hasSearch
				cursor={cursor}
				hasNextPage={hasNextPage}
				dataTestId="interview-datatable"
				title={t('interviews:title', { count })}
				Icon={RecordVoiceOverIcon}
				toolbar={
					<InterviewsFilterBar isAllRowsSelected={isAllRowsSelected} refetch={refetch} dataList={nodes} count={count} />
				}
			/>
		</Container>
	)
}
