import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import ButtonBase, { type ButtonBaseProps } from '@mui/material/ButtonBase'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { type ReactElement } from 'react'

interface Props extends ButtonBaseProps {
	simple: boolean
}

const useStyles = makeStyles()((theme) => ({
	root: {
		padding: 15,
		borderRadius: 0,
		width: '100%',
		textAlign: 'left',
		boxSizing: 'border-box',
		borderBottom: `solid 1px ${theme.palette.grays.gray1}`,

		'&:last-child': {
			borderBottom: 'none'
		}
	}
}))

export const CardMenuButton = ({ children, simple, ...props }: Props): ReactElement => {
	const { classes } = useStyles()

	return (
		<ButtonBase {...props} className={classes.root}>
			<Typography
				variant="button"
				component="div"
				style={{
					fontWeight: 'normal',
					textAlign: 'unset',
					flex: 1
				}}
			>
				{children}
			</Typography>
			{!simple && (
				<div
					style={{
						fontSize: 25,
						height: 25,
						marginLeft: 10
					}}
				>
					<ChevronRightIcon fontSize="inherit" />
				</div>
			)}
		</ButtonBase>
	)
}
