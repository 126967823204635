import { type ReactElement } from 'react'
import { type GetUserOfferListQuery, useGetAssessorsQuery, type GetAssessorsQuery } from '@gqlTypes'
import { Typography, Stack } from '@mui/material'
import { StepsOverview } from '@features/lists/components/cells/StepsOverview'
import { generateRoute, ROUTE_CANDIDATE_DETAIL } from '../../../router/routes'
import { useTranslation } from 'react-i18next'
import { UserCard } from '@shared/components/cards/UserCard'
import { OfferStatus } from './OfferStatus'
import { CandidateCardActions } from './CandidateCardActions'
import { InterviewAssessor } from '@domains/interviews/list/InterviewAssessor'
import { indexById } from '#helpers/array.helper'

interface Props {
	offer: GetUserOfferListQuery['getUserOfferList']['nodes'][0]
	isSelected?: boolean
	selectRow?: () => void
}

export const CandidateCard = ({ offer, isSelected, selectRow }: Props): ReactElement => {
	const { t } = useTranslation()
	const { data } = useGetAssessorsQuery()
	const assessors: GetAssessorsQuery['getAssessors'][0][] = data?.getAssessors ?? []
	const indexedAssessors = indexById<GetAssessorsQuery['getAssessors'][0]>(assessors)
	const offerAssessors: Record<string, string> = offer.assessors

	const getAssessorsFullName = (assessorIds: Record<string, string>): string => {
		return Object.values(assessorIds)
			.map((assessorId) => {
				return indexedAssessors[assessorId]?.reverseFullName ?? ''
			})
			.filter((name) => name !== '')
			.join(', ')
	}

	const renderActions = (isHovered: boolean): ReactElement => {
		return <CandidateCardActions offer={offer} isSelected={isSelected} selectRow={selectRow} isHovered={isHovered} />
	}

	return (
		<UserCard
			userRef={offer.userInfo}
			actions={renderActions}
			to={generateRoute(ROUTE_CANDIDATE_DETAIL, { userOfferId: offer.id })}
			linkLabel={t('candidates:card.open')}
			hasLink={offer.offerStatus?.orgaFullAccess}
		>
			<Stack spacing={2}>
				<Typography sx={{ fontSize: '16px', color: 'grays.gray5' }}>{offer.orgaOffer?.label}</Typography>
				<StepsOverview steps={offer.stepsIntegrations} />
				<Stack spacing={1} minHeight={80}>
					<OfferStatus status={offer?.offerStatus?.label ?? ''} />
					<InterviewAssessor assessorFullName={getAssessorsFullName(offerAssessors)} />
				</Stack>
			</Stack>
		</UserCard>
	)
}
