import { type ReactElement } from 'react'
import {
	ROUTE_COMMUNITY_MEMBERS,
	ROUTE_COMMUNITY_MEMBERS_TAB_RETURNS,
	ROUTE_COMMUNITY_MEMBERS_DETAIL,
	ROUTE_COMMUNITY_MEMBERS_TAB_DISCUSSION,
	ROUTE_COMMUNITY_MEMBERS_TAB_INFO,
	ROUTE_COMMUNITY_MEMBERS_DETAIL_TAB_PROGRESSION,
	ROUTE_COMMUNITY_MEMBERS_TAB_ORGA_RETURN,
	ROUTE_COMMUNITY_MEMBERS_TAB_PROFILE
} from '../../../router/routes'
import UserPositionsDetails from '../../../features/details/pages/userPositions/UserPositionsDetails'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCommunityMembersDetails } from './useCommunityMembersDetails'

export const CommunityMembersDetails = (): ReactElement => {
	const { t } = useTranslation()
	const profilePath = ROUTE_COMMUNITY_MEMBERS_TAB_PROFILE
	const discussionPath = ROUTE_COMMUNITY_MEMBERS_TAB_DISCUSSION
	const progressionPath = ROUTE_COMMUNITY_MEMBERS_DETAIL_TAB_PROGRESSION
	const returnsPath = ROUTE_COMMUNITY_MEMBERS_TAB_RETURNS
	const informationsPath = [ROUTE_COMMUNITY_MEMBERS_DETAIL, ROUTE_COMMUNITY_MEMBERS_TAB_INFO]
	const orgaReturnPath = ROUTE_COMMUNITY_MEMBERS_TAB_ORGA_RETURN
	const backRoute = ROUTE_COMMUNITY_MEMBERS
	const backToolTip = t('menu.employees')

	const { userPositionId } = useParams<{ userPositionId: string }>()
	const infos = useCommunityMembersDetails(userPositionId)

	return (
		<UserPositionsDetails
			progressionPath={progressionPath}
			profilePath={profilePath}
			discussionPath={discussionPath}
			informationsPath={informationsPath}
			returnsPath={returnsPath}
			infos={infos}
			userPositionId={userPositionId}
			orgaReturnPath={orgaReturnPath}
			backRoute={backRoute}
			backToolTip={backToolTip}
		/>
	)
}
