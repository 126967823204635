import { useState } from 'react'
import { setUserBContext } from '@shared/store/actions/userB.action'
import { setRoleContext } from '@shared/store/actions/role.action'
import { useDispatch } from 'react-redux'
import Bugsnag from '@bugsnag/js'
import { setInterviewFields } from '@shared/store/actions/interviewFields.action'
import { indexByProperty } from '../../helper/array.helper'
import { useMeLazyQuery } from '@gqlTypes'
import i18next from 'i18next'

export const AuthStates = {
	anonymous: 'anonymous',
	firebaseAuthenticated: 'firebaseAuthenticated',
	emagemeAuthenticated: 'emagemeAuthenticated',
	onlyMobile: 'onlyMobile'
}

export const useAuth = () => {
	const [loadUserContext, setLoadUserContext] = useState(true)
	const [authState, setAuthState] = useState(AuthStates.anonymous)
	const [language, setLanguage] = useState('fr')
	const dispatch = useDispatch()

	const onCompleted = async (data) => {
		const me = data.me
		Bugsnag.addMetadata('User', 'data', me)
		if (me && me.active) {
			Bugsnag.leaveBreadcrumb('UserB found and active')
			setAuthState(me.isMobileOnly ? AuthStates.onlyMobile : AuthStates.emagemeAuthenticated)
			setLanguage(me.language)
		} else {
			Bugsnag.leaveBreadcrumb('UserB found but deactivate')
		}

		const { id, active, firstname, lastname, visioLink, isRestrictedAccess } = me

		// Indexing in front end en to prevent JSON typing in gql
		const indexedFields = indexByProperty(me.organization.interviewFields, 'id')
		dispatch(setRoleContext(me.role))
		dispatch(setInterviewFields(indexedFields))
		dispatch(setUserBContext({ id, active, firstname, lastname, visioLink, isRestrictedAccess }))

		setLoadUserContext(false)
		await i18next.changeLanguage(me.language)
	}

	const onError = () => {
		setLoadUserContext(false)
	}

	const [me] = useMeLazyQuery({ fetchPolicy: 'cache-and-network', onCompleted, onError })

	return { me, loadUserContext, authState, language, setAuthState, setLoadUserContext }
}
