import { type ReactElement } from 'react'
import { Link } from '@shared/components/links/Link'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface Props {
	recipientSlug: string
	senderId: string
	senderCreationDateTimeStamp: string
}

export const FeedbackButton = ({ recipientSlug, senderCreationDateTimeStamp, senderId }: Props): ReactElement => {
	const { t } = useTranslation()

	const urlPrefix = import.meta.env.VITE_WEB_PUBLIC_URL
	const feedbackUrl = `${urlPrefix}f/s/${recipientSlug}/${senderId}/${senderCreationDateTimeStamp}`

	return (
		<Link isExternal url={feedbackUrl}>
			<Button variant="contained" color="secondary">
				{t('translation:candidates.informations.feedback')}
			</Button>
		</Link>
	)
}
