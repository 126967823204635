import { type ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useWatch } from 'react-hook-form'
import useDeepCompareEffect from 'use-deep-compare-effect'
import debounce from 'lodash.debounce'

interface Props {
	isDirty: boolean
	isDraft: boolean
	isSavingDraft: boolean
	isSaving: boolean
	saveDraft: (isDisplayFeedback?: boolean) => void
}

export const SaveDraftButton = ({
	isDirty,
	isDraft,
	isSavingDraft,
	isSaving,
	saveDraft
}: Props): ReactElement | null => {
	const { t } = useTranslation()
	const debouncedChangeHandler = useCallback(
		debounce(() => {
			saveDraft(false)
		}, 10000),
		[]
	)
	const watchedData = useWatch({})

	const manualSave = (): void => {
		debouncedChangeHandler.cancel()
		saveDraft()
	}

	useDeepCompareEffect(() => {
		if (isDirty && isDraft && !isSavingDraft) debouncedChangeHandler()
	}, [watchedData])

	useEffect(() => {
		if (!isDraft || isSaving) debouncedChangeHandler.cancel()

		return () => {
			debouncedChangeHandler.cancel()
		}
	}, [isSaving, isDraft])

	if (!isDraft || isSaving) return null

	return (
		<Tooltip title={t('translation:interviewDetailPage.registerDraftTooltip')}>
			<LoadingButton loading={isSavingDraft} onClick={manualSave} variant="contained">
				{t('translation:interviewDetailPage.registerDraft')}
			</LoadingButton>
		</Tooltip>
	)
}
