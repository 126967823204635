import { Route, Switch, useLocation, useParams } from 'react-router-dom'
import useCandidatesDetails from './useCandidatesDetails'
import {
	ROUTE_CANDIDATES,
	ROUTE_CANDIDATE_DETAIL,
	ROUTE_CANDIDATE_DETAIL_TAB_DISCUSSION,
	ROUTE_CANDIDATE_DETAIL_TAB_INFO,
	ROUTE_CANDIDATE_DETAIL_TAB_PROGRESSION,
	ROUTE_CANDIDATE_DETAIL_TAB_ORGA_RETURN,
	ROUTE_CANDIDATE_DETAIL_TAB_PROFILE,
	ROUTE_CANDIDATE_DETAIL_TAB_RETURNS,
	ROUTE_CANDIDATE_DETAIL_TAB_CAREER
} from '../../../router/routes'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import TabBar from '@features/details/components/TabBar'
import Discussion from '@domains/discussions/tab/Discussion'
import { useTranslation } from 'react-i18next'
import { ImageReturnTab } from '@domains/feedbacks/tab/ImageReturnTab'
import { IntegrationType, TrackType } from '@gqlTypes'
import IntegrationProgression from '@features/details/pages/userPositions/IntegrationProgression'
import { Container } from '@mui/material'
import { useCandidatesInformations } from './useCandidatesInformations'
import Informations from '@features/details/components/informationsTab/Informations'
import Profile from '@domains/profile/Profile'
import { INTEGRATION_TYPE } from '../../../constants/INTEGRATION_TYPE'
import { OrgaReturn } from '@domains/brandFeedbacks/tab/OrgaReturn'
import { CareerMatchings } from '@domains/careerMatching/CareerMatchings'

export default function CandidatesDetails() {
	const { userOfferId } = useParams()
	const infos = useCandidatesDetails({ userOfferId })
	const { t } = useTranslation()
	const { state } = useLocation()
	const profilePath = ROUTE_CANDIDATE_DETAIL_TAB_PROFILE
	const discussionPath = ROUTE_CANDIDATE_DETAIL_TAB_DISCUSSION
	const progression = ROUTE_CANDIDATE_DETAIL_TAB_PROGRESSION
	const informationsPath = [ROUTE_CANDIDATE_DETAIL, ROUTE_CANDIDATE_DETAIL_TAB_INFO]
	const orgaReturnPath = ROUTE_CANDIDATE_DETAIL_TAB_ORGA_RETURN
	const careerPath = ROUTE_CANDIDATE_DETAIL_TAB_CAREER
	const returnsPath = ROUTE_CANDIDATE_DETAIL_TAB_RETURNS
	const backRoute = state?.interviewId ? `/interview/offer/${userOfferId}/${state?.interviewId}` : ROUTE_CANDIDATES
	const informations = useCandidatesInformations(infos.integrationInfo)

	if (infos.isLoading) return <LocalLoader />

	return (
		<Container maxWidth="xl">
			<TabBar infos={infos} backRoute={backRoute} backToolTip={t('menu.candidates')} />
			<Switch>
				<Route path={profilePath}>
					{infos.integrationInfo && infos.userInfo && (
						<Profile
							integrationType={INTEGRATION_TYPE.CANDIDACY}
							id={userOfferId}
							trackType={TrackType.Offer}
							discussionRoute={discussionPath}
						/>
					)}
				</Route>
				<Route path={discussionPath}>
					<Discussion infos={infos} />
				</Route>
				<Route path={progression}>
					<IntegrationProgression infos={infos} trackType={TrackType.Offer} />
				</Route>
				<Route path={returnsPath}>
					{infos.integrationInfo && (
						<ImageReturnTab userIntegrationId={userOfferId} userIntegrationType={IntegrationType.Offer} />
					)}
				</Route>
				<Route path={orgaReturnPath}>
					<OrgaReturn filterKey="senderRef.userId" value={infos.userInfo.userId} />
				</Route>
				<Route path={careerPath}>
					<CareerMatchings userIntegrationId={infos.integrationInfo.id} trackType={TrackType.Offer} />
				</Route>
				<Route path={informationsPath} exact={false}>
					{infos.integrationInfo && infos.userInfo && <Informations infos={infos} informations={informations} />}
				</Route>
			</Switch>
		</Container>
	)
}
