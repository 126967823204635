import { useTranslation } from 'react-i18next'
import AutocompleteField from './AutocompleteField'
import { useGetUserIntegrationsQuery } from '@gqlTypes'

export default function AssessedField() {
	const { t } = useTranslation()

	const { loading, data } = useGetUserIntegrationsQuery()

	const userIntegrations = data?.getUserIntegrations ?? []
	const rules = { required: t('drawer:interview.required') }
	const items = userIntegrations.map((integration) => ({
		value: { id: integration.userIntegrationId, type: integration.offerIntegrationType },
		label: `${integration.lastname} ${integration.firstname} - ${integration.offerIntegrationLabel} (${integration.offerIntegrationTypeLabel})`
	}))

	return (
		<AutocompleteField
			rules={rules}
			name="userIntegration"
			items={items}
			label={t('drawer:interview.assessed')}
			loading={loading}
		/>
	)
}
