import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { ChipSelector } from '../../../../shared/components/forms/fields/ChipSelector'
import { useWatch } from 'react-hook-form'
import { useGetUserFunctionQuery } from '@gqlTypes'
import { ColumnLayout } from '@shared/components/layout/ColumnLayout'

export const TopHardSkill = (): ReactElement | null => {
	const { t } = useTranslation()

	const userFunctionId = useWatch<string>({ name: 'userFunction' }) ?? ''
	const { data, loading: isLoading } = useGetUserFunctionQuery({ variables: { userFunctionId } })
	const hardSkills = data?.getUserFunction?.hardSkills ?? []

	return (
		<ColumnLayout title={t('offers:details.expectedSkills.hardSkills.title')}>
			<ChipSelector
				name="topHardSkillIds"
				items={hardSkills}
				isLoading={isLoading}
				maxSelectableItem={3}
				minSelectableItem={3}
			/>
		</ColumnLayout>
	)
}
