import MassMessages from '../../../features/modals/pages/MassMessages'
import { MassReminders } from '../../../features/modals/pages/MassReminders'
import { useTranslation } from 'react-i18next'
import { Fullname } from '../../../features/lists/components/Filters/Fullname'
import { CheckboxInput } from '../../../features/lists/components/Filters/CheckboxInput'
import MassFeedback from '../../../features/modals/pages/MassFeedback'
import { TrackPositionFilter } from '../../../features/lists/components/Filters/TrackPositionFilter'
import ManagersFilter from '../../../features/lists/components/Filters/ManagersFilter'
import { ExportGQL } from '../../../features/modals/components/actions/ExportGQL'
import PositionStatusFilter from '../../../features/lists/components/Filters/PositionStatusFilter'
import { TextFilter } from '../../../features/lists/components/Filters/TextFilter'
import BusinessUnitFilter from '../../../features/lists/components/Filters/BusinessUnitFilter'
import LocationFilter from '../../../features/lists/components/Filters/LocationsFilter'
import { IntegrationCategory, StudentExportDocument, TrackType, useUpdateManyUserIntegrationsMutation } from '@gqlTypes'
import { MassInterviewsCreation } from '@domains/interviews/createMany/MassInterviewsCreation'
import { InterviewTypeStateFilter } from '@features/lists/components/Filters/InterviewTypeStateFilter'
import { PromotionFilter } from '../../../features/lists/components/Filters/PromotionFilter'
import { MassUpdatesGql } from '@features/modals/pages/MassUpdatesGql'
import { LeftBar } from '@features/lists/components/LeftBar'
import { Filters } from '@features/lists/components/Filters'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import RiskFilter from '@features/lists/components/Filters/RiskFilter'
import { useOrganization } from '@domains/organization/useOrganization'
import { useRole } from '@shared/hooks/useRole'

export default function StudentsFilterBar({ isAllRowsSelected, refetch, dataList, count }) {
	const { hasOrgaSkills } = useOrganization()
	const { t } = useTranslation(['lists', 'positions'])
	const [updateManyUserIntegrations, { loading }] = useUpdateManyUserIntegrationsMutation()
	const { screens } = useRole()

	const massAction = {
		trackType: TrackType.Position,
		integrationCategory: IntegrationCategory.School,
		isAllRowsSelected,
		dataList
	}

	return (
		<LeftBar>
			<MassInterviewsCreation {...massAction} />
			{screens.students.hasAdminAccess ? (
				<MassUpdatesGql
					{...massAction}
					query={updateManyUserIntegrations}
					refetch={refetch}
					isLoading={loading}
					isAllRowsSelected={isAllRowsSelected}
					count={count}
				/>
			) : null}

			<MassMessages {...massAction} count={count} />
			<MassReminders {...massAction} />
			{hasOrgaSkills ? <MassFeedback {...massAction} count={count} /> : null}
			<ExportGQL isAllRowsSelected={isAllRowsSelected} dataList={dataList} QUERY={StudentExportDocument} />
			<Filters refetch={refetch}>
				<FiltersContent>
					<Fullname />
					<TextFilter keyName="userInfo.externalId" label={t('lists:filter.externalId')} />
					<TrackPositionFilter keyName="orgaPosition.id" keyLabel="schoolTrack" />
					<LocationFilter label={t(`lists:filter.campus.label`)} allValueLabel={t(`lists:filter.campus.allLabel`)} />
					<ManagersFilter keyLabel="referents" />
					<ManagersFilter keyName="externalAssessorId" keyLabel="tutors" />
					<BusinessUnitFilter isSchool />
					<PromotionFilter />
					<PositionStatusFilter />
					<RiskFilter />
					<TextFilter keyName="userInfo.currentCompanyLowercase" label={t('lists:filter.currentCompany')} />
					<CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" />
					<CheckboxInput keyName="isActive" translationKey="lists:filter.isActive" />
					<CheckboxInput
						keyName="isExternalAssessorValidated"
						translationKey="lists:filter.isExternalAssessorValidated"
					/>
					<InterviewTypeStateFilter />
				</FiltersContent>
			</Filters>
		</LeftBar>
	)
}
