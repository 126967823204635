import { CardList } from '@features/lists/components/CardList'
import { useGetJobSearchListQuery } from '@gqlTypes'
import { Container } from '@mui/material'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { JobSearchCard } from './JobSearchCard'
import { EmptyJobSearchList } from './EmptyJobSearchList'

export const ResumeLibrary = (): ReactElement => {
	const { t } = useTranslation()
	const variables = { cursor: '', limit: 2 }
	const { data, loading, fetchMore } = useGetJobSearchListQuery({
		variables,
		fetchPolicy: 'cache-and-network'
	})

	const result = data?.getJobSearchList
	const jobSearches = result?.nodes ?? []
	const cursor = result?.cursor ?? ''
	const hasNextPage = result?.hasNextPage ?? false
	const count = result?.count ?? 0

	return (
		<Container maxWidth="xl">
			<CardList
				backRoute={'/'}
				toolbar={<></>}
				emptyStateLabel={t('offers:empty')}
				renderItem={(item) => <JobSearchCard jobSearch={item} key={item.id} />}
				dataList={jobSearches}
				isLoading={loading}
				title={t('offers:resumeLibrary.title', { count })}
				emptyComponent={<EmptyJobSearchList />}
				cursor={cursor}
				hasNextPage={hasNextPage}
				fetchMore={fetchMore}
			/>
		</Container>
	)
}
