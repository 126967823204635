import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import { useTranslation } from 'react-i18next'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import clsx from 'clsx'

import { makeStyles } from 'tss-react/mui'

export function defaultRenderCell(row, column) {
	if (row) {
		return <Typography>{row[column.key || column.label]}</Typography>
	} else {
		return <></>
	}
}

const useStyle = makeStyles()((theme) => ({
	root: {},
	table: {},
	row: {},
	emptyState: {
		height: 400,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		color: theme.palette.grays.gray2
	}
}))

export function DataTable(props) {
	const { width, onSelectAllRows, isAllRowsSelected, dataTestId } = props
	const { columns, rowGetter, rowCount } = props
	const { selectedRows, onRowSelectionChange, selectable } = props
	const { classes } = useStyle(props)
	const { t } = useTranslation(['userOfferStatus', 'lists'])

	const displayedColumns = columns.filter((column) => column.isDisplay == null || column.isDisplay)

	function getRowCount() {
		if (typeof rowCount === 'function') {
			return rowCount()
		}
		return rowCount
	}

	function setSelectionForRow(index) {
		if (!onRowSelectionChange) {
			return
		}
		let newSelectedRows = []

		if (selectedRows.indexOf(index) >= 0) {
			newSelectedRows = selectedRows.filter((el) => el !== index)
		} else {
			newSelectedRows = [...selectedRows, index]
		}
		const isAllRowSelected = getRowCount() === newSelectedRows.length
		onRowSelectionChange(newSelectedRows)
		onSelectAllRows(isAllRowSelected)
	}

	function setSelectAll(isSelectAll) {
		if (!onRowSelectionChange) {
			return
		}

		if (isSelectAll) {
			onSelectAllRows(true)
			const allSelection = new Array(getRowCount()).fill(0).map((_, i) => i)
			onRowSelectionChange(allSelection)
		} else {
			onSelectAllRows(false)
			onRowSelectionChange([])
		}
	}

	// let startRow = page * pageSize;
	const totalRows = rowCount()

	const rowsEls = new Array(totalRows).fill(null).map((_, i) => {
		const currentIndex = i
		return (
			<DataTableRow
				key={currentIndex}
				columns={displayedColumns}
				selectable={selectable}
				rowData={rowGetter(currentIndex)}
				rowIndex={currentIndex}
				selected={selectedRows?.indexOf(currentIndex) >= 0}
				onSelectionChange={(isSelected) => setSelectionForRow(currentIndex, isSelected)}
			/>
		)
	})

	return (
		<div style={{ width }}>
			{totalRows > 0 && (
				<>
					<TableContainer className={classes.root}>
						<Table className={classes.table} data-testid={dataTestId}>
							<DataTableHeader
								columns={displayedColumns}
								selectable={selectable}
								onSelectAll={(isAllSelected) => setSelectAll(isAllSelected)}
								allSelected={isAllRowsSelected}
							/>
							<TableBody>{rowsEls}</TableBody>
						</Table>
					</TableContainer>
					{/* <TablePagination */}
					{/*   component='div' */}
					{/*   count={totalRows} */}
					{/*   rowsPerPage={pageSize} */}
					{/*   rowsPerPageOptions={[pageSize]} */}
					{/*   page={Math.floor(startRow / pageSize)} */}
					{/*   onChangePage={(_, page) => onPageChanged?.(page)} */}
					{/*   labelDisplayedRows={({ from, to, count }) => */}
					{/*   t('translation:datatable.displayedRows', { */}
					{/*   from, */}
					{/*   to, */}
					{/*   count */}
					{/*  })} */}
					{/* /> */}
				</>
			)}
			{totalRows < 1 && (
				<div className={classes.emptyState}>
					<div className="icon">{props.emptyStateIcon || <FormatListBulletedIcon />}</div>
					<Typography>{props.emptyStateLabel || t('lists:dataGrid.empty')}</Typography>
				</div>
			)}
		</div>
	)
}

DataTable.propTypes = {
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			align: PropTypes.oneOf(['center', 'left', 'right']),
			key: PropTypes.string,
			label: PropTypes.string.isRequired,
			cellRenderer: PropTypes.func,
			width: PropTypes.number
		})
	).isRequired,
	emptyStateIcon: PropTypes.node,
	emptyStateLabel: PropTypes.node,
	onPageChanged: PropTypes.func,
	onRowSelectionChange: PropTypes.func,
	onSelectAllRows: PropTypes.func,
	page: PropTypes.number,
	pageSize: PropTypes.number,
	rowCount: PropTypes.func.isRequired,
	rowGetter: PropTypes.func.isRequired,
	selectable: PropTypes.bool,
	selectedRows: PropTypes.arrayOf(PropTypes.number),
	width: PropTypes.number
}

DataTable.defaultProps = {
	// pageSize: 25,
	page: 0
}

export default DataTable

/// TABLE HEAD ///

const useRowStyle = makeStyles()(() => ({
	root: {},
	cell: {},
	cellContent: {
		display: 'flex',
		justifyContent: 'stretch',
		alignItems: 'center',
		width: '100%',
		height: '100%',

		'& > *': {
			flex: 1
		}
	},
	checkboxCell: {
		width: 74,
		maxWidth: 74,
		'& > $cellContent': {
			width: 'min-content',
			maxWidth: 'min-content'
		}
	},
	header: {},
	stickyHeader: {
		position: 'sticky'
	}
}))

function DataTableHeader(props) {
	const { columns, selectable, onSelectAll, allSelected } = props
	const { classes } = useRowStyle(props)
	const { t } = useTranslation(['userOfferStatus', 'lists'])

	const colEls = columns.map((el) => (
		<TableCell key={el.key || el.label} align={el.align} style={el.width ? { width: el.width } : undefined}>
			{el.label || el.key}
		</TableCell>
	))

	return (
		<TableHead className={clsx(classes.header)}>
			{selectable && (
				<TableCell align="center" className={clsx(classes.checkboxCell)}>
					<div className={classes.cellContent}>
						<div>
							<Tooltip title={t('lists:dataGrid.selectAll')}>
								<Checkbox onChange={(e) => onSelectAll(e.target.checked)} checked={allSelected} />
							</Tooltip>
						</div>
					</div>
				</TableCell>
			)}
			{colEls}
		</TableHead>
	)
}

/// TABLE ROW ///

function DataTableRow(props) {
	const { selectable, selected, onSelectionChange } = props
	const { columns, rowData, rowIndex } = props
	const { classes } = useRowStyle(props)
	const { t } = useTranslation(['lists'])

	return (
		<TableRow className={classes.root} key={rowIndex}>
			{selectable && (
				<TableCell align="center" className={clsx(classes.checkboxCell)}>
					<div className={classes.cellContent}>
						<div>
							<Tooltip title={t('lists:dataGrid.select')}>
								<Checkbox checked={selected} onChange={(e) => onSelectionChange(e.target.checked)} />
							</Tooltip>
						</div>
					</div>
				</TableCell>
			)}
			{columns.map((column, i) => (
				<TableCell
					align={column.align}
					className={classes.cell}
					key={column.key || column.label || i}
					style={column.width ? { width: column.width } : undefined}
				>
					<div className={classes.cellContent}>
						<div>{column.renderCell?.(rowData, column, rowIndex) || defaultRenderCell(rowData, column, rowIndex)}</div>
					</div>
				</TableCell>
			))}
		</TableRow>
	)
}
