import { type ReactElement } from 'react'
import { LeftBar } from '@features/lists/components/LeftBar'
import { useGetUserOfferJobMatchingStatusCountQuery } from '@gqlTypes'
import { ChipList } from '@shared/components/ChipList'
import { useHistory } from 'react-router-dom'

interface Props {
	trackOfferId: string
	statusId: string
}

export const JobMatchingListToolbar = ({ trackOfferId, statusId }: Props): ReactElement => {
	const { data } = useGetUserOfferJobMatchingStatusCountQuery({ variables: { trackOfferId } })
	const history = useHistory()
	const status = data?.getUserOfferJobMatchingStatusCount ?? []
	const onchange = (statusId: string): void => {
		history.push(`/offers${trackOfferId !== '' ? '/' : ''}${trackOfferId}/matchings/${statusId}`)
	}

	return (
		<LeftBar>
			<ChipList withCount items={status} selectedItems={[statusId]} onChange={onchange} />
		</LeftBar>
	)
}
