import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import { LocalDate } from '../core/LocalDate'
import Tooltip from '@mui/material/Tooltip'
import theme from '../theme'

const useStyle = makeStyles()(
	(theme, { backgroundColor, marginLeft, indicatorColor, leftPosition, rightPosition, transform, textAlign }) => ({
		root: {
			marginTop: 25,
			marginBottom: 25,
			textAlign
		},

		message: {
			boxSizing: 'border-box',
			display: 'inline-block',
			padding: '15px 20px',
			maxWidth: '75%',
			position: 'relative',
			marginLeft,
			zIndex: 1,
			textAlign: 'left',
			backgroundColor,
			borderRadius: 10,
			paddingRight: 30,
			marginRight: 15,
			paddingLeft: 30
		},

		indicator: {
			position: 'absolute',
			top: 0,
			left: leftPosition,
			color: indicatorColor,
			right: rightPosition,
			transform
		},

		author: {
			marginLeft: 25,
			fontSize: '0.8em',
			fontStyle: 'italic',
			color: theme.palette.grays.gray4,
			'$selfVariant &': {
				marginLeft: 0,
				marginRight: 25
			}
		},

		date: {
			marginLeft: 20,
			fontSize: '0.8em',
			fontStyle: 'italic',
			color: theme.palette.grays.gray3,
			display: 'none',
			'$selfVariant &': {
				marginLeft: 0,
				marginRight: 20
			}
		}
	})
)

export function ConversationMessageContainer({ author, date, children, variant = 'self' }) {
	const isSelf = variant === 'self'
	const backgroundColor = isSelf ? theme.palette.messageSelf.main : theme.palette.messageOther.main
	const marginLeft = isSelf ? 0 : 15
	const indicatorColor = isSelf ? theme.palette.messageSelf.main : theme.palette.messageOther.main
	const leftPosition = isSelf ? 'unset' : -15
	const rightPosition = isSelf ? -15 : 'unset'
	const transform = isSelf ? 'scale(-1, 1)' : 'unset'
	const textAlign = isSelf ? 'right' : 'left'
	const { classes } = useStyle({
		backgroundColor,
		marginLeft,
		indicatorColor,
		leftPosition,
		rightPosition,
		transform,
		textAlign
	})

	const tooltip = (
		<>
			{date && <LocalDate date={date} dateStyle="short" timeStyle="short" />}
			{author && ` • ${author}`}
		</>
	)

	return (
		<Tooltip enterDelay={500} placement={variant === 'self' ? 'right' : 'left'} title={tooltip}>
			<div className={clsx(classes.root, classes[`${variant}Variant`])}>
				<div className={classes.message}>
					<MessageIndicator className={classes.indicator} />
					{children}
				</div>
			</div>
		</Tooltip>
	)
}

function MessageIndicator(props) {
	return (
		<svg {...props} width="24" height="15" viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 0H24V15H15L0 0Z" fill="currentcolor" />
		</svg>
	)
}
