import { type ReactElement } from 'react'
import { IconButton, Link, Stack, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useToggle } from '@shared/hooks/useToggle'
import { useTranslation } from 'react-i18next'

interface Props {
	isRequired?: boolean
	isWhite?: boolean
	label: string
	children: React.ReactNode
	actions?: React.ReactNode
}

export const SliderTitle = ({ isRequired = false, isWhite = false, label, children, actions }: Props): ReactElement => {
	const [isDescriptionOpen, toogleIsDescriptionOpen] = useToggle()
	const { t } = useTranslation()
	const color = isWhite ? '#FFF' : 'grays.gray4'
	return (
		<>
			<Stack direction="row" justifyContent="space-between" alignItems="center">
				<Stack direction="row" spacing={2}>
					<Typography
						sx={{
							fontWeight: 'bold',
							fontSize: '20px',
							color
						}}
					>
						{label} {isRequired ? <span style={{ color: '#FB881F' }}>*</span> : null}
					</Typography>
					{actions}
				</Stack>
				<Stack className="no-print" direction="row" spacing={1}>
					<Link sx={{ cursor: 'pointer' }} onClick={toogleIsDescriptionOpen}>
						{t('sliders:expectations')}
					</Link>
					<IconButton sx={{ color, padding: 0 }} onClick={toogleIsDescriptionOpen}>
						<InfoOutlinedIcon />
					</IconButton>
				</Stack>
			</Stack>
			{isDescriptionOpen ? (
				<Typography
					sx={{
						p: 2,
						color: 'grays.gray4',
						backgroundColor: 'backgroundBlue.main',
						borderRadius: '4px',
						whiteSpace: 'pre-line'
					}}
				>
					{children}
				</Typography>
			) : null}
		</>
	)
}
