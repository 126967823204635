import Typography from '@mui/material/Typography'
import { type ReactElement } from 'react'
import ConditionnalWrapper from '@shared/components/ConditionnalWrapper'
import { extractUrl } from '#helpers/string.helper'
import { Link, type SxProps } from '@mui/material'

interface Props {
	text: string
	sx?: SxProps
}

export const TypographyWithLink = ({ text, sx }: Props): ReactElement => {
	const extractedTexts = extractUrl(text)

	return (
		<Typography sx={sx}>
			{extractedTexts.map((text) => {
				if (text.value === '') return <></>
				return (
					<ConditionnalWrapper
						key={text.value}
						condition={text.type === 'url'}
						wrapper={(children: ReactElement) => (
							<Link
								href={text.value}
								target="_blank"
								rel="noreferrer"
								underline="none"
								sx={{ ...sx, textDecoration: 'underline', fontStyle: 'italic' }}
							>
								{children}
							</Link>
						)}
					>
						<b>{text.value}</b>
					</ConditionnalWrapper>
				)
			})}
		</Typography>
	)
}
