import { ObjectiveStatusId, useGetInterviewObjectivesQuery, type GetInterviewObjectivesQuery } from '@gqlTypes'
import { CardActionArea, Chip, Grid, IconButton, Stack, Typography } from '@mui/material'
import { type ReactNode, type ReactElement } from 'react'
import { ObjectiveTendency } from './ObjectiveTendency'
import { CircularProgressWithLabel } from '@ui/CircularProgressWithLabel'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { InterviewObjectivesEditionModal } from '../edition/InterviewObjectiveEditionModal'
import { useToggle } from '@shared/hooks/useToggle'
import { indexById } from '#helpers/array.helper'

interface Props {
	id: string
	label: string
	interviewId: string
	status: GetInterviewObjectivesQuery['getInterviewObjectives'][0]['status']
	completion: number
	tendency: number
	targetComponent: ReactNode
	nextTargetComponent: ReactNode
	isShortTerme: boolean
	reevaluatedTargetComponent: ReactNode
	isReadOnly: boolean
}

export const InterivewObjectiveRow = ({
	id,
	label,
	status,
	targetComponent,
	completion,
	tendency,
	nextTargetComponent,
	interviewId,
	isShortTerme,
	reevaluatedTargetComponent,
	isReadOnly
}: Props): ReactElement => {
	const [isUpdateOpen, toggleIsUpdateOpen] = useToggle(false)
	const { data } = useGetInterviewObjectivesQuery({ variables: { interviewId } })
	const indexedObjectives = indexById(data?.getInterviewObjectives ?? [])

	const objective = indexedObjectives[id]

	return (
		<>
			{isUpdateOpen ? (
				<InterviewObjectivesEditionModal
					toggleIsOpen={toggleIsUpdateOpen}
					objective={objective}
					isReadOnly={isReadOnly}
				/>
			) : null}

			<CardActionArea
				sx={{ borderRadius: 1, p: 1, py: 2 }}
				onClick={() => {
					toggleIsUpdateOpen()
				}}
			>
				<Stack direction="row" alignItems="center">
					<Grid container spacing={2} alignItems="center">
						<Grid item xs={2}>
							<Typography fontWeight={'bold'}>{label}</Typography>
						</Grid>
						<Grid item xs={3}>
							{targetComponent}
						</Grid>
						<Grid item xs={1}>
							<CircularProgressWithLabel value={completion} />
						</Grid>
						<Grid item xs={2}>
							<Chip label={status.label} sx={{ bgcolor: status.color, color: 'white' }} />
						</Grid>
						<Grid item xs={2}>
							{status.id === ObjectiveStatusId.Defined || isShortTerme ? (
								<Typography textAlign="center">-</Typography>
							) : (
								<ObjectiveTendency tendency={tendency} />
							)}
						</Grid>
						<Grid item xs={2}>
							{isShortTerme ? reevaluatedTargetComponent : nextTargetComponent}
						</Grid>
					</Grid>
					<IconButton
						onClick={() => {
							toggleIsUpdateOpen()
						}}
					>
						{isReadOnly ? <KeyboardArrowRightIcon /> : <EditIcon />}
					</IconButton>
				</Stack>
			</CardActionArea>
		</>
	)
}
