import { Stack, type SxProps, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import NorthEastIcon from '@mui/icons-material/NorthEast'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import EastIcon from '@mui/icons-material/East'
import { useTranslation } from 'react-i18next'

interface Props {
	tendency: number
}

export const ObjectiveTendency = ({ tendency }: Props): ReactElement => {
	const { t } = useTranslation()
	const sx: SxProps = { fontSize: 45 }

	let icon = <EastIcon color="primary" sx={sx} />
	const isPositive = tendency > 0
	const prefix = isPositive ? '+' : ''
	if (isPositive) icon = <NorthEastIcon color="secondary" sx={sx} />
	if (tendency < 0) icon = <SouthEastIcon color="warning" sx={sx} />

	return (
		<Stack direction="row" alignItems={'center'} justifyContent={'center'} gap={1}>
			{icon}
			<Stack>
				<Typography>{`${prefix}${tendency}%`}</Typography>
				<Typography>{t('interview:objectives.list.tendency.label')}</Typography>
			</Stack>
		</Stack>
	)
}
