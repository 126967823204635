import { useGetEducationLevelsQuery, useGetQuartersQuery, useGetUserFunctionsQuery } from '@gqlTypes'
import TableLayoutRow from '@shared/components/cards/TableLayoutRow'
import InputTextField from '@shared/components/forms/fields/inputTextField'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import { type ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ChipSelector } from '../../../../shared/components/forms/fields/ChipSelector'
import InputSelectField from '@shared/components/forms/fields/InputSelectField'
import { AuthorizedOrganizationsAutocomplete } from '../AuthorizedOrganizationsAutocomplete'

export const OfferMainCard = (): ReactElement | null => {
	const { t } = useTranslation()
	const { control, errors } = useFormContext()
	const { data: dataUserFunctions, loading: isLoadingUserFunctions } = useGetUserFunctionsQuery()
	const { data: dataEducationLevels, loading: isLoadingEducationLevels } = useGetEducationLevelsQuery()
	const { data: dataQuarters, loading: isLoadingQuarters } = useGetQuartersQuery()
	const educationLevels = dataEducationLevels?.getEducationLevels ?? []
	const quarters = dataQuarters?.getQuarters ?? []

	return (
		<CardLayout noDivider title={t('offers:details.main.title')} tooltip={t('offers:details.main.tooltip')}>
			<TableLayout>
				<AuthorizedOrganizationsAutocomplete />
				<TableLayoutRow translationKey="offers:details.main.label">
					<InputTextField control={control} name="label" error={errors.label} required />
				</TableLayoutRow>
				<TableLayoutRow translationKey="offers:details.main.function">
					<InputSelectField
						isLoading={isLoadingUserFunctions}
						name="userFunction"
						items={dataUserFunctions?.getUserFunctions ?? []}
						control={control}
						displayKey="name"
						rules={{ required: t('form:errors.required') }}
						errors={errors}
					/>
				</TableLayoutRow>
				<TableLayoutRow translationKey="offers:details.main.educationLevel">
					<ChipSelector
						name="educationLevelIds"
						items={educationLevels}
						isLoading={isLoadingEducationLevels}
						minSelectableItem={1}
					/>
				</TableLayoutRow>
				<TableLayoutRow translationKey="offers:details.main.zipCode">
					<InputTextField
						control={control}
						name="zipCode"
						error={errors.zipCode}
						required
						patternValue={/\b\d{5}\b/g}
					/>
				</TableLayoutRow>
				<TableLayoutRow translationKey="offers:details.main.duration">
					<ChipSelector
						name="contractDurationIds"
						items={[
							{ id: '12', label: t('offers:details.main.duration.values.12') },
							{ id: '24', label: t('offers:details.main.duration.values.24') }
						]}
						minSelectableItem={1}
					/>
				</TableLayoutRow>
				<TableLayoutRow translationKey="offers:details.main.startQuarter">
					<ChipSelector name="startQuarterIds" items={quarters} minSelectableItem={1} isLoading={isLoadingQuarters} />
				</TableLayoutRow>
			</TableLayout>
		</CardLayout>
	)
}
