import { LocalLoader } from '@shared/components/layout/LocalLoader'
import DataTable from '@ui/data/DataTable'
import { ButtonMore } from '@shared/components/buttons/ButtonMore'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedRows } from '@shared/store/actions/selectedRows.actions'
import { Box, Paper, Stack, Typography } from '@mui/material'

export default function DataGridGQL({
	emptyStateLabel,
	columns,
	setIsAllRowsSelected,
	isAllRowsSelected,
	dataList,
	isLoading,
	fetchMore,
	cursor,
	hasNextPage,
	dataTestId,
	title,
	Icon,
	toolbar
}) {
	const selectedRows = useSelector(({ selectedRows }) => selectedRows)
	const dispatch = useDispatch()

	return (
		<Box elevation={3} sx={{ maxWidth: 1445, width: '100%', ml: 'auto', mr: 'auto', mb: '100px' }}>
			{isLoading ? (
				<Box mt="3" sx={{ height: 300 }}>
					<LocalLoader />
				</Box>
			) : (
				<Stack gap={3}>
					<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
						<Stack direction="row" gap={1} alignItems="center" mt={1}>
							<Icon color="primary" />
							<Typography variant="h5" color="primary">
								<b>{title}</b>
							</Typography>
						</Stack>
						{toolbar}
					</Stack>
					<Paper elevation={3}>
						<DataTable
							rowGetter={(index) => dataList[index]}
							rowCount={() => dataList.length}
							columns={columns()}
							selectable
							selectedRows={selectedRows}
							onRowSelectionChange={(newRows) => {
								dispatch(setSelectedRows(newRows))
							}}
							emptyStateLabel={emptyStateLabel}
							onSelectAllRows={setIsAllRowsSelected}
							isAllRowsSelected={isAllRowsSelected}
							dataTestId={dataTestId}
						/>
						<ButtonMore
							hasNextPage={hasNextPage}
							isLoading={isLoading}
							onClick={() => fetchMore({ variables: { cursor } })}
						/>
					</Paper>
				</Stack>
			)}
		</Box>
	)
}
