import { Button } from '@mui/material'
import { DefaultTabsLayout } from '@shared/tabs/components/DefaultTabsLayout'
import { t } from 'i18next'
import { type ReactElement } from 'react'
import MovingIcon from '@mui/icons-material/Moving'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { type TabContent } from '@shared/tabs/types/TabContent'
import { BottomSkillComments } from './bottomSkillComments/BottomSkillComments'
import { MyProfileResume } from './resume/MyProfileResume'
import { myProfileRouter } from './myProfile.router'
import { Link } from 'react-router-dom'
import { FeedbackRequestPath } from '@domains/feedbackRequest/FeedbackRequest.router'
import { useMeQuery } from '@gqlTypes'
import { generateRoute } from '../../router/routes'

export const MyProfilePages = (): ReactElement => {
	const { data } = useMeQuery()
	const isFeedbackFullRequest: boolean = data?.me?.organization?.isFeedbackFullRequest ?? true
	const fullrequest = isFeedbackFullRequest ? 'fullrequest' : ''
	const tabs: TabContent[] = [
		{
			translationKey: 'myProfileRouter.skills',
			icon: <AccountCircleOutlinedIcon />,
			route: {
				component: MyProfileResume,
				path: myProfileRouter.path.resume,
				exact: true
			}
		},
		{
			translationKey: 'myProfileRouter.developmentsAxis',
			icon: <MovingIcon />,
			route: {
				component: BottomSkillComments,
				path: myProfileRouter.path.developmentsAxis
			}
		}
	]

	return (
		<DefaultTabsLayout
			title={t('menu.my-profile')}
			tabs={tabs}
			right={
				<Link to={generateRoute(FeedbackRequestPath.form, { fullrequest })}>
					<Button sx={{ height: 50 }} variant="contained" color="secondary">
						{t('askFeedbacks')}
					</Button>
				</Link>
			}
		/>
	)
}
