import { Route, Switch, useLocation } from 'react-router-dom'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import TabBar from '../../components/TabBar'
import UserPositionsInformations from './UserPositionsInformations'
import Discussion from '../../../../domains/discussions/tab/Discussion'
import IntegrationProgression from './IntegrationProgression'
import { IntegrationType, TrackType } from '@gqlTypes'
import { Container } from '@mui/material'
import Profile from '@domains/profile/Profile'
import { INTEGRATION_TYPE } from '../../../../constants/INTEGRATION_TYPE'
import { ImageReturnTab } from '@domains/feedbacks/tab/ImageReturnTab'
import { OrgaReturn } from '@domains/brandFeedbacks/tab/OrgaReturn'
import { CareerMatchings } from '@domains/careerMatching/CareerMatchings'

export default function UserPositionsDetails({
	profilePath,
	discussionPath,
	progressionPath,
	informationsPath,
	returnsPath,
	infos,
	userPositionId,
	orgaReturnPath,
	backRoute,
	backToolTip,
	careerPath
}) {
	if (infos.isLoading) return <LocalLoader />
	const { state } = useLocation()
	const calculatedBackRoute = state?.interviewId
		? `/interview/position/${userPositionId}/${state?.interviewId}`
		: backRoute

	return (
		<Container maxWidth="xl">
			<TabBar infos={infos} backRoute={calculatedBackRoute} backToolTip={backToolTip} />
			<Switch>
				<Route path={profilePath}>
					{infos.integrationInfo && infos.userInfo && (
						<Profile
							integrationType={INTEGRATION_TYPE.POSITION}
							id={userPositionId}
							trackType={TrackType.Position}
							discussionRoute={discussionPath}
						/>
					)}
				</Route>
				<Route path={discussionPath}>
					<Discussion infos={infos} />
				</Route>
				<Route path={progressionPath}>
					<IntegrationProgression infos={infos} trackType={TrackType.Position} />
				</Route>
				<Route path={returnsPath}>
					{infos.integrationInfo && (
						<ImageReturnTab userIntegrationId={userPositionId} userIntegrationType={IntegrationType.Position} />
					)}
				</Route>
				<Route path={orgaReturnPath}>
					<OrgaReturn filterKey="senderRef.userId" value={infos.userInfo.userId} />
				</Route>
				<Route path={careerPath}>
					<CareerMatchings userIntegrationId={infos.integrationInfo.id} trackType={TrackType.Position} />
				</Route>
				<Route path={informationsPath} exact={false}>
					{infos.integrationInfo && infos.userInfo && <UserPositionsInformations infos={infos} />}
				</Route>
			</Switch>
		</Container>
	)
}
