import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CheckboxInput } from '../../../features/lists/components/Filters/CheckboxInput'
import { INTEGRATION_TYPE } from '../../../constants/INTEGRATION_TYPE'
import { ExportGQL } from '../../../features/modals/components/actions/ExportGQL'
import { gql } from '@apollo/client'
import { TrackOfferFilter } from '../../../features/lists/components/Filters/TrackOfferFilter'
import { TrackPositionFilter } from '../../../features/lists/components/Filters/TrackPositionFilter'
import { AssessorsFilter } from '../../../features/lists/components/Filters/AssessorsFilter'
import { useMeQuery, useUpdateManyUserInterviewsMutation } from '@gqlTypes'
import { MassUpdatesGql } from '../../../features/modals/pages/MassUpdatesGql'
import { CustomFieldsFilters } from '../../../features/lists/components/Filters/customFields/CustomFieldsFilters'
import LocationFilter from '@features/lists/components/Filters/LocationsFilter'
import BusinessUnitFilter from '@features/lists/components/Filters/BusinessUnitFilter'
import { InterviewTypeIdFilter } from '@features/lists/components/Filters/InterviewTypeIdFilter'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import { NewInterviewDrawer } from '@domains/interviews/create/NewInterviewDrawer'
import { MassReminders } from '@features/modals/pages/MassReminders'
import { LeftBar } from '@features/lists/components/LeftBar'
import { Filters } from '@features/lists/components/Filters'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import { InterviewLateFilter } from './InterviewLateFilter'
import { PromotionFilter } from '@features/lists/components/Filters/PromotionFilter'
import { ZipInterviewsPdf } from './ZipInterviewsPdf'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import { useRole } from '@shared/hooks/useRole'
import { filterOperation } from '@ui/filter/FilterForm'
import { InterviewUserIntegrationStatusFilter } from './filter/InterviewUserIntegrationStatusFilter'

const INTERVIEW_EXPORT = gql`
	query InterviewExport($filters: [FilterInput], $organizationId: String, $ids: [String]) {
		exportCSV: interviewExport(filters: $filters, organizationId: $organizationId, ids: $ids)
	}
`

export default function InterviewsFilterBar({ refetch, isAllRowsSelected, dataList, count }) {
	const { t } = useTranslation(['lists', 'interviews'])
	const exclusiveValue = useSelector(({ exclusiveValue }) => exclusiveValue)
	const { screens } = useRole()

	const { data } = useMeQuery()
	const [updateManyUserInterviews, { loading: isLoading }] = useUpdateManyUserInterviewsMutation()

	const role = data.me.role
	if (role == null) return null
	const { isExternal, roleType } = role

	const isAssessor = roleType === 'assessor'
	const massAction = {
		isAllRowsSelected,
		dataList
	}

	return (
		<LeftBar>
			<NewInterviewDrawer reset={refetch} />
			{screens.interviews.hasAdminAccess ? (
				<MassUpdatesGql
					{...massAction}
					query={updateManyUserInterviews}
					refetch={refetch}
					isLoading={isLoading}
					count={count}
				/>
			) : null}

			<MassReminders {...massAction} isInterview />
			{isExternal ? null : (
				<>
					<ExportGQL {...massAction} QUERY={INTERVIEW_EXPORT} />
					<ZipInterviewsPdf {...massAction} />
				</>
			)}

			<Filters refetch={refetch}>
				<FiltersContent>
					<Fullname firstNameKey="userFirstnameLowercase" lastNameKey="userLastnameLowercase" />
					<TextFilter keyName="userRef.externalId" label={t('lists:filter.externalId')} />
				</FiltersContent>
				<FiltersContent>
					<InterviewLateFilter keyName="hasOnlyLateInterview" translationKey="hasOnlyLateInterviewkeyName" />
					<TrackOfferFilter
						keyName="orgaOfferId"
						exclusiveValueToMatch={INTEGRATION_TYPE.CANDIDACY}
						exclusiveValue={exclusiveValue}
						exclusiveValueDefault=""
						labelAll={t('lists:filter.offer.allLabel')}
					/>
					<TrackPositionFilter keyName="orgaPositionId" />
					{!isAssessor && <AssessorsFilter keyName="juryId" />}
					<CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" />
					<CheckboxInput keyName="stepsState.interview" translationKey="lists:filter.realised" />
					<CheckboxInput keyName="stepsState.interviewSignature" translationKey="lists:filter.signed" />
					<LocationFilter isBasedOnOrgaType />
					<BusinessUnitFilter />
					<PromotionFilter />
					<CustomFieldsFilters />
					<InterviewTypeIdFilter />
					<InterviewUserIntegrationStatusFilter />
					<TextFilter
						type="date"
						keyName="targetDate"
						operation={filterOperation.GREATER_THAN_EQUAL}
						label={t('lists:filter.minTargetDate')}
						InputLabelProps={{ shrink: true }}
					/>
					<TextFilter
						type="date"
						keyName="targetDate"
						operation={filterOperation.LOWER_THAN_EQUAL}
						label={t('lists:filter.maxTargetDate')}
						InputLabelProps={{ shrink: true }}
					/>
				</FiltersContent>
			</Filters>
		</LeftBar>
	)
}
