import { useState } from 'react'
import CandidatesFilterBar from './CandidatesFilterBar'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { ScreenId, useGetUserOfferListQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import PeopleIcon from '@mui/icons-material/People'
import { Container } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { CandidateCard } from './CandidateCard'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { useOrganization } from '@domains/organization/useOrganization'
import { searchQuery } from '@features/lists/components/Search'

export default function Candidates() {
	const { t } = useTranslation(['lists', 'candidates'])
	useDocumentTitle(t('candidates:title'))
	useCheckRoleSecurity(ScreenId.Candidates)
	const { filters } = useFilters()
	const { organizationId } = useOrganization()
	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)

	const variables = { organizationId, limit: 12, filters, searchQuery: searchQuery() }
	const { loading, data, refetch, fetchMore } = useGetUserOfferListQuery({ variables })
	useDefaultFilter({ refetch })

	let nodes = []
	let cursor = ''
	let hasNextPage = false
	let count = 0
	if (data && data.getUserOfferList) ({ nodes, cursor, hasNextPage, count } = data.getUserOfferList)

	return (
		<Container maxWidth="xl">
			<CardList
				emptyStateLabel={t('interviews:dataGrid.empty')}
				renderItem={(item, isSelected, selectRow) => (
					<CandidateCard key={item.id} offer={item} isSelected={isSelected} selectRow={selectRow} />
				)}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				dataTestId="candidates-datatable"
				title={t('candidates:title', { count })}
				Icon={PeopleIcon}
				refetch={refetch}
				hasSearch
				toolbar={
					<CandidatesFilterBar isAllRowsSelected={isAllRowsSelected} refetch={refetch} dataList={nodes} count={count} />
				}
			/>
		</Container>
	)
}
