import { type ReactNode, type FC } from 'react'
import { Provider } from 'react-redux'
import loader from './reducers/loader.reducer'
import role from './reducers/role.reducer'
import userB from './reducers/userB.reducer'
import { applyMiddleware, combineReducers, createStore, compose, type Reducer } from 'redux'
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import dataList from './reducers/dataList.reducer'
import queryContext from '../../features/lists/stores/queryContext.reducer'
import isLoading from '../../features/lists/stores/isLoading.reducer'
import filters from './reducers/filters.reducer'
import selectedRows from './reducers/selectedRows.reducer'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory, type History } from 'history'
import selectedInterviewId from '../../features/details/stores/selectedInterviewId.reducer'
import exclusiveValue from '../../features/lists/stores/exclusiveValue.reducer'
import interviewFields from './reducers/interviewFields.reduce'

export const history = createBrowserHistory()

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
	}
}

const rootReducer = (history: History<unknown>): Reducer =>
	combineReducers({
		router: connectRouter(history),
		loader,
		role,
		userB,
		dataList,
		queryContext,
		isLoading,
		selectedRows,
		filters,
		selectedInterviewId,
		exclusiveValue,
		interviewFields
	})
const devtoolsCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
const composeEnhancers = devtoolsCompose ?? compose
const store = createStore(
	rootReducer(history),
	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	composeEnhancers(applyMiddleware(thunk, createDebounce(), routerMiddleware(history)))
)

interface Props {
	children: ReactNode
}

const MyStoreProvider: FC<Props> = ({ children }) => {
	return <Provider store={store}>{children}</Provider>
}

export default MyStoreProvider

export type RootState = ReturnType<typeof store.getState>
