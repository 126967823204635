import React, { useEffect, useState } from 'react'
import MaterialSlider from '@mui/material/Slider'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import SliderLabel from './SliderLabel'
import { valueToPercent } from '../../helper/number.helper'
import theme from '../theme'
import { Box } from '@mui/material'

const READONLY_CLASSNAME = 'emageme-slider-readonly'

export function Slider({
	sx,
	min,
	max,
	markers,
	step,
	delimiter,
	startLabel,
	endLabel,
	hasSubLabel,
	children,
	defaultValue,
	value,
	id,
	color,
	className,
	readOnly,
	disabled,
	onChange,
	size,
	gradientDirection,
	baseScore,
	isDarkMode,
	style,
	trackColor
}) {
	const { t } = useTranslation()
	const isControlled = value || value === 0
	const givenValue = value ?? defaultValue

	const [actualValue, setActualValue] = useState(0)
	const isNotEvaluated = actualValue === 0
	const subLabelValue = Math.round(actualValue / (baseScore ?? 1)).toString() ?? '0'

	const [isOpen, setIsOpen] = useState(false)
	const railColor = isDarkMode ? theme.palette.grays.gray1 : theme.palette.grays.gray2

	const hasUpperLabel = startLabel || endLabel
	const isOpenLabelKey = isOpen ? 'true' : 'false'

	useEffect(() => {
		if (givenValue != null) setActualValue(givenValue)
	}, [givenValue])

	const styleProps = {
		size,
		gradientDirection,
		hasLabel: hasUpperLabel,
		valueIndex: actualValue?.indexOf?.(actualValue) || 0,
		isNotEvaluated,
		railColor,
		isDarkMode,
		readOnly,
		markers,
		trackColor
	}
	const { classes: sliderClasses } = useSliderStyle(styleProps)
	const { classes } = useStyle(styleProps)

	function handleOnChange(newValues) {
		if (!isControlled) setActualValue(newValues)
		if (onChange) {
			onChange((min < 0 ? newValues.find((el) => el !== 0) : newValues) || 0)
		}
	}

	return (
		<Box sx={sx}>
			<Box sx={sx} style={{ display: 'flex', ...style }}>
				<span style={{ width: '100%' }} className={clsx(classes.root, 'emageme-slider', className)}>
					{delimiter && (
						<span className={classes.delimiter} style={{ left: valueToPercent(delimiter, min, max) + '%' }} />
					)}
					{hasUpperLabel && (
						<SliderLabel id={id} endLabel={endLabel} min={min} startLabel={startLabel} value={actualValue} />
					)}
					{markers}
					<div style={{ width: '100%' }}>
						<MaterialSlider
							marks={!readOnly}
							value={actualValue}
							onChange={(_, v) => {
								if (v === actualValue) return
								handleOnChange(v)
							}}
							disabled={readOnly || disabled}
							classes={sliderClasses}
							className={readOnly && !disabled ? READONLY_CLASSNAME : null}
							step={step || 1}
							max={max}
							min={min}
							color={color}
						/>
					</div>
				</span>
			</Box>
			{hasSubLabel && (
				<Typography style={{ marginLeft: readOnly ? 0 : 34 }}>
					{t(`sliders:subLabel.${subLabelValue ?? '0'}`)}
				</Typography>
			)}
			{children && (
				<div onClick={() => setIsOpen(!isOpen)}>
					<div className={classes.buttonContainer}>
						<Typography className={classes.button}>{t(`sliders:open.${isOpenLabelKey}`)}</Typography>
					</div>
					{isOpen && children}
				</div>
			)}
		</Box>
	)
}

Slider.defaultProps = {
	min: 0,
	max: 100,
	color: 'secondary',
	defaultValue: 0
}

export default React.memo(Slider)

const useSliderStyle = makeStyles()(
	(theme, { size, valueIndex, isNotEvaluated, railColor, isDarkMode, readOnly, trackColor }) => ({
		root: {
			height: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		},
		rail: {
			color: railColor,
			height: size ?? 6,
			opacity: isDarkMode ? 1 : 0.38
		},
		track: {
			height: size ?? 5,
			borderRadius: 6,
			color: trackColor ?? theme.palette.secondary.main
		},
		thumb: {
			marginRight: 0,
			marginLeft: 5,
			marginTop: 0,
			width: 22,
			height: 22,
			backgroundColor: isNotEvaluated ? railColor : theme.palette.secondary.main,

			'&:not(.MuiSlider-active)': {
				display: 'none',
				pointerEvents: 'none'
			},

			[`&[data-index="${valueIndex}"]`]: {
				display: 'flex'
			}
		},
		colorPrimary: {
			[`&$disabled.${READONLY_CLASSNAME}`]: {
				color: `${theme.palette.primary.main}`
			}
		},
		colorSecondary: {
			[`&$disabled.${READONLY_CLASSNAME}`]: {
				color: `${theme.palette.secondary.main}`
			}
		},
		disabled: {
			'& .MuiSlider-thumb': {
				display: 'none !important'
			}
		},
		mark: {
			opacity: readOnly ? 0 : 1,
			backgroundColor: '#ECE4E4',
			width: 12,
			height: 12,
			borderRadius: '100%'
		},
		markActive: {
			backgroundColor: theme.palette.secondary.main
		}
	})
)

const useStyle = makeStyles()((theme, { hasLabel, markers }) => ({
	root: {
		position: 'relative',
		marginTop: markers && hasLabel ? 25 : 0
	},
	labelBar: {
		height: 30,
		marginBottom: -5,
		display: 'flex',
		alignItems: 'flex-end',

		'& .MuiTypography-body2': {
			color: theme.palette.grays.gray5
		},

		'& .muted.MuiTypography-body2': {
			color: theme.palette.grays.gray2
		},

		'&.bolder .MuiTypography-body2': {
			fontWeight: 'bold'
		},

		'&.bolder .muted.MuiTypography-body2': {
			fontWeight: 'normal'
		},

		'& .spacer': {
			flex: 1
		}
	},
	delimiter: {
		position: 'absolute',
		bottom: -17,
		marginLeft: 4,
		height: 30,
		transform: 'translateY(-50%)',
		borderLeft: 'solid grey 1px'
	},
	marker: {
		position: 'absolute',
		display: 'inline-block',
		bottom: 23,
		height: 31,
		width: 20
	},
	markerIcon: {
		position: 'absolute',
		top: 1,
		left: 1,
		display: 'inline-block',
		width: 18,
		height: 18,
		fontSize: 18,
		borderRadius: '100%',
		overflow: 'hidden',
		color: theme.palette.darkBlue.main
	},
	buttonContainer: {
		display: 'flex',
		flexDirection: 'row-reverse'
	},
	button: {
		cursor: 'pointer',
		color: theme.palette.grays.gray2,
		display: 'inline-block',
		fontSize: 13
	}
}))
