import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'

export default function SliderLabel({ min, startLabel, value, endLabel, id }) {
	const { classes } = useStyle()

	const containerClassName = min < 0 ? 'bolder' : null
	const startLabelClassName = min < 0 && value > 0 ? 'muted' : null
	const endLabelClassName = min < 0 && value <= 0 ? 'muted' : null

	return (
		<div className={clsx(classes.labelBar, containerClassName)}>
			{startLabel && (
				<Typography
					sx={{ wordBreak: 'break-word' }}
					component="label"
					variant="body2"
					htmlFor={id}
					className={clsx('start', startLabelClassName)}
				>
					{startLabel}
				</Typography>
			)}
			{endLabel && (
				<Typography component="label" variant="body2" htmlFor={id} className={clsx('end', endLabelClassName)}>
					{endLabel}
				</Typography>
			)}
		</div>
	)
}

const useStyle = makeStyles()((theme) => ({
	labelBar: {
		marginBottom: -8,
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'space-between',

		'& .MuiTypography-body2': {
			color: theme.palette.grays.gray5
		},

		'& .muted.MuiTypography-body2': {
			color: theme.palette.grays.gray2
		},

		'&.bolder .MuiTypography-body2': {
			fontWeight: 'bold'
		},

		'&.bolder .muted.MuiTypography-body2': {
			fontWeight: 'normal'
		},

		'& .spacer': {
			flex: 1
		}
	}
}))
