import { type UserRef } from '@gqlTypes'
import { type CircularProgressProps } from '@mui/material'
import { UserAvatar } from '@shared/components/UserAvatar'
import { CircularProgressWithContent } from '@ui/CircularProgressWithContent'
import { type ReactElement } from 'react'

interface Props extends CircularProgressProps {
	userRef?: UserRef | null
	progress: number
}

export const UserAvatarProgress = ({ userRef, progress, ...props }: Props): ReactElement | null => {
	if (userRef == null) return null
	return (
		<CircularProgressWithContent value={progress} size={75} {...props}>
			<UserAvatar user={userRef} />
		</CircularProgressWithContent>
	)
}
