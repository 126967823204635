import { type ReactElement, useState } from 'react'
import { Card, CardContent, Container, Stack } from '@mui/material'
import { Logo } from '@ui/brand/Logo'

import { useHistory, useParams } from 'react-router-dom'
import { useCreatePasswordMutation, useIsValidPasswordTokenQuery } from '@gqlTypes'
import { firebaseAuth } from '../../../firebase/firebase.helper'
import { PasswordRules } from '../components/PasswordRules'
import { PasswordInput } from '../components/PasswordInput'
import { LoadingButton } from '@mui/lab'
import { useTranslation } from 'react-i18next'
import { usePasswordValidation } from '../hooks/usePasswordValidation'
import { ErrorHandler } from '#helpers/error.helper'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { InvalidPasswordToken } from '../components/InvalidPasswordToken'

export const CreatePassword = (): ReactElement => {
	const { t } = useTranslation()
	const { passwordTokenId } = useParams<{ passwordTokenId: string }>()
	const history = useHistory()
	const [password, setPassword] = useState('')
	const { isValidPassword } = usePasswordValidation(password)
	const { data, loading: isLoading } = useIsValidPasswordTokenQuery({ variables: { passwordTokenId } })
	const [createPasswordMutation, { loading: isSaving }] = useCreatePasswordMutation()

	if (isLoading) return <LocalLoader load />
	if (data?.isValidPasswordToken !== true) return <InvalidPasswordToken />

	const createPassword = (): void => {
		;(async () => {
			const { data } = await createPasswordMutation({ variables: { passwordTokenId, password } })
			const token = data?.createPassword ?? ''
			await firebaseAuth().signInWithCustomToken(token)
			history.push('/')
		})().catch(ErrorHandler)
	}

	return (
		<Container maxWidth="xl" sx={{ mt: 5 }}>
			<Stack spacing={2} alignItems="center" mt={2}>
				<Logo />
				<PasswordRules password={password} />
				<Card elevation={3}>
					<CardContent style={{ alignItems: 'center' }}>
						<PasswordInput password={password} setPassword={setPassword} />
					</CardContent>
				</Card>
				<LoadingButton variant="contained" disabled={!isValidPassword} loading={isSaving} onClick={createPassword}>
					{t('auth:createPassword')}
				</LoadingButton>
			</Stack>
		</Container>
	)
}
