import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import {
	type InterviewObjectiveInput,
	useUpdateInterviewObjectiveMutation,
	type GetInterviewObjectivesQuery,
	GetInterviewObjectivesDocument
} from '@gqlTypes'
import { useGqlSnackBar } from '@shared/gql/hooks/useGqlSnackBar'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'
import { InterviewObjectivesForm } from './InterviewObjectivesForm'
import { LoadingButton } from '@mui/lab'
import { useToggle } from '@shared/hooks/useToggle'
import { InterviewObjectiveDeletionModal } from './InterviewObjectiveDeletionModal'

interface Props {
	toggleIsOpen: () => void
	objective?: GetInterviewObjectivesQuery['getInterviewObjectives'][0]
	isReadOnly: boolean
}

export const InterviewObjectivesEditionModal = ({
	toggleIsOpen,
	objective,
	isReadOnly
}: Props): ReactElement | null => {
	if (objective == null) return null
	const { t } = useTranslation()
	const key = 'interview:objectives.modal'
	const { onError } = useGqlSnackBar(`${key}.edition`)
	const [isDeleteOpen, toggleIsDeleteOpen] = useToggle(false)

	const defaultValues: InterviewObjectiveInput = {
		typeId: objective.type.id,
		statusId: objective.status.id,
		...objective
	}

	const [updateObjective, { loading: isSaving }] = useUpdateInterviewObjectiveMutation({
		onError,
		refetchQueries: [GetInterviewObjectivesDocument]
	})

	const formMethods = useForm({ defaultValues, mode: 'onChange' })
	const onSubmit = async (input: InterviewObjectiveInput): Promise<void> => {
		await updateObjective({ variables: { input, interviewObjectiveId: objective.id } })
		toggleIsOpen()
	}

	return (
		<Dialog open fullWidth maxWidth="lg" onClose={toggleIsOpen} PaperProps={{ sx: { borderRadius: 3 } }}>
			<DialogTitle>{isReadOnly ? t(`${key}.detail.title`) : t(`${key}.edition.title`)}</DialogTitle>
			<DialogCloseButton onClick={toggleIsOpen} />
			<DialogContent>
				<FormProvider {...formMethods}>
					<InterviewObjectivesForm isReadOnly={isReadOnly} onSubmit={onSubmit}>
						{isReadOnly ? null : (
							<Stack
								sx={{
									justifyContent: 'space-between',
									flexDirection: 'row',
									alignItems: 'normal'
								}}
							>
								<InterviewObjectiveDeletionModal
									isOpen={isDeleteOpen}
									toggleIsOpen={toggleIsDeleteOpen}
									objectiveId={objective.id}
								/>
								<LoadingButton variant="contained" type="submit" loading={isSaving} sx={{ maxWidth: 150 }}>
									{t('interview:objectives.modal.edition.save')}
								</LoadingButton>
							</Stack>
						)}
					</InterviewObjectivesForm>
				</FormProvider>
			</DialogContent>
		</Dialog>
	)
}
