import { useOrganization } from '@domains/organization/useOrganization'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { filterOperation } from '@ui/filter/FilterForm'
import InputFilter from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const InterviewUserIntegrationStatusFilter = (): ReactElement => {
	const { t } = useTranslation()
	const { organization } = useOrganization()
	const positionStatus = organization?.positionStatus ?? []
	const offerStatus = organization?.offerStatusObject ?? []
	const status = [...positionStatus, ...offerStatus].map((status) => ({ value: status.id, label: status.label }))

	return (
		<InputFilter keyName="userIntegrationStatus" operation={filterOperation.EQUAL} isAutoComplete>
			<AutoCompleteFilter
				options={status}
				label={t('lists:filter.interviewUserIntegrationStatus.label')}
				allValueLabel={t('lists:filter.interviewUserIntegrationStatus.allLabel')}
				width="100%"
			/>
		</InputFilter>
	)
}
