import Button from '@mui/material/Button'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	value: string | boolean
	field: string
	onValidate: (field: string, value: string | boolean) => void
	disabled?: boolean
}

export const ValidateButton = ({ value, field, onValidate, disabled = false }: Props): ReactElement | null => {
	const { t } = useTranslation(['lists'])

	return (
		<Button
			disabled={disabled || value === ''}
			onClick={() => {
				onValidate(field, value)
			}}
		>
			{value === '' ? t('modal:massUpdate.update') : t('modal:massUpdate.update')}
		</Button>
	)
}
