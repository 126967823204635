import clsx from 'clsx'
import MarkerArrow from './MarkerArrow'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from 'tss-react/mui'
import ConditionnalWrapper from '../ConditionnalWrapper'
import theme from '@ui/theme'

const sizes = {
	medium: {
		marker: {
			height: 28,
			width: 17
		},
		markerIcon: {
			width: 15,
			height: 15,
			fontSize: 15,
			top: 1,
			left: 1
		}
	},
	large: {
		marker: {
			height: 34,
			width: 23
		},
		markerIcon: {
			width: 21,
			height: 21,
			fontSize: 18,
			top: 2,
			left: 2
		}
	}
}

export default function Marker({ icon, isFilled, value, label, arrowColor, size }) {
	const min = 0
	const max = 5
	const actualSize = size ?? 'medium'

	function valueToPercent(value, min, max) {
		return ((value - min) * 100) / (max - min)
	}
	const left = valueToPercent(value, min, max)
	const marker = sizes[actualSize].marker
	const markerIcon = sizes[actualSize].markerIcon
	const color = isFilled ? 'white' : theme.palette.darkBlue.main
	const { classes } = useStyle({ left, marker, markerIcon, color })

	const validValue = value && value > 0
	if (!validValue) return <></>

	return (
		<ConditionnalWrapper
			condition={label}
			wrapper={(children) => (
				<Tooltip placement="top" title={<span style={{ whiteSpace: 'pre-line' }}>{label}</span>} arrow>
					{children}
				</Tooltip>
			)}
		>
			<span className={classes.marker}>
				<MarkerArrow color={arrowColor} isFilled={isFilled} />
				<span className={clsx(classes.markerIcon, color)}>{icon}</span>
			</span>
		</ConditionnalWrapper>
	)
}

const useStyle = makeStyles()((theme, { left, marker, markerIcon, color }) => ({
	marker: {
		position: 'absolute',
		display: 'inline-block',
		bottom: 23,
		height: marker.height,
		width: marker.width,
		left: left + '%',
		marginLeft: -(marker.width / 2)
	},
	markerIcon: {
		position: 'absolute',
		display: 'inline-block',
		width: markerIcon.width,
		height: markerIcon.height,
		fontSize: markerIcon.fontSize,
		top: markerIcon.top,
		left: markerIcon.left,
		borderRadius: '100%',
		overflow: 'hidden',
		color
	}
}))
