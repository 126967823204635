import PropTypes from 'prop-types'

import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

export default function RoutingButtonIcon(props) {
	const { route, icon, tooltipTitle } = props

	return (
		<Tooltip title={tooltipTitle}>
			<IconButton aria-label={tooltipTitle} style={{ cursor: 'pointer' }} href={route} target="_blank">
				{icon}
			</IconButton>
		</Tooltip>
	)
}

RoutingButtonIcon.propTypes = {
	icon: PropTypes.element.isRequired,
	route: PropTypes.string.isRequired,
	tooltipTitle: PropTypes.string
}
