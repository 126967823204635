import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from '@mui/material/Select'
import Input from '@mui/material/Input'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { useGetAssessorsQuery } from '@gqlTypes'

export default function AssessorsUpdateSection({ onValidate }) {
	const [currentAssessor, setCurrentAssessor] = useState([])
	const { t } = useTranslation(['userOfferStatus', 'lists'])
	const { data } = useGetAssessorsQuery()
	const assessorsList = data?.getAssessors ?? []

	const handleChange = (event) => {
		const selected = event.target.value
		if (selected.includes(null)) {
			setCurrentAssessor([])
			return
		}

		const payload = { assessors: {} }
		selected.map((assessor) => {
			payload.assessors[assessor] = assessor
			return null
		})
		setCurrentAssessor(event.target.value)
	}

	return (
		<DialogSection style={{ display: 'flex', flexDirection: 'row' }}>
			<div>{t('modal:massUpdate.assessor')}</div>
			<Select
				multiple
				value={currentAssessor}
				onChange={handleChange}
				input={<Input />}
				className="main-dialog-element"
			>
				<MenuItem value={null}>no assessors</MenuItem>
				{assessorsList.map((assessor) => (
					<MenuItem key={assessor.id} value={assessor.id}>
						{assessor.reverseFullName}
					</MenuItem>
				))}
			</Select>
			<Button onClick={() => onValidate('assessors', currentAssessor)}>{t('modal:massUpdate.update')}</Button>
		</DialogSection>
	)
}
