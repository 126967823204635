import StarRatingInput from '@ui/core/StarRatingInput'
import InputFilter from '@ui/filter/InputFilter'

export default function StarRating({ keyName, operation, label, unratedLabel }) {
	return (
		<InputFilter keyName={keyName} operation={operation}>
			<StarRatingInput
				id="min-alignment-filter"
				label={label}
				unratedLabel={unratedLabel}
				forceInteger
				orientation="horizontal"
			/>
		</InputFilter>
	)
}
