import {
	ROUTE_STUDENTS,
	ROUTE_STUDENTS_DETAIL,
	ROUTE_STUDENTS_DETAIL_TAB_DISCUSSION,
	ROUTE_STUDENTS_DETAIL_TAB_INFO,
	ROUTE_STUDENTS_DETAIL_TAB_ORGA_RETURN,
	ROUTE_STUDENTS_DETAIL_TAB_PROFILE,
	ROUTE_STUDENTS_DETAIL_TAB_PROGRESSION,
	ROUTE_STUDENTS_DETAIL_TAB_RETURNS,
	ROUTE_STUDENTS_DETAIL_TAB_CAREER
} from '../../../router/routes'
import UserPositionsDetails from '../../../features/details/pages/userPositions/UserPositionsDetails'
import { useParams } from 'react-router-dom'
import useStudentsDetails from './useStudentsDetails'
import { useTranslation } from 'react-i18next'

export default function StudentsDetails() {
	const { t } = useTranslation()

	const profilePath = ROUTE_STUDENTS_DETAIL_TAB_PROFILE
	const discussionPath = ROUTE_STUDENTS_DETAIL_TAB_DISCUSSION
	const progressionPath = ROUTE_STUDENTS_DETAIL_TAB_PROGRESSION
	const returnsPath = ROUTE_STUDENTS_DETAIL_TAB_RETURNS
	const informationsPath = [ROUTE_STUDENTS_DETAIL, ROUTE_STUDENTS_DETAIL_TAB_INFO]
	const orgaReturnPath = ROUTE_STUDENTS_DETAIL_TAB_ORGA_RETURN
	const careerPath = ROUTE_STUDENTS_DETAIL_TAB_CAREER
	const backRoute = ROUTE_STUDENTS
	const backToolTip = t('menu.students')

	const { userPositionId } = useParams()
	const infos = useStudentsDetails(userPositionId)

	return (
		<UserPositionsDetails
			progressionPath={progressionPath}
			profilePath={profilePath}
			discussionPath={discussionPath}
			informationsPath={informationsPath}
			returnsPath={returnsPath}
			infos={infos}
			userPositionId={userPositionId}
			orgaReturnPath={orgaReturnPath}
			backRoute={backRoute}
			backToolTip={backToolTip}
			careerPath={careerPath}
		/>
	)
}
