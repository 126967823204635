import { type ReactElement } from 'react'
import { FormHelperText, Stack } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useTranslation } from 'react-i18next'
import { ChipList } from '../../ChipList'

interface Props {
	name: string
	items: Item[]
	isLoading?: boolean
	maxSelectableItem?: number
	withOrder?: boolean
	minSelectableItem?: number
	isEditable?: boolean
	defaultValue?: string[]
}
interface Item {
	id: string
	label: string
	color?: string
	isPositive?: boolean
}

export const ChipSelector = ({
	name,
	items,
	isLoading = false,
	maxSelectableItem = 0,
	withOrder = false,
	minSelectableItem = 0,
	defaultValue = [],
	isEditable = true
}: Props): ReactElement | null => {
	const formContext = useFormContext()
	const { t } = useTranslation()
	const errors = formContext.formState?.errors ?? {}

	if (isLoading) return <LocalLoader />
	if (items.length === 0) return null

	const computeNewValue = (id: string, value: string[] = []): string[] => {
		return value.includes(id)
			? value.filter((value) => value !== id)
			: maxSelectableItem > 0 && value.length === maxSelectableItem
			? value
			: [...value, id]
	}

	const error = errors[name]

	return (
		<Controller
			defaultValue={defaultValue}
			name={name}
			rules={{
				validate: (array) =>
					array.length >= minSelectableItem ? true : t('form:errors.minSelectableItem', { count: minSelectableItem })
			}}
			render={({ value, onChange }) => {
				return (
					<Stack>
						<ChipList
							items={items}
							withOrder={withOrder}
							onChange={(itemId) => {
								onChange(computeNewValue(itemId, value as string[]))
							}}
							selectedItems={value}
							isEditable={isEditable}
						/>
						<FormHelperText error>{error?.message ?? ''}</FormHelperText>
					</Stack>
				)
			}}
		/>
	)
}
