import { useTranslation } from 'react-i18next'
import { filterOperation } from '@ui/filter/FilterForm'
import InputFilter from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'
import { useGetManagersQuery } from '@gqlTypes'

interface Props {
	keyLabel?: string
	keyName?: string
}

export default function ManagersFilter({ keyName = 'manager', keyLabel = 'managers' }: Props): JSX.Element {
	const { t } = useTranslation()
	const { data } = useGetManagersQuery()
	const managers = (data?.getManagers ?? []).map(({ id, reverseFullName }) => ({ value: id, label: reverseFullName }))

	return (
		<InputFilter keyName={keyName} operation={filterOperation.EQUAL} isAutoComplete>
			<AutoCompleteFilter
				options={managers}
				label={t(`lists:filter.${keyLabel}.label`)}
				allValueLabel={t(`lists:filter.${keyLabel}.allLabel`)}
				width="100%"
			/>
		</InputFilter>
	)
}
