import { useMeQuery, type UserOrganizationFragment } from '@gqlTypes'

interface Return {
	organization: UserOrganizationFragment | null
	orgaSkillsData: UserOrganizationFragment['orgaSkillsData']
	hasOrgaSkills: boolean
	organizationId: string
}

export const useOrganization = (): Return => {
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const organization = data?.me?.organization ?? null
	const orgaSkillsData = organization?.orgaSkillsData ?? []
	const hasOrgaSkills = orgaSkillsData.length > 0
	const organizationId = organization?.id ?? ''
	return { organization, orgaSkillsData, hasOrgaSkills, organizationId }
}
