import { type GetJobSearchListQuery } from '@gqlTypes'
import { Typography } from '@mui/material'
import { UserCard } from '@shared/components/cards/UserCard'
import { UserAvatar } from '@shared/components/UserAvatar'
import { type ReactElement } from 'react'
import { CurrentLocation } from '@domains/students/list/CurrentLocation'
import { DurationLabel } from '../jobMatching/DurationLabel'

interface Props {
	jobSearch: GetJobSearchListQuery['getJobSearchList']['nodes'][0]
}

export const JobSearchCard = ({ jobSearch }: Props): ReactElement => {
	const { userOffer, jobDurationLabels, startMonthLabel } = jobSearch
	const { userInfo, location, businessUnit, orgaOffer } = userOffer
	const publicTitle = userInfo?.publicTitle
	const subtitle = publicTitle != null && publicTitle !== '' ? publicTitle : orgaOffer?.label ?? ''
	return (
		<UserCard userRef={userInfo} avatarComponent={<UserAvatar user={userInfo} />}>
			<Typography sx={{ fontSize: '16px', color: 'grays.gray5' }}>{subtitle}</Typography>
			<CurrentLocation businessUnit={businessUnit} location={location} />
			<DurationLabel jobDurationLabels={jobDurationLabels} startMonthLabel={startMonthLabel} />
		</UserCard>
	)
}
