import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import ErrorBoundaryFallback from './ErrorBoundaryFallback'
import Bugsnag from '@bugsnag/js'

export default function ErrorBoundary(props) {
	const { children } = props
	return (
		<ReactErrorBoundary
			onError={(error, info) => {
				if (import.meta.env.NODE_ENV !== 'development') {
					Bugsnag.leaveBreadcrumb('Error boundary info', info, 'error')
					Bugsnag.notify(error)
				} else {
					console.error('ErrorBoundary Catch')
					console.log(error)
					console.log(info)
				}
			}}
			FallbackComponent={ErrorBoundaryFallback}
		>
			{children}
		</ReactErrorBoundary>
	)
}

ErrorBoundary.propTypes = {}

ErrorBoundary.defaultProps = {}
