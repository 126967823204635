import { INTEGRATION_NAME } from '../../../constants/INTEGRATION_TYPE'
import { getCommunityMembersTabs } from '@shared/components/layout/menus/menus'
import useUserPositionsDetails from '../../../features/details/hooks/userPositions/useUserPositionsDetails'

export function useCommunityMembersDetails(
	userPositionId: string
): (typeof useUserPositionsDetails)['prototype'] & { integrationName: string } {
	const subMenuTabs = getCommunityMembersTabs(userPositionId)
	const details = useUserPositionsDetails(userPositionId, subMenuTabs)
	return { ...details, integrationName: INTEGRATION_NAME.EMPLOYEE }
}
