import { useCallback } from 'react'
import KeyIndicatorCard from './KeyIndicatorCard'
import { useTranslation } from 'react-i18next'
import { Stack } from '@mui/material'

export default function KeyIndicatorsCardRow({ indicators }) {
	const { t } = useTranslation()

	const calculateIdentification = useCallback(
		(selfEvaluationRange, selfEvaluationStrengths, selfEvaluationWeaknesses) => {
			const indicator = 'identification'
			let text
			let type

			if (!selfEvaluationRange || !selfEvaluationStrengths || !selfEvaluationWeaknesses) type = 'empty'
			else if (selfEvaluationRange >= 2) {
				type = 'good'
				text = t(`details:indicators.${indicator}.${type}`, {
					strengths: selfEvaluationStrengths,
					weaknesses: selfEvaluationWeaknesses
				})
			} else type = 'bad'

			return {
				title: t(`details:indicators.${indicator}.${type}`),
				indicator,
				colorType: type,
				text
			}
		},
		[t]
	)

	const calculateSelfEvaluation = useCallback(
		(selfEvaluationAverage) => {
			const indicator = 'selfEvaluation'
			let type
			let text
			let title = t(`details:indicators.${indicator}.title`)

			if (!selfEvaluationAverage) {
				title = t(`details:indicators.${indicator}.empty`)
				type = 'empty'
			} else if (selfEvaluationAverage <= 1.33) {
				text = t(`details:indicators.${indicator}.bad`)
				type = 'average'
			} else if (selfEvaluationAverage >= 3.66) {
				text = t(`details:indicators.${indicator}.average`)
				type = 'average'
			} else {
				text = t(`details:indicators.${indicator}.good`)
				type = 'good'
			}

			return {
				title,
				indicator,
				colorType: type,
				text
			}
		},
		[t]
	)

	const calculateImageReturn = useCallback(
		(requestsCount, returnsCount, sharedRequestsCount) => {
			const indicator = 'imageReturn'
			let type

			if (returnsCount > 1) type = 'good'
			else if (returnsCount === 1) type = 'average'
			else type = 'bad'

			const text = (
				<>
					{t('details:indicators.requestsCount', { count: requestsCount ?? 0 })}
					<br />
					{t('details:indicators.returnsCount', { count: returnsCount ?? 0 })} <br />
					{t('details:indicators.sharedRequestsCount', { count: sharedRequestsCount ?? 0 })}
				</>
			)

			return {
				text,
				indicator,
				colorType: type
			}
		},
		[t]
	)

	const calculateAlignment = useCallback(
		(returnsCount, returnsAverage, selfEvaluationAverage) => {
			const indicator = 'alignment'
			let type = 'good'
			const alignment = returnsAverage - selfEvaluationAverage
			if (returnsCount === 0 || !returnsCount) type = 'empty'
			else if (alignment > 1.33) type = 'average'
			else if (alignment < -1.33) type = 'bad'

			return {
				title: t(`details:indicators.alignment.${type}`),
				indicator,
				colorType: type
			}
		},
		[t]
	)

	const calculateIndicators = useCallback(() => {
		return [
			calculateIdentification(
				indicators?.selfEvaluationRange,
				indicators?.selfEvaluationStrengths,
				indicators?.selfEvaluationWeaknesses
			),
			calculateSelfEvaluation(indicators?.selfEvaluationAverage),
			calculateImageReturn(indicators?.requestsCount, indicators?.returnsCount, indicators?.sharedRequestsCount),
			calculateAlignment(indicators?.returnsCount, indicators?.returnsAverage, indicators?.selfEvaluationAverage)
		]
	}, [indicators, calculateIdentification, calculateSelfEvaluation, calculateImageReturn, calculateAlignment])

	return (
		<Stack sx={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }} mb={5}>
			{calculateIndicators().map((card) => (
				<KeyIndicatorCard
					colorType={card.colorType}
					indicator={card.indicator}
					title={card.title}
					text={card.text}
					iconText={card.iconText}
					key={card.indicator}
				/>
			))}
		</Stack>
	)
}
