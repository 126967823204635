import { useCallback } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { buildPayload } from '../../../helper/queryBuilder.helper'

export default function useInformations(integrationInfo, updateIntegration, integrationKey) {
	const errorHandler = useErrorHandler()

	const handleChange = useCallback(
		(field, newValue) => {
			if (integrationInfo) {
				return updateIntegration(integrationInfo.id, buildPayload(field, newValue)).catch(errorHandler)
			}
		},
		[errorHandler, integrationInfo, updateIntegration]
	)

	return {
		handleChange,
		integrationKey
	}
}
