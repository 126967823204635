import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import useOrganizationUsersColumns from './useOrganizationUsersColumns'
import DataGridGQL from '@features/lists/components/DataGridGQL'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import ListBar from './ListBar'
import { useFilters } from '@shared/hooks/useFilters'
import PeopleIcon from '@mui/icons-material/People'
import { ScreenId, useGetOrganizationUserListQuery } from '@gqlTypes'
import { useState } from 'react'

export default function OrganizationUsers() {
	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { t } = useTranslation(['lists', 'organizationUsers'])
	useCheckRoleSecurity(ScreenId.OrganizationUser)
	useDocumentTitle(t('organizationUsers:list.document.title'))
	const columns = useOrganizationUsersColumns()
	const { filters } = useFilters()

	const variables = { limit: 12, filters }
	const { loading, data, fetchMore, refetch } = useGetOrganizationUserListQuery({ variables })

	let nodes = []
	let cursor = ''
	let hasNextPage = false
	let count = 0
	if (data && data.getOrganizationUserList) ({ nodes, cursor, hasNextPage, count } = data.getOrganizationUserList)

	return (
		<DataGridGQL
			emptyStateLabel={t('organizationUsers:dataGrid.empty')}
			columns={columns}
			setIsAllRowsSelected={setIsAllRowsSelected}
			isAllRowsSelected={isAllRowsSelected}
			dataList={nodes}
			isLoading={loading}
			fetchMore={fetchMore}
			cursor={cursor}
			hasNextPage={hasNextPage}
			title={t('organizationUsers:list.bar.title', { count })}
			Icon={PeopleIcon}
			toolbar={<ListBar isAllRowsSelected={isAllRowsSelected} dataList={nodes} refetch={refetch} />}
		/>
	)
}
