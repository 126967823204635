import { useState, type ReactElement } from 'react'
import { Box, ButtonBase, Grid, Stack, Typography } from '@mui/material'
import { SharedReturnList } from '@domains/feedbacks/tab/SharedReturnList'
import { SharedDetailsList } from '@domains/feedbacks/tab/SharedDetailsList'
import CardLayout from '@ui/core/CardLayout'
import { type GetUserIntegrationSoftSkillRestitutionQuery } from '@gqlTypes'
import { SoftSKillRestitutionKeywords } from '@features/details/components/interviewTab/SoftSKillRestitutionKeywords'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'

interface Props {
	softSkillRestitution: GetUserIntegrationSoftSkillRestitutionQuery['getUserIntegrationSoftSkillRestitution'][0]
	isDefaultOpen?: boolean
}

export const SoftSkillRestitutionSection = ({ softSkillRestitution, isDefaultOpen }: Props): ReactElement => {
	const { softSkill, feedbacks, behaviorsAverage, topKeywordsCount, bottomKeywordsCount } = softSkillRestitution
	const [isOpen, setIsOpen] = useState(isDefaultOpen)

	return (
		<div>
			<ButtonBase
				sx={{ alignContent: 'center', gap: 1.5, marginBottom: 3 }}
				disableRipple
				onClick={() => {
					setIsOpen(!(isOpen ?? false))
				}}
			>
				{isOpen ?? false ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
				<Typography sx={{ p: 0, fontWeight: 'bold', color: 'primary.main', fontSize: 24 }}>
					{softSkill.label}
				</Typography>
				<Box
					sx={{
						color: 'greenCount.main',
						backgroundColor: 'greenCount.secondary',
						height: 23,
						width: 29,
						borderRadius: 7,
						alignContent: 'center'
					}}
				>
					{feedbacks.length}
				</Box>
			</ButtonBase>
			{isOpen ?? false ? (
				<CardLayout sx={{ padding: 3 }}>
					<Stack spacing={2}>
						<Grid container spacing={10}>
							<Grid item xs={6}>
								<SharedReturnList sharedReturns={feedbacks} />
							</Grid>
							<Grid item xs={6} spacing={1}>
								<SharedDetailsList details={behaviorsAverage} />
								<SoftSKillRestitutionKeywords {...{ topKeywordsCount, bottomKeywordsCount }} />
							</Grid>
						</Grid>
					</Stack>
				</CardLayout>
			) : null}
		</div>
	)
}
