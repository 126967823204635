import { useState } from 'react'
import MassMessagesModal from '../components/actions/MassMessagesModal'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IconAction } from '@shared/icon/IconAction'

export default function MassMessages({ count, trackType, dataList, isAllRowsSelected, integrationCategory }) {
	const [isOpen, setIsOpen] = useState(false)
	const { t } = useTranslation(['lists'])
	const selectedRows = useSelector(({ selectedRows }) => selectedRows)
	const userIntegrationIds = selectedRows.map((index) => dataList[index].id)

	return (
		<>
			<IconAction
				tooltip={t('modal:massMessages.tooltip')}
				disabled={selectedRows.length < 1}
				onClick={() => {
					setIsOpen(true)
				}}
			>
				<SmsOutlinedIcon />
			</IconAction>
			<MassMessagesModal
				isOpen={isOpen}
				rowCount={count}
				setIsOpen={setIsOpen}
				userIntegrationsIds={isAllRowsSelected ? [] : userIntegrationIds}
				trackType={trackType}
				integrationCategory={integrationCategory}
			/>
		</>
	)
}
