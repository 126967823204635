import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BooleanUpdater from '../BooleanUpdater'

export default function EnglishUpdateSection({ onValidate }) {
	const [isEnglish, setIsEnglish] = useState('')
	const { t } = useTranslation(['userOfferStatus', 'lists'])

	return (
		<BooleanUpdater
			onValidate={onValidate}
			value={isEnglish}
			setvalue={setIsEnglish}
			title={t('modal:massUpdate.english')}
			field="english"
		/>
	)
}
