import { useTranslation } from 'react-i18next'
import MenuItem from '@mui/material/MenuItem'
import SelectorUpdater from '../SelectorUpdater'
import { useMeQuery } from '@gqlTypes'

export default function StatusUpdate({ onValidate }) {
	const { t } = useTranslation(['userOfferStatus', 'lists'])

	const { data, loading: isLoading } = useMeQuery()
	if (isLoading || data?.me == null) return null
	const statuses = data.me.organization.offerStatusObject

	return (
		<SelectorUpdater onValidate={onValidate} items={statuses} field="status" title={t('modal:massUpdate.status')}>
			{statuses.map((status) => {
				return (
					<MenuItem key={status.id} value={status.id}>
						{status.label}
					</MenuItem>
				)
			})}
		</SelectorUpdater>
	)
}
