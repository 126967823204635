import { useMeQuery } from '@gqlTypes'
import { useTranslation } from 'react-i18next'
import { filterOperation } from '@ui/filter/FilterForm'
import InputFilter from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'

export default function StatusFilter(): JSX.Element | null {
	const { t } = useTranslation()

	const { data, loading: isLoading } = useMeQuery()
	if (isLoading || data?.me == null) return null
	const status = data.me.organization.offerStatusObject.map((status) => ({ value: status.id, label: status.label }))

	return (
		<InputFilter keyName="status" operation={filterOperation.IN_ARRAY} isAutoComplete isMultiple>
			<AutoCompleteFilter options={status} label={t('lists:filter.status')} multiple limitTags={5} />
		</InputFilter>
	)
}
