import { ProfileResume } from '@domains/resume/ProfileResume'
import { useMySlugQuery } from '@gqlTypes'
import { useGqlSnackBar } from '@shared/gql/hooks/useGqlSnackBar'
import { type ReactElement } from 'react'

export const MyProfileResume = (): ReactElement | null => {
	const { onError } = useGqlSnackBar('translation:myProfileLoad')
	const { data, error } = useMySlugQuery({ onError })

	if (error != null || data?.me == null) return null
	return (
		<ProfileResume page={`${import.meta.env.VITE_WEB_PUBLIC_URL}resume/${data.me.slug}/iframe/${data.me.language}`} />
	)
}
