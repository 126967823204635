import TextField from '@ui/core/TextField'
import InputFilter from '@ui/filter/InputFilter'
import { type InputLabelProps } from '@mui/material'
import { filterOperation } from '@ui/filter/FilterForm'
import { type ReactElement } from 'react'

interface Props {
	keyName: string
	operation?: string
	label: string
	merged?: string
	type?: string
	InputLabelProps?: Partial<InputLabelProps>
}

export const TextFilter = ({
	keyName,
	operation = filterOperation.EQUAL,
	label,
	merged,
	type,
	InputLabelProps
}: Props): ReactElement => {
	return (
		<InputFilter keyName={keyName} operation={operation} isTextField>
			<TextField
				label={label}
				variant="standard"
				merged={merged}
				type={type}
				InputLabelProps={InputLabelProps}
				isBlurChange
			/>
		</InputFilter>
	)
}
