import { type ReactElement } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import InputFilter from '@ui/filter/InputFilter'

interface RadioItem {
	label: string
	value: string
}

interface Props {
	keyName: string
	operation: string
	radios: RadioItem[]
}

export const RadioFilter = ({ keyName, operation, radios }: Props): ReactElement => {
	return (
		<FormControl component="fieldset" variant="standard">
			<InputFilter keyName={keyName} operation={operation}>
				<RadioGroup row>
					{radios.map((radio) => (
						<FormControlLabel key={radio.value} value={radio.value} label={radio.label} control={<Radio />} />
					))}
				</RadioGroup>
			</InputFilter>
		</FormControl>
	)
}
