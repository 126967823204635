import {
	mdiSchool,
	mdiNaturePeople,
	mdiFaceMan,
	mdiHandshake,
	mdiLightbulbGroup,
	mdiAccountGroup,
	mdiSprout,
	mdiThumbUp,
	mdiCalendar,
	mdiEmoticonOutline,
	mdiCreation,
	mdiTableOfContents,
	mdiAnimationPlay,
	mdiHandHeart,
	mdiHumanMaleChild,
	mdiHeadQuestionOutline,
	mdiMotherHeart,
	mdiEarHearing,
	mdiHeartPlusOutline,
	mdiPuzzlePlusOutline,
	mdiAccountSwitch,
	// mdiTeach,
	mdiCommentAccountOutline,
	mdiCommentTextMultipleOutline,
	mdiMicrophoneVariant,
	mdiMessageCogOutline,
	mdiTeaOutline,
	mdiMessagePlusOutline,
	mdiMessageProcessingOutline,
	mdiMessageArrowRightOutline,
	mdiAccountTieVoiceOutline,
	mdiCommentQuoteOutline,
	mdiForum,
	mdiAccountNetworkOutline,
	mdiBookOpenPageVariant,
	mdiBookAccountOutline,
	mdiFaceAgent,
	mdiThermometer,
	mdiHuman,
	mdiAccountAlert,
	mdiHumanGreeting,
	mdiKettleSteamOutline,
	mdiRecycleVariant,
	mdiHeadCogOutline,
	mdiHumanHandsdown,
	mdiThermometerChevronDown,
	mdiSignalDistanceVariant,
	mdiHeadHeartOutline,
	mdiTagFaces,
	mdiImageFilterHdr,
	mdiHumanHandsup,
	mdiWalk,
	mdiHeadLightbulbOutline,
	mdiAccountTieOutline,
	mdiAccountPlus,
	mdiAccountHeart,
	mdiArrowTopRightBottomLeft,
	mdiAccountQuestion,
	mdiAccountCash,
	mdiBomb,
	mdiFlash,
	mdiCompass,
	mdiArmFlexOutline,
	mdiScaleBalance,
	mdiEyeCheckOutline,
	mdiFlag,
	mdiFeather,
	mdiAccountCheck,
	mdiRoomServiceOutline,
	mdiDog,
	mdiAccountSupervisorCircle,
	mdiHomeAccount,
	// mdiHandRight,
	mdiDirections,
	mdiHandPointingUp,
	mdiBatteryCharging80,
	mdiStepBackward,
	mdiEye,
	mdiChessKnight,
	mdiPalette,
	mdiHeadSyncOutline,
	mdiStar,
	mdiCogs,
	mdiHiking,
	mdiChartBubble,
	mdiAccountSupervisor,
	mdiAccountTieVoice,
	mdiDraw,
	mdiAutoFix,
	mdiGlasses,
	mdiPencilPlusOutline,
	// mdiEmailReceiveOutline,
	mdiEarth,
	mdiEmoticonExcitedOutline,
	mdiPodcast,
	mdiPriorityHigh,
	mdiLightbulbGroupOutline,
	mdiTimelineClockOutline,
	mdiCalendarArrowRight,
	mdiTimelineTextOutline,
	mdiTools,
	mdiTarget,
	mdiFileTreeOutline,
	mdiTimeline,
	mdiChartGantt,
	mdiFileCertificateOutline,
	mdiFileDocumentEditOutline,
	mdiHeartPulse,
	// mdiPollBoxOutline,
	mdiHeadDotsHorizontalOutline,
	mdiChartTimeline,
	mdiAccountHeartOutline,
	mdiArrowDecisionOutline,
	mdiRing,
	mdiCloudSearchOutline,
	mdiPuzzleStarOutline,
	mdiPencil,
	mdiLeadPencil,
	mdiFire,
	mdiHandOkay,
	mdiFinance,
	mdiBank,
	// mdiLaptopWindows,
	mdiTruckFastOutline,
	mdiGoogleAnalytics,
	// mdiDirectionsBoat,
	mdiKeyboardSettings,
	// mdiLaptopMac,
	mdiOutdoorLamp,
	mdiBookAccount,
	mdiFaceRecognition,
	mdiOrderBoolDescending,
	mdiSpiderWeb,
	mdiChatQuestion,
	mdiMonitorShare,
	mdiCommentBookmark,
	mdiAccountStar,
	mdiAccountMultiple,
	mdiAccountSearch,
	mdiAccountTie,
	mdiPlaylistCheck,
	mdiTooltipCheckOutline,
	mdiEmailOutline,
	mdiShieldAccount,
	mdiLifebuoy,
	mdiLightbulbOnOutline,
	mdiRocketLaunch,
	mdiAccountSchool,
	mdiDrawPen,
	mdiCardAccountDetailsStar,
	mdiFaceManShimmer,
	mdiBed,
	mdiFood,
	mdiCalendarMonthOutline,
	mdiWarehouse,
	mdiBusSide,
	mdiAirplane,
	mdiFerry,
	mdiCommentQuestion,
	mdiCommentQuote,
	mdiAccountCircle,
	mdiPoliceBadge,
	mdiCommentQuestionOutline,
	mdiLogin,
	mdiAccountGroupOutline,
	mdiBriefcaseSearchOutline,
	mdiTrafficCone,
	mdiRayStartArrow,
	mdiWindTurbine,
	mdiFileSearchOutline,
	mdiCheck,
	mdiThumbDown,
	mdiMinus,
	mdiAccountArrowUp,
	mdiLightbulbOn,
	mdiHeart,
	mdiEmoticonHappyOutline,
	mdiArrowRightTopBold,
	mdiChatQuestionOutline,
	mdiCrowd,
	mdiChatOutline,
	mdiStarBox,
	mdiRunFast,
	mdiLock,
	mdiArmFlex,
	mdiClockTimeThreeOutline,
	mdiLightningBolt,
	mdiCardsHeart,
	mdiArrowUpThick,
	mdiFormatListBulleted,
	mdiCrown,
	mdiControllerClassicOutline,
	mdiBrush,
	mdiHandshakeOutline,
	mdiBarcodeScan,
	mdiAtom,
	mdiCommentMultiple,
	mdiPineTree,
	mdiDoctor,
	mdiMonitor,
	mdiNumeric,
	mdiParagliding,
	mdiAirplaneTakeoff,
	mdiHandClap,
	mdiWrench,
	mdiCodeNotEqualVariant,
	mdiMagnify,
	mdiTownHall,
	mdiFilePdfBox,
	mdiNumericPositive1,
	mdiAccount,
	mdiDomain,
	mdiCompassOutline,
	mdiFolderAccount,
	mdiFoodApple,
	mdiVideo,
	mdiDramaMasks,
	mdiShopping,
	mdiSpa,
	mdiHanger,
	mdiChemicalWeapon,
	mdiBriefcase,
	mdiNewspaper,
	mdiRobot,
	mdiCloud,
	mdiSilverware,
	mdiBeach,
	mdiHome,
	mdiHammer,
	mdiDiamond,
	mdiCube,
	mdiCrane,
	mdiGlassFragile,
	mdiBook,
	mdiSawBlade,
	mdiTshirtCrew,
	mdiFactory,
	mdiCog,
	mdiCar,
	mdiRecycle,
	mdiLeaf,
	mdiFlask,
	mdiAlphabetical,
	mdiAssistant,
	mdiCalculator,
	mdiHomeCity,
	mdiGavel,
	mdiSale,
	mdiTractor,
	mdiFlower,
	mdiHospital,
	mdiSoccer,
	mdiTrain,
	mdiTruck,
	mdiStore,
	mdiDesktopMac,
	mdiDiamondStone
} from '@mdi/js'
import { SvgIcon, type SxProps } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	name: string
	size?: number | string
	color?: string
	style?: React.CSSProperties
	sx?: SxProps
}

// eslint-disable-next-line react/display-name
export default ({ name, size = 24, color, style, sx }: Props): ReactElement | null => {
	if (name == null) return null
	const paths: Record<string, string> = {
		login: mdiLogin,
		'card-account-details-star': mdiCardAccountDetailsStar,
		'face-man-shimmer': mdiFaceManShimmer,
		bed: mdiBed,
		food: mdiFood,
		'calendar-month-outline': mdiCalendarMonthOutline,
		warehouse: mdiWarehouse,
		'bus-side': mdiBusSide,
		airplane: mdiAirplane,
		ferry: mdiFerry,
		'briefcase-search-outline': mdiBriefcaseSearchOutline,
		'account-school': mdiAccountSchool,
		'shield-account': mdiShieldAccount,
		'email-outline': mdiEmailOutline,
		'tooltip-check-outline': mdiTooltipCheckOutline,
		'playlist-check': mdiPlaylistCheck,
		'account-tie': mdiAccountTie,
		school: mdiSchool,
		'account-search': mdiAccountSearch,
		'nature-people': mdiNaturePeople,
		'face-man': mdiFaceMan,
		handshake: mdiHandshake,
		'lightbulb-group': mdiLightbulbGroup,
		'account-group': mdiAccountGroup,
		sprout: mdiSprout,
		'thumb-up': mdiThumbUp,
		calendar: mdiCalendar,
		'emoticon-outline': mdiEmoticonOutline,
		creation: mdiCreation,
		'table-of-contents': mdiTableOfContents,
		'animation-play': mdiAnimationPlay,
		'hand-heart': mdiHandHeart,
		'human-male-child': mdiHumanMaleChild,
		'head-question-outline': mdiHeadQuestionOutline,
		'mother-heart': mdiMotherHeart,
		'ear-hearing': mdiEarHearing,
		'heart-plus-outline': mdiHeartPlusOutline,
		'puzzle-plus-outline': mdiPuzzlePlusOutline,
		'account-switch': mdiAccountSwitch,
		// teach: mdiTeach,
		'comment-account-outline': mdiCommentAccountOutline,
		'comment-text-multiple-outline': mdiCommentTextMultipleOutline,
		'microphone-variant': mdiMicrophoneVariant,
		'message-cog-outline': mdiMessageCogOutline,
		'tea-outline': mdiTeaOutline,
		'message-plus-outline': mdiMessagePlusOutline,
		'message-processing-outline': mdiMessageProcessingOutline,
		'message-arrow-right-outline': mdiMessageArrowRightOutline,
		'account-tie-voice-outline': mdiAccountTieVoiceOutline,
		'comment-quote-outline': mdiCommentQuoteOutline,
		forum: mdiForum,
		'account-network-outline': mdiAccountNetworkOutline,
		'book-open-page-variant': mdiBookOpenPageVariant,
		'book-account-outline': mdiBookAccountOutline,
		'face-agent': mdiFaceAgent,
		thermometer: mdiThermometer,
		human: mdiHuman,
		'account-alert': mdiAccountAlert,
		'human-greeting': mdiHumanGreeting,
		'kettle-steam-outline': mdiKettleSteamOutline,
		'recycle-variant': mdiRecycleVariant,
		'head-cog-outline': mdiHeadCogOutline,
		'human-handsdown': mdiHumanHandsdown,
		'thermometer-chevron-down': mdiThermometerChevronDown,
		'signal-distance-variant': mdiSignalDistanceVariant,
		'head-heart-outline': mdiHeadHeartOutline,
		'tag-faces': mdiTagFaces,
		'image-filter-hdr': mdiImageFilterHdr,
		'human-handsup': mdiHumanHandsup,
		walk: mdiWalk,
		'head-lightbulb-outline': mdiHeadLightbulbOutline,
		'account-tie-outline': mdiAccountTieOutline,
		'account-plus': mdiAccountPlus,
		'account-heart': mdiAccountHeart,
		'arrow-top-right-bottom-left': mdiArrowTopRightBottomLeft,
		'account-question': mdiAccountQuestion,
		'account-cash': mdiAccountCash,
		bomb: mdiBomb,
		flash: mdiFlash,
		compass: mdiCompass,
		'arm-flex-outline': mdiArmFlexOutline,
		'scale-balance': mdiScaleBalance,
		'eye-check-outline': mdiEyeCheckOutline,
		flag: mdiFlag,
		feather: mdiFeather,
		'account-check': mdiAccountCheck,
		'room-service-outline': mdiRoomServiceOutline,
		dog: mdiDog,
		'account-supervisor-circle': mdiAccountSupervisorCircle,
		'home-account': mdiHomeAccount,
		// 'hand-right': mdiHandRight,
		directions: mdiDirections,
		'hand-pointing-up': mdiHandPointingUp,
		'battery-charging-80': mdiBatteryCharging80,
		'step-backward': mdiStepBackward,
		eye: mdiEye,
		'chess-knight': mdiChessKnight,
		palette: mdiPalette,
		'head-sync-outline': mdiHeadSyncOutline,
		star: mdiStar,
		cogs: mdiCogs,
		hiking: mdiHiking,
		'chart-bubble': mdiChartBubble,
		'account-supervisor': mdiAccountSupervisor,
		'account-group-outline': mdiAccountGroupOutline,
		'account-tie-voice': mdiAccountTieVoice,
		draw: mdiDraw,
		'auto-fix': mdiAutoFix,
		glasses: mdiGlasses,
		'pencil-plus-outline': mdiPencilPlusOutline,
		// 'email-receive-outline': mdiEmailReceiveOutline,
		earth: mdiEarth,
		'emoticon-excited-outline': mdiEmoticonExcitedOutline,
		podcast: mdiPodcast,
		'priority-high': mdiPriorityHigh,
		'lightbulb-group-outline': mdiLightbulbGroupOutline,
		'lightbulb-outline': mdiLightbulbOnOutline,
		'timeline-clock-outline': mdiTimelineClockOutline,
		'calendar-arrow-right': mdiCalendarArrowRight,
		'timeline-text-outline': mdiTimelineTextOutline,
		tools: mdiTools,
		target: mdiTarget,
		'file-tree-outline': mdiFileTreeOutline,
		timeline: mdiTimeline,
		'chart-gantt': mdiChartGantt,
		'file-certificate-outline': mdiFileCertificateOutline,
		'file-document-edit-outline': mdiFileDocumentEditOutline,
		'heart-pulse': mdiHeartPulse,
		// 'poll-box-outline': mdiPollBoxOutline,
		'head-dots-horizontal-outline': mdiHeadDotsHorizontalOutline,
		'chart-timeline': mdiChartTimeline,
		'account-heart-outline': mdiAccountHeartOutline,
		'arrow-decision-outline': mdiArrowDecisionOutline,
		ring: mdiRing,
		'cloud-search-outline': mdiCloudSearchOutline,
		'puzzle-star-outline': mdiPuzzleStarOutline,
		pencil: mdiPencil,
		'lead-pencil': mdiLeadPencil,
		fire: mdiFire,
		'hand-okay': mdiHandOkay,
		finance: mdiFinance,
		bank: mdiBank,
		// 'laptop-windows': mdiLaptopWindows,
		'truck-fast-outline': mdiTruckFastOutline,
		'google-analytics': mdiGoogleAnalytics,
		// 'directions-boat': mdiDirectionsBoat,
		'keyboard-settings': mdiKeyboardSettings,
		// 'laptop-mac': mdiLaptopMac,
		'outdoor-lamp': mdiOutdoorLamp,
		'book-account': mdiBookAccount,
		'face-recognition': mdiFaceRecognition,
		'order-bool-descending': mdiOrderBoolDescending,
		'spider-web': mdiSpiderWeb,
		'chat-question': mdiChatQuestion,
		'monitor-share': mdiMonitorShare,
		'comment-bookmark': mdiCommentBookmark,
		'account-star': mdiAccountStar,
		'account-multiple': mdiAccountMultiple,
		lifebuoy: mdiLifebuoy,
		'light-bulb-outline': mdiLightbulbOnOutline,
		'rocket-launch': mdiRocketLaunch,
		'draw-pen': mdiDrawPen,
		'comment-question': mdiCommentQuestion,
		'comment-quote': mdiCommentQuote,
		'account-circle': mdiAccountCircle,
		'police-badge': mdiPoliceBadge,
		'comment-question-outline': mdiCommentQuestionOutline,
		'traffic-cone': mdiTrafficCone,
		'ray-start-arrow': mdiRayStartArrow,
		'wind-turbine': mdiWindTurbine,
		'file-search-outline': mdiFileSearchOutline,
		check: mdiCheck,
		'thumb-down': mdiThumbDown,
		minus: mdiMinus,
		'account-arrow-up': mdiAccountArrowUp,
		heart: mdiHeart,
		'lightbulb-on': mdiLightbulbOn,
		'emoticon-happy-outline': mdiEmoticonHappyOutline,
		'arrow-right-top-bold': mdiArrowRightTopBold,
		'chat-question-outline': mdiChatQuestionOutline,
		crowd: mdiCrowd,
		'chat-outline': mdiChatOutline,
		'star-box': mdiStarBox,
		'run-fast': mdiRunFast,
		lock: mdiLock,
		'arm-flex': mdiArmFlex,
		'clock-time-three-outline': mdiClockTimeThreeOutline,
		'lightning-bolt': mdiLightningBolt,
		'cards-heart': mdiCardsHeart,
		'arrow-up-thick': mdiArrowUpThick,
		'format-list-bulleted': mdiFormatListBulleted,
		crown: mdiCrown,
		'controller-classic-outline': mdiControllerClassicOutline,
		brush: mdiBrush,
		'handshake-outline': mdiHandshakeOutline,
		'barcode-scan': mdiBarcodeScan,
		atom: mdiAtom,
		'comment-multiple': mdiCommentMultiple,
		'pine-tree': mdiPineTree,
		doctor: mdiDoctor,
		monitor: mdiMonitor,
		numeric: mdiNumeric,
		paragliding: mdiParagliding,
		'airplane-takeoff': mdiAirplaneTakeoff,
		'hand-clap': mdiHandClap,
		wrench: mdiWrench,
		'code-not-equal-variant': mdiCodeNotEqualVariant,
		magnify: mdiMagnify,
		'town-hall': mdiTownHall,
		'file-pdf-box': mdiFilePdfBox,
		'numeric-positive-1': mdiNumericPositive1,
		account: mdiAccount,
		domain: mdiDomain,
		compas: mdiCompassOutline,
		'folder-account': mdiFolderAccount,
		foodApple: mdiFoodApple,
		video: mdiVideo,
		'drama-mask': mdiDramaMasks,
		shopping: mdiShopping,
		spa: mdiSpa,
		hanger: mdiHanger,
		chemicalWeapon: mdiChemicalWeapon,
		briefcase: mdiBriefcase,
		newspaper: mdiNewspaper,
		robot: mdiRobot,
		cloud: mdiCloud,
		silverware: mdiSilverware,
		beach: mdiBeach,
		home: mdiHome,
		hammer: mdiHammer,
		diamond: mdiDiamond,
		cube: mdiCube,
		crane: mdiCrane,
		'glass-fragile': mdiGlassFragile,
		book: mdiBook,
		'saw-blade': mdiSawBlade,
		'tshirt-crew': mdiTshirtCrew,
		factory: mdiFactory,
		mdischool: mdiSchool,
		cog: mdiCog,
		car: mdiCar,
		recycle: mdiRecycle,
		leaf: mdiLeaf,
		flask: mdiFlask,
		alphabetical: mdiAlphabetical,
		assistant: mdiAssistant,
		calculator: mdiCalculator,
		'home-city': mdiHomeCity,
		gavel: mdiGavel,
		sale: mdiSale,
		tractor: mdiTractor,
		flower: mdiFlower,
		hospital: mdiHospital,
		soccer: mdiSoccer,
		train: mdiTrain,
		truck: mdiTruck,
		store: mdiStore,
		'desktop-mac': mdiDesktopMac,
		'diamond-stone': mdiDiamondStone
	}

	const path = paths[name]
	if (path == null) return null
	const width = size

	return (
		<SvgIcon sx={sx} style={{ width, height: width, ...style }}>
			<svg viewBox="0 0 24 24" role="presentation">
				<path d={path} fill={color} />
			</svg>
		</SvgIcon>
	)
}
