import Divider from '@mui/material/Divider'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import Typography from '@mui/material/Typography'
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import { makeStyles } from 'tss-react/mui'
import { useTranslation } from 'react-i18next'

export default function Legend({ isSoftskillReturnShared, isSoftskillTestShared }) {
	const { t } = useTranslation()
	const { classes } = useStyles()

	return (
		<>
			<Divider />
			<InfoCardContent className={classes.legend}>
				<Typography>
					<span className="delimiter icon" />
					{t('translation:candidates.profile.requirement')}
				</Typography>
				<Typography>
					<span className="progress icon" />
					{t('translation:candidates.profile.interviewEvaluation')}
				</Typography>

				<Typography>
					<span className="icon">
						<SupervisedUserCircleOutlinedIcon fontSize="inherit" />
					</span>
					{t('translation:candidates.profile.ecosystemReturn')}
					{!isSoftskillReturnShared && <> {t('translation:candidates.profile.softSkills.unshared')}</>}
				</Typography>

				<Typography>
					<span className="icon">
						<AccountCircleOutlinedIcon fontSize="inherit" />
					</span>
					{t('translation:candidates.profile.selfEvaluation')}
					{!isSoftskillTestShared && <> {t('translation:candidates.profile.softSkills.unshared')}</>}
				</Typography>
			</InfoCardContent>
		</>
	)
}

const useStyles = makeStyles()((theme) => ({
	legend: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gap: 10,

		'& .icon': {
			width: 30,
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100%',
			marginRight: 10,
			fontSize: 20,
			verticalAlign: 'middle',
			paddingBottom: 2
		},

		'& .progress::after': {
			content: '""',
			width: '100%',
			height: 6,
			borderRadius: 6,
			background: `linear-gradient(to right, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark})`
		},

		'& .delimiter::after': {
			content: '""',
			height: 20,
			borderLeft: 'solid currentcolor 1px',
			color: theme.palette.secondary.main
		},

		'& .delimiter': {}
	}
}))
