import { useUpdateUserPositionMutation } from '@gqlTypes'
import useInformations from '../useInformations'

export default function useUserPositionsInformations(integrationInfo) {
	const [updateUserPosition] = useUpdateUserPositionMutation()
	const query = async (userPositionId, userPositionInput) => {
		await updateUserPosition({ variables: { userPositionId, userPositionInput } })
	}

	return useInformations(integrationInfo, query, 'orgaPosition')
}
