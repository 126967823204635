import { type OrganizationListItem, useMeQuery, ScreenId } from '@gqlTypes'
import DashboardIcon from '@mui/icons-material/Dashboard'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import { type ReactElement } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import MDIIcon from '@shared/components/Icon/MDIIcon'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import { OffersPath } from '@domains/offers/OffersRouter'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { myProfileRouter } from '@domains/myProfile/myProfile.router'
import { RoutePath } from '../../router/RoutePath'
import FolderSharedIcon from '@mui/icons-material/FolderShared'

export interface MenuItem {
	id: string
	label: string
	path: string
	exact?: boolean
	icon: ReactElement
}

interface UseMainToolbarMenu {
	menu: MenuItem[]
	selectedItemId: string
	organizationList: OrganizationListItem[]
	currentOrganizationId: string
}

export const menu: MenuItem[] = [
	{
		id: ScreenId.Home,
		label: 'menu.home',
		path: RoutePath.Home,
		exact: true,
		icon: <DashboardIcon />
	},
	{
		id: ScreenId.OffersMatching,
		label: 'menu.offers-matching',
		path: OffersPath.list,
		icon: <MDIIcon name="file-search-outline" />
	},
	{
		id: ScreenId.Candidates,
		label: 'menu.candidates',
		path: RoutePath.Candidates,
		icon: <PersonSearchIcon />
	},
	{
		id: ScreenId.Students,
		label: 'menu.students',
		path: RoutePath.Students,
		icon: <MDIIcon name="account-school" />
	},
	{
		id: ScreenId.Employees,
		label: 'menu.employees',
		path: RoutePath.Employees,
		icon: <MDIIcon name="account-tie" />
	},
	{
		id: ScreenId.CommunityMembers,
		label: 'menu.communityMembers',
		path: RoutePath.CommunityMembers,
		icon: <MDIIcon name="account-group" />
	},
	{
		id: ScreenId.Interviews,
		label: 'menu.interview',
		path: RoutePath.Interviews,
		icon: <PlaylistAddCheckIcon />
	},
	{
		id: ScreenId.Discussions,
		label: 'menu.chat',
		path: RoutePath.Discussions,
		icon: <MailOutlineIcon />
	},
	{
		id: ScreenId.OrgaReturns,
		label: 'menu.orgaReturns',
		path: RoutePath.OrgaReturns,
		icon: <MDIIcon name="tooltip-check-outline" />
	},
	{
		id: ScreenId.MyInterviews,
		label: 'menu.my-interviews',
		path: RoutePath.MyInterview,
		icon: <RecentActorsIcon />
	},
	{
		id: myProfileRouter.roleId,
		label: 'menu.my-profile',
		path: myProfileRouter.path.resume,
		icon: <AccountCircleIcon />
	},
	{
		id: ScreenId.ResumeLibrary,
		label: 'menu.resumeLibrary',
		path: RoutePath.resumeLibrary,
		icon: <FolderSharedIcon />
	}
]

export const useMainToolbarMenu = (): UseMainToolbarMenu => {
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const { pathname } = useLocation()
	const roleMenu = data?.me?.role?.menu ?? []
	const selectedItemId = menu.find((item) => matchPath(pathname, item))?.id ?? ''
	const authMenu = menu.filter(({ id }) => roleMenu.includes(id) || id === ScreenId.Home)
	const organizationList = data?.me?.organizationList ?? []
	const currentOrganizationId = data?.me?.organization.id ?? ''

	return { menu: authMenu, selectedItemId, organizationList, currentOrganizationId }
}
