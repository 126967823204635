import { Box, Stack } from '@mui/material'
import CardLayout from '@ui/core/CardLayout'
import { Fragment, type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Attachment } from '@shared/storage/components/Attachment'
import { ImportFileButton } from '@shared/storage/components/ImportFileButton'
import { useUploadInterviewAttachment } from './hooks/useUploadInterviewAttachment'
import { useDeleteInterviewAttachment } from './hooks/useDeleteInterviewAttachment'
import { type InterviewAttachment } from '@gqlTypes'
import { InterviewAttachmentDeleteDialog } from './InterviewAttachmentDeleteDialog'

interface Props {
	connectedUserId: string
	interview: {
		id: string
		organizationId: string
		isArchived: boolean
		isSignedByAssessed: boolean
		isSignedByAssessor: boolean
		attachmentsObject: InterviewAttachment[]
		juryId: string
	}
}

export const InterviewAttachments = ({ interview, connectedUserId }: Props): ReactElement => {
	const {
		id: interviewId,
		organizationId,
		isArchived,
		isSignedByAssessed,
		isSignedByAssessor,
		attachmentsObject: attachments
	} = interview
	const { t } = useTranslation()
	const [fileNameChosenToBeDeleted, setFileNameChosenToBeDeleted] = useState<string | null>(null)
	const { upload } = useUploadInterviewAttachment({ connectedUserId, interviewId, organizationId, attachments })
	const { remove: onDelete } = useDeleteInterviewAttachment({ interviewId, attachments })

	return (
		<Box>
			<CardLayout title={t('translation:attachment.title')}>
				<Stack sx={{ mx: 4, my: 3 }}>
					{t('translation:attachment.explanation')}
					{attachments.map(({ fileUrl, fileName, authorId }) => (
						<Fragment key={fileUrl}>
							<Attachment
								{...{
									attachment: { fileUrl, fileName },
									onDelete: () => {
										setFileNameChosenToBeDeleted(fileName)
									},
									hasDeleteButton:
										authorId === connectedUserId && !isArchived && !isSignedByAssessor && !isSignedByAssessed
								}}
							/>
						</Fragment>
					))}
					<InterviewAttachmentDeleteDialog
						{...{
							onDelete,
							fileName: fileNameChosenToBeDeleted,
							setFileName: setFileNameChosenToBeDeleted
						}}
					/>
				</Stack>
				{!isArchived && !isSignedByAssessor && !isSignedByAssessed ? (
					<Stack className="no-print" direction="row" sx={{ ml: 3, mb: 3 }}>
						<ImportFileButton
							onSelectFile={upload}
							label={t('translation:attachment.button')}
							accept="image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						/>
					</Stack>
				) : null}
			</CardLayout>
		</Box>
	)
}
