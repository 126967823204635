import { type GetCareerMatchingsQuery } from '@gqlTypes'
import { Card, CardContent, CircularProgress, Stack, Tooltip, Typography } from '@mui/material'
import { type ReactElement } from 'react'
import { CareerMatchingDetailScores } from './CareerMatchingDetailScores'
import MDIIcon from '@shared/components/Icon/MDIIcon'
import theme from '@ui/theme'
import { reduceString } from '#helpers/string.helper'

interface Props {
	matching: GetCareerMatchingsQuery['getCareerMatchings'][0]
}

export const CareerMatchingCard = ({ matching }: Props): ReactElement => {
	const profile = matching.careerProfile
	const domainLabels = profile.motivationLabels.domains.map((domain) => domain.label).join(', ')
	return (
		<Card elevation={3} sx={{ width: 270, borderRadius: 3, display: 'inline-block', height: '100%' }}>
			<Stack justifyContent="space-between" sx={{ height: '100%' }}>
				<CardContent>
					<Stack sx={{ height: 170 }}>
						<Stack direction="row" sx={{ alignItems: 'center', mb: 1 }}>
							<MDIIcon color={theme.palette.primary.main} name={profile.icon} size={60} />
							<Stack direction="row" sx={{ alignItems: 'center', gap: 1 }}>
								<CircularProgress variant="determinate" value={matching.score} color={'secondary'} />
								<Typography color={'secondary'}>{`${matching.score} %`}</Typography>
							</Stack>
						</Stack>

						<Stack justifyContent="space-between" sx={{ gap: 1 }}>
							<Typography fontWeight="bold" style={{ wordBreak: 'break-word' }}>
								{profile.label}
							</Typography>
							<Tooltip title={domainLabels}>
								<Typography>{reduceString(domainLabels, 25)}</Typography>
							</Tooltip>
						</Stack>
					</Stack>
					<CareerMatchingDetailScores detailScores={matching.scoreDetails} />
				</CardContent>
			</Stack>
		</Card>
	)
}
