import InfoValue from '@features/details/components/informationsTab/InfoValue'
import CardLayout from '@ui/core/CardLayout'
import { TableLayout } from '@ui/core/TableLayout'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import EventIcon from '@mui/icons-material/Event'
import WorkIcon from '@mui/icons-material/Work'
import { IntegrationCategory } from '@gqlTypes'

interface Props {
	category: string
	registerDateLabel: string
	publicTitle: string
}

export const KeyInformationsCard = ({ category, registerDateLabel, publicTitle }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const hasPublicTitle = publicTitle !== ''
	const hasRegisterDate = registerDateLabel !== ''

	if (category !== IntegrationCategory.Company || (!hasPublicTitle && !hasRegisterDate)) return null

	return (
		<CardLayout title={t('translation:candidates.informations.cardTitle.generalInfos')}>
			<TableLayout>
				{registerDateLabel != null ? (
					<InfoValue
						icon={<EventIcon />}
						title={t('interview:keyInformations.registerDate')}
						value={registerDateLabel}
					/>
				) : null}
				{hasPublicTitle ? (
					<InfoValue icon={<WorkIcon />} title={t('interview:keyInformations.publicTitle')} value={publicTitle} />
				) : null}
			</TableLayout>
		</CardLayout>
	)
}
