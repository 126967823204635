import { useState } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import EmployeesFilterBar from './EmployeesFilterBar'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { ScreenId, useGetEmployeesListQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { Container } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import { EmployeeCard } from './EmployeeCard'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { searchQuery } from '@features/lists/components/Search'
import { useOrganization } from '@domains/organization/useOrganization'

export default function Employees() {
	const { t } = useTranslation(['lists', 'positions'])
	useDocumentTitle(t('employees:title'))
	useCheckRoleSecurity(ScreenId.Employees)
	const { filters } = useFilters()

	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { organizationId } = useOrganization()

	const variables = { organizationId, limit: 12, filters, searchQuery: searchQuery() }
	const { loading, data, refetch, fetchMore } = useGetEmployeesListQuery({ variables })
	useDefaultFilter({ refetch })

	let nodes = []
	let cursor = ''
	let hasNextPage = false
	let count = 0
	if (data && data.getEmployeesList) ({ nodes, cursor, hasNextPage, count } = data.getEmployeesList)

	return (
		<Container maxWidth="xl">
			<CardList
				emptyStateLabel={t('employees:dataGrid.empty')}
				renderItem={(item, isSelected, selectRow) => (
					<EmployeeCard key={item.id} position={item} isSelected={isSelected} selectRow={selectRow} />
				)}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				dataTestId="employees-datatable"
				title={t('employees:title', { count })}
				Icon={RecordVoiceOverIcon}
				toolbar={
					<EmployeesFilterBar isAllRowsSelected={isAllRowsSelected} refetch={refetch} dataList={nodes} count={count} />
				}
				refetch={refetch}
				hasSearch
			/>
		</Container>
	)
}
