import MassMessages from '@features/modals/pages/MassMessages'
import { MassReminders } from '@features/modals/pages/MassReminders'
import { CheckboxInput } from '@features/lists/components/Filters/CheckboxInput'
import { filterOperation } from '@ui/filter/FilterForm'
import { useTranslation } from 'react-i18next'
import { TextFilter } from '@features/lists/components/Filters/TextFilter'
import PhoneSelect from '@features/lists/components/Filters/PhoneSelect'
import StarRating from '@features/lists/components/Filters/StarRating'
import { Fullname } from '@features/lists/components/Filters/Fullname'
import OfferAndJury from '@features/lists/components/Filters/OfferAndJury'
import MassFeedback from '@features/modals/pages/MassFeedback'
import { ExportGQL } from '@features/modals/components/actions/ExportGQL'
import { USER_OFFER_EXPORT } from './userOfferExport'
import { IntegrationCategory, TrackType, useUpdateManyUserIntegrationsMutation } from '@gqlTypes'
import { InterviewTypeStateFilter } from '@features/lists/components/Filters/InterviewTypeStateFilter'
import { MassUpdatesGql } from '@features/modals/pages/MassUpdatesGql'
import { Divider } from '@mui/material'
import { Filters } from '@features/lists/components/Filters'
import { LeftBar } from '@features/lists/components/LeftBar'
import { FiltersContent } from '@features/lists/components/FiltersContent'
import StatusFilter from '@features/lists/components/Filters/StatusFilter'
import { useOrganization } from '@domains/organization/useOrganization'
import { useRole } from '@shared/hooks/useRole'

export default function CandidatesFilterBar({ isAllRowsSelected, refetch, dataList, count }) {
	const { hasOrgaSkills } = useOrganization()
	const { t } = useTranslation(['lists', 'candidates'])
	const { screens } = useRole()
	const [updateManyUserIntegrations, { loading }] = useUpdateManyUserIntegrationsMutation()
	const massAction = {
		trackType: TrackType.Offer,
		integrationCategory: IntegrationCategory.School,
		isAllRowsSelected,
		dataList
	}
	return (
		<LeftBar>
			{screens.candidates.hasAdminAccess ? (
				<MassUpdatesGql
					{...massAction}
					query={updateManyUserIntegrations}
					refetch={refetch}
					isLoading={loading}
					count={count}
				/>
			) : null}

			<MassMessages {...massAction} count={count} />
			<MassReminders {...massAction} />
			{hasOrgaSkills ? <MassFeedback {...massAction} count={count} /> : null}
			<ExportGQL isAllRowsSelected={isAllRowsSelected} dataList={dataList} QUERY={USER_OFFER_EXPORT} />
			<Filters refetch={refetch}>
				<FiltersContent>
					<OfferAndJury />
					<StatusFilter />
					<CheckboxInput keyName="hasReminder" translationKey="lists:filter.withReminder" />
					<CheckboxInput keyName="isArchived" translationKey="lists:filter.archived" />
					<CheckboxInput keyName="steps.profile" translationKey="lists:filter.steps.profile" />
					<CheckboxInput keyName="steps.softskills" translationKey="lists:filter.steps.softSkills" />
					<CheckboxInput keyName="steps.returnsRequests" translationKey="lists:filter.steps.returnsRequests" />
					<StarRating
						keyName="alignment"
						operation={filterOperation.CONTAIN}
						label={t('lists:filter.minAlignment')}
						unratedLabel={t('lists:filter.minAlignmentUnspecified')}
					/>
					<InterviewTypeStateFilter />
				</FiltersContent>
				<Divider sx={{ my: 4 }} />
				<FiltersContent>
					<Fullname />
					<TextFilter keyName="userInfo.email" label={t('lists:filter.email')} />
					<PhoneSelect keyName="userInfo.mobile" label={t('lists:filter.phone')} />
				</FiltersContent>
			</Filters>
		</LeftBar>
	)
}
