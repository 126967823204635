import DetailBar from '@domains/organizationUsers/details/DetailBar'
import {
	type InterviewTemplateInput,
	type UpdateInterviewTemplateMutationVariables,
	useGetInterviewTemplateQuery,
	useUpdateInterviewTemplateMutation
} from '@gqlTypes'
import { Container, Grid, Stack } from '@mui/material'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type ReactElement } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import MultiSelectCard from './MultiSelectCard'
import { useSnackbar } from 'notistack'
import { Severities } from '../../constants/Severities'
import { ErrorHandler } from '#helpers/error.helper'
import { InterviewTemplateGeneralCard } from './InterviewTemplateGeneralCard'
import { ROUTE_INTERVIEW_TEMPLATE_LIST } from '../../router/routes'

export const InterviewTemplateDetail = (): ReactElement | null => {
	const { interviewTemplateId } = useParams<{ interviewTemplateId: string }>()
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()

	const formMethods = useForm({ mode: 'onChange' })
	const { data, loading: isLoading } = useGetInterviewTemplateQuery({
		variables: { interviewTemplateId },
		fetchPolicy: 'cache-and-network'
	})
	const [updateInterviewTemplate, { loading: isTemplateUpdating }] = useUpdateInterviewTemplateMutation()

	const handleSubmit = (): void => {
		;(async () => {
			const values = formMethods.getValues()

			const offers = data?.getTrackOfferList?.nodes ?? []
			const positions = data?.getTrackPositionList?.nodes ?? []
			const tracks: { label: string; id: string }[] = [...offers, ...positions]

			const input: InterviewTemplateInput = {
				interviewType: values.interviewType,
				interviewSteps: values.interviewSteps,
				interviewQuestionIds: values.interviewQuestionIds ?? [],
				hasHardSkills: values.hasHardSkills,
				hasSoftSkills: values.hasSoftSkills,
				motivationsLabelsGroups: values.motivationsLabelsGroups ?? [],
				motivationsQuestions: values.motivationsQuestions ?? [],
				trackId: values.trackId,
				trackLabel: tracks.find((track) => track.id === values.trackId)?.label ?? '',
				isDisplayMotivationVideos: values.isDisplayMotivationVideos,
				softskillIds: values.softskillIds ?? [],
				hasObjectives: values.hasObjectives
			}

			const variables: UpdateInterviewTemplateMutationVariables = { interviewTemplateId, input }

			try {
				await updateInterviewTemplate({ variables })
				enqueueSnackbar(t('interviewTemplate:detail.submition.success'), { variant: Severities.success })
			} catch (error) {
				enqueueSnackbar(t('interviewTemplate:detail.submition.error'), { variant: Severities.error })
			}
		})().catch(ErrorHandler)
	}

	return (
		<Container maxWidth="xl">
			<FormProvider {...formMethods}>
				<form>
					<DetailBar
						formIsValid
						handleSubmit={handleSubmit}
						isSaving={isTemplateUpdating}
						title={t('interviewTemplate:detail.bar.title')}
						backTooltip={t('interviewTemplate:detail.bar.title')}
						save={t('interviewTemplate:detail.bar.save')}
						route={ROUTE_INTERVIEW_TEMPLATE_LIST}
					/>
					<LocalLoader withPadding load={isLoading}>
						<Grid container spacing={3} sx={{ mt: 0 }}>
							<Grid item xs={6}>
								<Stack spacing={3}>
									<MultiSelectCard
										items={data?.getInterviewTemplate.selectableInterviewSteps ?? []}
										title={t('interviewTemplate:detail.interviewSteps.title')}
										fieldName="interviewSteps"
									/>
									<MultiSelectCard
										items={data?.getInterviewTemplate.availableMotivationsQuestions ?? []}
										title={t('tracks:details.cards.motivationQuestion')}
										fieldName="motivationsQuestions"
									/>
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Stack spacing={3}>
									<InterviewTemplateGeneralCard />
									<MultiSelectCard
										items={data?.getInterviewTemplate.availableInterviewQuestions ?? []}
										title={t('tracks:details.cards.interviewQuestions')}
										fieldName="interviewQuestionIds"
									/>
									<MultiSelectCard
										items={data?.getInterviewTemplate.availableMotivationsLabelsGroups ?? []}
										title={t('tracks:details.cards.MotivationGroup')}
										fieldName="motivationsLabelsGroups"
									/>
								</Stack>
							</Grid>
						</Grid>
					</LocalLoader>
				</form>
			</FormProvider>
		</Container>
	)
}
