import { makeStyles } from 'tss-react/mui'
import theme from '@ui/theme'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined'
import ExposureIcon from '@mui/icons-material/Exposure'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'

const colors = {
	average: theme.palette.darkBlue.light,
	good: theme.palette.secondary.light,
	bad: theme.palette.warning.light,
	empty: theme.palette.warning.gray2
}

const indicators = {
	identification: ExposureIcon,
	selfEvaluation: StarBorderIcon,
	imageReturn: RecordVoiceOverOutlinedIcon,
	alignment: PeopleAltOutlinedIcon
}

export default function KeyIndicatorCard({ colorType, indicator, title, text, iconText }) {
	const color = { main: 'black' }
	color.main = colors[colorType] ?? 'black'

	const { classes } = useStyle({ color })

	const Icon = indicators[indicator]
	const icon = <Icon className={classes.icon} sx={{ color: color.main }} />

	return (
		<Stack
			sx={{
				width: 200,
				height: 130,
				backgroundColor: color.main + '14',
				border: 'solid',
				borderRadius: 3,
				borderColor: color.main,
				borderWidth: 3
			}}
		>
			<div className={classes.iconContainer}>
				{icon}
				<Typography fontWeight="bold" fontSize={30}>
					{iconText}
				</Typography>
			</div>
			<div className={classes.textContainer}>
				<Typography className={classes.title} sx={{ color: color.main }}>
					{title}
				</Typography>
				<Typography className={classes.text} sx={{ color: color.main }}>
					{text}
				</Typography>
			</div>
		</Stack>
		// </div>
	)
}

const useStyle = makeStyles()(() => ({
	container: {
		width: 200,
		height: 130,
		backgroundColor: ({ color }) => color.main + '14',
		border: 'solid',
		borderRadius: 8,
		borderColor: ({ color }) => color.main,
		borderWidth: 3
	},
	iconContainer: {
		height: '40%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	icon: { fontSize: 45 },
	textContainer: {
		height: '60%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	title: {
		textAlign: 'center'
	},
	text: {
		textAlign: 'center',
		fontWeight: 'bold'
	}
}))
