import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export default function LegendItem({ color, text, colorText, title }) {
	const { classes } = useStyle({ color })

	return (
		<div className={classes.container}>
			<Typography className={classes.title}>{title}</Typography>
			<Typography className={classes.colorText} color={color}>
				{colorText}
			</Typography>
			<Typography className={classes.text}>{text}</Typography>
		</div>
	)
}

const useStyle = makeStyles()(() => ({
	container: {
		display: 'flex',
		flexDirection: 'row'
	},
	colorText: {
		fontStyle: 'italic',
		marginLeft: 5
	},
	text: {
		fontStyle: 'italic',
		marginLeft: 5,
		marginRight: 20
	},
	title: {
		fontStyle: 'italic',
		textDecoration: 'underline'
	}
}))
