import PropTypes from 'prop-types'

import DiscussionInfoCard from './DiscussionInfoCard'
import MessageInfoCard from '../../shared/components/cards/MessageInfoCard'
import ConversationFrame from '@ui/conversation/ConversationFrame'
import { makeStyles } from 'tss-react/mui'
import { LocalLoader } from '../../shared/components/layout/LocalLoader'
import useDiscussionContent from './useDiscussionContent'
import { Grid, Stack } from '@mui/material'

const useStyles = makeStyles()((theme) => ({
	root: {
		padding: '3vw',
		display: 'flex'
	},
	layout: {
		display: 'grid',
		gridTemplateColumns: '1fr 2fr',
		gap: '3vw',

		[theme.breakpoints.down('sm')]: {
			gridTemplateColumns: '1fr'
		}
	},
	leftLayout: {
		'& > *': {
			marginBottom: '3vw'
		}
	},
	rightLayout: {
		'& > *': {
			marginBottom: '0vw'
		}
	},
	avatar: {
		fontSize: 50,
		width: 150,
		height: 150
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	converationFrame: {
		height: '75vh'
	}
}))

export default function DiscussionContent({ discussionUser, setDiscussionUser, userId, displayName }) {
	const { classes } = useStyles()
	const discussionContent = useDiscussionContent(discussionUser, setDiscussionUser, userId, displayName)

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<Stack spacing={2}>
						{discussionUser && (
							<DiscussionInfoCard
								photoURL={discussionUser.photoURL}
								isRead={discussionContent.isRead}
								setIsRead={discussionContent.setIsRead}
								isFlagged={discussionContent.isFlagged}
								setIsFlagged={discussionContent.setIsFlagged}
								isArchived={discussionContent.isArchived}
								setIsArchived={discussionContent.setIsArchived}
								userOfferList={discussionContent.userOfferList}
								// eslint-disable-next-line react/jsx-handler-names
								handleMarkChange={discussionContent.handleMarkChange}
								// eslint-disable-next-line react/jsx-handler-names
								onCandidacySelect={discussionContent.onCandidacySelect}
								displayName={displayName}
								userId={userId}
							/>
						)}
						{!discussionUser && (
							<DiscussionInfoCard disabled displayName={discussionContent.displayName} userId={userId} />
						)}
						{discussionContent.messages.length > 0 && <MessageInfoCard messages={discussionContent.messages} />}
					</Stack>
				</Grid>

				<Grid item xs={8}>
					<ConversationFrame
						className={classes.converationFrame}
						loading={discussionContent.isSendingMessage}
						onNewMessage={discussionContent.handleNewMessage}
					>
						{discussionContent.isLoadingMessages && <LocalLoader />}
						{!discussionContent.isLoadingMessages && discussionContent.decryptedMessages}
					</ConversationFrame>
				</Grid>
			</Grid>
		</>
	)
}

DiscussionContent.propTypes = {
	discussionUserId: PropTypes.string
}
