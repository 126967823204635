import { useState } from 'react'
import { Button, Grid, Stack, TableRow, TextField } from '@mui/material'
import CardLayout from '@ui/core/CardLayout'
import { InfoCardContent } from '@ui/core/InfoCardContent'
import { useTranslation } from 'react-i18next'
import Avatar from '@mui/material/Avatar'
import { TableLayout } from '@ui/core/TableLayout'
import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import BooleanInput from '@ui/core/BooleanInput'
import LinearProgress from '@ui/core/LinearProgress'
import StarRatingInput from '@ui/core/StarRatingInput'
import { calculateCompletion } from '../../../../helper/candidacyStatus.helper'
import { useErrorHandler } from 'react-error-boundary'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import InfoValue from './InfoValue'
import { PresentationLink } from './PresentationLink'
import { makeStyles } from 'tss-react/mui'
import { Link } from '@shared/components/links/Link'
import { useMeQuery } from '@gqlTypes'
import { DateTime } from 'luxon'
import { FeedbackButton } from './FeedbackButton'

const useStyle = makeStyles()((theme) => ({
	avatarCard: {
		height: 300,

		'& .MuiTypography-body1': {
			fontWeight: 'bold'
		},

		'& $avatar': {
			width: 150,
			height: 150
		},

		'& $content': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-start',
			alignItems: 'center',

			'& > *:not($avatar)': {
				marginBottom: 5
			}
		}
	},
	avatar: {
		marginBottom: 34,
		backgroundColor: theme.palette.secondary.main,
		fontSize: 50
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}))

export default function Informations({ infos, informations }) {
	const { t } = useTranslation(['translation', 'avatar'])
	const { classes } = useStyle()
	const errorHandler = useErrorHandler()
	const { data } = useMeQuery()
	const organizationType = data?.me.organization.type
	const { id, creationDate } = data?.me ?? { id: '', creationDate: '' }

	const timestamp = DateTime.fromISO(creationDate).toMillis()

	const publicCode = infos.integrationInfo.publicCode
	const hasPublicCode = publicCode != null && publicCode !== ''
	const location = infos.integrationInfo.location

	useDocumentTitle(
		t('translation:candidates.informations.title', { name: infos.integrationInfo?.userInfo?.displayName })
	)

	const [isEnglish, setIsEnglish] = useState(infos.integrationInfo.english)

	const mailLink = infos.integrationInfo?.userInfo?.email ? `mailto:${infos.integrationInfo?.userInfo?.email}` : null
	const slug = infos.integrationInfo?.userInfo?.slug ?? ''
	const urlPrefix = import.meta.env.VITE_WEB_PUBLIC_URL
	const resumeUrl = `${urlPrefix}resume/${slug}`
	const fieldList = infos.fieldList ?? []

	return (
		<Grid container spacing={3}>
			<Grid item xs={3}>
				<CardLayout
					className={classes.avatarCard}
					title={t('translation:candidates.informations.cardTitle.profileImage')}
				>
					<InfoCardContent className={classes.content}>
						<Avatar className={classes.avatar} sx={{ width: 150, height: 150 }} src={infos.userInfo.photoURL || ''}>
							{infos.integrationInfo?.userInfo?.initials && infos.integrationInfo?.userInfo?.initials}
						</Avatar>
					</InfoCardContent>
				</CardLayout>
			</Grid>
			<Grid item xs={5}>
				<CardLayout
					dataTestId="informations-infoCard"
					title={t('translation:candidates.informations.cardTitle.generalInfos')}
					footer={
						<Stack direction="row" spacing={1}>
							<Link isExternal url={resumeUrl}>
								<Button variant="contained">{t('translation:candidates.informations.resume')}</Button>
							</Link>
							<FeedbackButton senderId={id} recipientSlug={slug} senderCreationDateTimeStamp={timestamp} />
						</Stack>
					}
				>
					<TableLayout>
						<InfoValue
							title={t('translation:candidates.commons.mobile')}
							value={infos.integrationInfo?.userInfo?.mobile}
						/>
						<InfoValue
							title={t('translation:candidates.commons.mail')}
							value={mailLink}
							link={infos.integrationInfo?.userInfo?.email}
						/>
						{hasPublicCode && <InfoValue title={t('translation:candidates.commons.publicCode')} value={publicCode} />}
						<InfoValue title={t('candidates.commons.birthdate')} value={infos.userInfo?.formatedBirthdate} />
						<InfoValue title={t('candidates.informations.publicTitle')} value={infos.userInfo?.publicTitle} />
						<InfoValue title={t(`candidates.informations.${organizationType}.location`)} value={location} />
						<InfoValue title={t('candidates.informations.currentCompany')} value={infos.userInfo?.currentCompany} />
						<InfoValue
							title={t('candidates.informations.currentFunction')}
							value={infos.userInfo?.currentFunctionObject?.name}
						/>
						<InfoValue
							title={t('candidates.informations.currentSector')}
							value={infos.userInfo?.currentSectorObject?.name}
						/>

						{fieldList.map(({ fieldId, labelOrganization, values }) => {
							const userInfo = infos.userInfo
							const fieldMapping = {
								zipCode: userInfo.zipCode,
								applicationMail: userInfo.applicationMail,
								acceptCommunications: userInfo.acceptCommunications
									? t('translation:candidates.commons.validateCommunications')
									: t('translation:candidates.commons.refuseCommunications'),
								educationLevel: values[userInfo?.educationLevel],
								status: values[userInfo?.status],
								link: userInfo.link
							}

							const value = fieldMapping[fieldId]
							const href = fieldId === 'link' ? userInfo.link : null

							return <InfoValue title={labelOrganization} value={value} key={fieldId} link={href} />
						})}
					</TableLayout>
				</CardLayout>
			</Grid>
			<Grid item xs={4}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.candidacyStatus')}>
					<TableLayout>
						<InfoValue
							title={t('translation:candidates.commons.status')}
							value={infos.integrationInfo?.statusObject?.label ?? ''}
						/>
						<InfoValue
							title={t('translation:candidates.commons.profile')}
							value={
								<LinearProgress
									compact
									withLabel
									labelPosition="left"
									value={calculateCompletion(infos.integrationInfo?.steps)}
									variant="determinate"
								/>
							}
						/>
						<InfoValue
							title={t('translation:candidates.commons.selfEvaluation')}
							value={
								<StarRatingInput
									readOnly
									value={infos.integrationInfo?.alignment?.length ? infos.integrationInfo?.alignment?.length - 1 : null}
								/>
							}
						/>
						<InfoValue
							title={t('translation:candidates.commons.interviewEvaluation')}
							value={
								<StarRatingInput
									readOnly
									value={infos.integrationInfo?.interview?.score ? infos.integrationInfo?.interview?.score : null}
								/>
							}
						/>
						<InfoValue
							title={t('translation:candidates.commons.imageReturn')}
							value={infos.imageReturnResponsesCount}
						/>
					</TableLayout>
				</CardLayout>
			</Grid>
			<Grid item xs={6}>
				<CardLayout className={classes.videoCard} title={t('translation:candidates.informations.cardTitle.video')}>
					<InfoCardContent className={classes.content}>
						<PresentationLink link={infos.userInfo?.link} />
					</InfoCardContent>
				</CardLayout>
			</Grid>
			<Grid item xs={6}>
				<CardLayout title={t('translation:candidates.informations.cardTitle.specificInfos')}>
					<InfoCardContent className={classes.content}>
						<TableLayout>
							<TableRow>
								<InfoLayoutLabel>{t('translation:candidates.informations.parcour')}</InfoLayoutLabel>
								<InfoLayoutValue>
									<BooleanInput
										value={isEnglish}
										label={t('translation:candidates.informations.english')}
										onChange={() => {
											informations
												.handleChange('english', !isEnglish)
												.then(() => setIsEnglish(!isEnglish))
												.catch(errorHandler)
										}}
									/>
								</InfoLayoutValue>
							</TableRow>
						</TableLayout>
						<TextField
							label={t('translation:candidates.informations.note')}
							variant="outlined"
							multiline
							rows={4}
							fullWidth
							InputLabelProps={{ shrink: true }}
							placeholder={t('translation:candidates.informations.notePlaceHolder')}
							defaultValue={infos.integrationInfo?.comment}
							onBlur={(event) => informations.handleChange('comment', event.target.value)}
						/>
					</InfoCardContent>
				</CardLayout>
			</Grid>
		</Grid>
	)
}
