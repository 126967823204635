import { InputAdornment, Typography, useTheme } from '@mui/material'
import { type Control, Controller, type FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TextField from '@ui/core/TextField'

interface Props {
	control?: Control
	name: string
	label?: string
	multiline?: boolean
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	defaultValue?: any
	disabled?: boolean
	required?: boolean
	error?: FieldError
	prefix?: string
	gqlFormError?: string
	patternValue?: RegExp
	placeholder?: string
	type?: string
	select?: boolean
}

export default function InputTextField({
	control,
	name,
	label,
	multiline,
	defaultValue,
	disabled = false,
	required = false,
	error,
	prefix = '',
	gqlFormError = '',
	patternValue,
	placeholder = '',
	type = '',
	select = false
}: Props): JSX.Element {
	const { t } = useTranslation()
	const hasError = ['required', 'pattern'].includes(error?.type ?? '') || gqlFormError !== ''
	let errorMessage = ''
	if (gqlFormError !== '') errorMessage = gqlFormError
	if (error?.type === 'required') errorMessage = t('form:errors.required')
	if (error?.type === 'pattern') errorMessage = error.message ?? ''
	const theme = useTheme()
	const color = theme.palette.grays.gray2

	const pattern = patternValue != null ? { value: patternValue, message: t('form:errors.invalid') } : undefined

	return (
		<Controller
			rules={{ required, pattern }}
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ onChange, value }) => {
				return (
					<TextField
						placeholder={placeholder}
						disabled={disabled}
						label={label}
						variant="outlined"
						size="small"
						onChange={onChange}
						value={value}
						multiline={multiline}
						fullWidth
						error={hasError}
						helperText={errorMessage}
						type={type}
						select={select}
						InputProps={{
							startAdornment:
								prefix !== '' ? (
									<InputAdornment position="start">
										<Typography sx={{ color }}>{prefix}</Typography>
									</InputAdornment>
								) : null
						}}
					/>
				)
			}}
		/>
	)
}
