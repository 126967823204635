import { Stack } from '@mui/material'
import { MinimumVersion } from './MinimumVersion'
import { type ReactElement } from 'react'
import { useMinimumVersion } from './useMinimumVersion'

interface Props {
	children: ReactElement
}

export const MinimumVersionProvider = ({ children }: Props): ReactElement => {
	const { hasMinimumVersion } = useMinimumVersion()
	return (
		<Stack sx={{ flex: 1 }}>
			{hasMinimumVersion ? null : <MinimumVersion />}
			{children}
		</Stack>
	)
}
