import Slider from '@ui/core/Slider'
import { Box, Stack, Typography } from '@mui/material'
import moment from 'moment'
import { type ReactElement } from 'react'
import { UserAvatar } from '@shared/components/UserAvatar'
import { type UserRef } from '@gqlTypes'

interface Props {
	score: number
	senderRef: UserRef
	relationType?: string
	date: string
	comment?: string
}

export const SharedReturn = ({ score, senderRef, relationType = '', date, comment = '' }: Props): ReactElement => {
	return (
		<Stack direction="row" spacing={2}>
			<UserAvatar user={senderRef} size={40} />
			<Box sx={{ flex: 1 }}>
				<Stack direction="row" spacing={1} divider={<Typography>•</Typography>}>
					<Typography sx={{ fontWeight: 'bold' }}>{senderRef.displayName} </Typography>
					{relationType !== '' ? <Typography>{relationType}</Typography> : null}
					<Typography>{moment(date).format('DD/MM/YYYY')}</Typography>
				</Stack>
				<Stack direction="row" spacing={2}>
					<Slider sx={{ flex: 1, display: 'flex' }} readOnly value={score} color="primary" min={0} max={5} />
					<Typography>{score} / 5</Typography>
				</Stack>
				{comment !== '' ? <Typography sx={{ fontStyle: 'italic' }}>&quot;{comment}&quot;</Typography> : null}
			</Box>
		</Stack>
	)
}
