import { generateRoute, ROUTE_HOME } from '../../../router/routes'
import { history } from '../../store/MyStoreProvider'

export default function ErrorBoundaryFallback(props) {
	const { error, resetErrorBoundary } = props
	return (
		<div>
			<div>{error.toString()}</div>
			<div
				onClick={() => {
					history.push(generateRoute(ROUTE_HOME))
					resetErrorBoundary()
				}}
			>
				close
			</div>
		</div>
	)
}

ErrorBoundaryFallback.propTypes = {}

ErrorBoundaryFallback.defaultProps = {}
