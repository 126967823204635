import { useSelector } from 'react-redux'
import InputSelectTextField from '@domains/interviews/create/InputSelectTextField'
import { type ReactElement } from 'react'
import { Grid } from '@mui/material'
import { type FieldErrors } from 'react-hook-form'
import ConditionnalWrapper from '@shared/components/ConditionnalWrapper'

interface Props {
	hasGrid?: boolean
	errors: FieldErrors
}

export const InterviewCustomFields = ({ hasGrid = false, errors }: Props): ReactElement => {
	const interviewFields = useSelector(
		({ interviewFields }: { interviewFields: Record<string, { id: string; label: string; values: [] }> }) =>
			interviewFields
	)

	return (
		<>
			{Object.entries(interviewFields).map(([key, data]) => {
				const currentField = interviewFields[data.id]
				return (
					<ConditionnalWrapper
						key={key}
						condition={hasGrid}
						wrapper={(children: ReactElement) => (
							<Grid item xs={12} sm={6} key={key}>
								{children}
							</Grid>
						)}
					>
						<InputSelectTextField
							errors={errors}
							key={key}
							label={currentField.label}
							items={currentField.values}
							isSelfKey
							name={`customFields.${data.id}`}
							labelAll=""
							rules={{}}
							required={undefined}
						/>
					</ConditionnalWrapper>
				)
			})}
		</>
	)
}
