import { CheckboxFilter } from '@features/lists/components/Filters/CheckboxFilter'
import InputFilter from '@ui/filter/InputFilter'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	keyName: string
	defaultValue?: boolean
}

export const InterviewLateFilter = ({ keyName, defaultValue = false }: Props): ReactElement => {
	const { t } = useTranslation()

	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<InputFilter keyName={keyName} isBooleanCheckbox defaultValue={defaultValue}>
				<CheckboxFilter />
			</InputFilter>
			<div>{t('interview:list.lateInterviewFilter')}</div>
		</div>
	)
}
