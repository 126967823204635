import { useState } from 'react'
import ListBar from './ListBar'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import useTracksColumns from './useTracksColumns'
import { useTranslation } from 'react-i18next'
import DataGridGQL from '../../../features/lists/components/DataGridGQL'
import { useLocation } from 'react-router-dom'
import {
	type GetTrackOfferListNodesFragment,
	type GetTrackPositionListNodesFragment,
	useGetTrackOfferListQuery,
	useGetTrackPositionListQuery
} from '@gqlTypes'
import PeopleIcon from '@mui/icons-material/People'

export default function TrackList(): JSX.Element {
	const { t } = useTranslation()
	useDocumentTitle(t('tracks:list.document.title'))
	const location = useLocation().pathname
	const isTrackOffer = location.includes('organization-offer')
	const columns = useTracksColumns(isTrackOffer)
	const dataTestId = isTrackOffer ? 'organization-offer-datatable' : 'organization-position-datatable'

	const variables = { cursor: '', limit: 200 }
	const { loading, data, fetchMore, refetch } = isTrackOffer
		? useGetTrackOfferListQuery({ variables })
		: useGetTrackPositionListQuery({ variables })

	let nodes: GetTrackPositionListNodesFragment[] | GetTrackOfferListNodesFragment[] = []
	let cursor = ''
	let hasNextPage = false
	let count = 0
	if (data != null) {
		const list = data.getTrackList
		nodes = list.nodes
		cursor = list.cursor ?? ''
		hasNextPage = list.hasNextPage ?? false
		count = list.count
	}

	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)

	return (
		<DataGridGQL
			emptyStateLabel={t('tracks:list.grid.empty')}
			columns={columns}
			setIsAllRowsSelected={setIsAllRowsSelected}
			isAllRowsSelected={isAllRowsSelected}
			dataList={nodes}
			isLoading={loading}
			fetchMore={fetchMore}
			cursor={cursor}
			hasNextPage={hasNextPage}
			dataTestId={dataTestId}
			title={t('tracks:list.bar.title', { count })}
			Icon={PeopleIcon}
			toolbar={<ListBar refetch={refetch} />}
		/>
	)
}
