import MenuItem from '@mui/material/MenuItem'
import SelectorUpdater from '../SelectorUpdater'
import { useTranslation } from 'react-i18next'
import { useGetManagersQuery } from '@gqlTypes'

export default function ManagerUpdateSection({ onValidate }) {
	const { t } = useTranslation(['lists'])
	const { data } = useGetManagersQuery()
	const managers = data?.getManagers ?? []

	return (
		<SelectorUpdater onValidate={onValidate} items={managers} field="manager" title={t('modal:massUpdate.manager')}>
			{managers.map((manager) => (
				<MenuItem key={manager.id} value={manager.id}>
					{manager.reverseFullName}
				</MenuItem>
			))}
		</SelectorUpdater>
	)
}
