import { useCallback, useState } from 'react'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MassFeedbackModal from '../components/actions/MassFeedbackModal'
import { getRowsData } from '../../../helper/massActions.helper'
import { IconAction } from '@shared/icon/IconAction'

export default function MassFeedback({ isAllRowsSelected, dataList, trackType, count, integrationCategory }) {
	const { t } = useTranslation()
	const selectedRows = useSelector(({ selectedRows }) => selectedRows)
	const [isOpen, setIsOpen] = useState(false)

	const getIntegrationIds = useCallback(() => {
		return getRowsData(selectedRows, dataList).map((row) => row.id)
	}, [selectedRows, dataList])

	const getDisplayName = useCallback(() => {
		if (selectedRows.length === 1) {
			const row = getRowsData(selectedRows, dataList)[0]
			return `${row.userInfo.firstName} ${row.userInfo.lastName}`
		}
	}, [selectedRows, dataList])

	return (
		<>
			<IconAction
				tooltip={t('modal:massFeedback.tooltip')}
				disabled={selectedRows.length < 1}
				onClick={() => setIsOpen(true)}
			>
				<LiveHelpOutlinedIcon />
			</IconAction>
			<MassFeedbackModal
				open={isOpen}
				setOpen={setIsOpen}
				displayName={getDisplayName()}
				userIntegrationsIds={isAllRowsSelected ? [] : getIntegrationIds()}
				trackType={trackType}
				count={isAllRowsSelected ? count : getIntegrationIds().length}
				integrationCategory={integrationCategory}
			/>
		</>
	)
}
