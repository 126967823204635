import { useTranslation } from 'react-i18next'
import { useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import InputFilter from '@ui/filter/InputFilter'
import AutoCompleteFilter from '../AutoCompleteFilter'

export const CustomFieldsFilters: React.FC = () => {
	const { t } = useTranslation()
	const { data, loading } = useMeQuery()
	if (loading || data?.me == null) return <></>

	const interviewFields = data.me.organization.interviewFields

	return (
		<>
			{interviewFields.map((field) => (
				<div key={field?.id}>
					<InputFilter keyName={`customFields.${field?.id ?? ''}`} operation={filterOperation.EQUAL} isAutoComplete>
						<AutoCompleteFilter
							options={field?.values.map((value) => ({ value, label: value }))}
							label={field?.label}
							allValueLabel={t('lists:filter.assessor.allLabel')}
							width="100%"
						/>
					</InputFilter>
				</div>
			))}
		</>
	)
}
