import { CircularProgress, Autocomplete, type SxProps, type TextFieldProps } from '@mui/material'
import { Controller, type FieldValues } from 'react-hook-form'
import TextField from '@ui/core/TextField'
import React, { type ReactElement } from 'react'

interface Item {
	value: string | number
	label: string
}

export interface AutocompleteSelectProps {
	name: string
	items: Item[]
	label?: string
	loading?: boolean
	sx?: SxProps
	rules?: FieldValues
	noneValueLabel?: string
	isRestricted?: boolean
	inputProps?: TextFieldProps
	orderItems?: boolean
	fullWidth?: boolean
	disableClearable?: boolean
	disabled?: boolean
}

export const AutocompleteSelect = ({
	name,
	items,
	label = '',
	loading = false,
	sx = {},
	rules = {},
	noneValueLabel = '',
	isRestricted = false,
	inputProps,
	orderItems = true,
	fullWidth = false,
	disableClearable = false,
	disabled = false
}: AutocompleteSelectProps): ReactElement => {
	const orderedItems = React.useMemo(() => {
		const orderedItems = orderItems ? [...items].sort((a, b) => a.label.localeCompare(b.label)) : [...items]
		if (noneValueLabel !== '') orderedItems.push({ label: noneValueLabel, value: '' })
		return orderedItems
	}, [items])
	const isDisabled = (items.length < 2 && isRestricted) || disabled

	return (
		<Controller
			rules={rules}
			name={name}
			render={({ onChange, value }) => {
				const selectedItem = React.useMemo(
					() => orderedItems.find((item) => item.value === value) ?? { value: '', label: '' },
					[orderedItems, value]
				)
				return (
					<Autocomplete
						sx={sx}
						options={orderedItems}
						onChange={(_: unknown, newValue: Item | null) => {
							onChange(newValue?.value ?? '')
						}}
						value={selectedItem}
						loading={loading}
						disabled={isDisabled}
						fullWidth={fullWidth}
						disableClearable={disableClearable}
						renderInput={(params) => (
							<TextField
								{...params}
								{...inputProps}
								variant="outlined"
								label={label}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<>
											{loading ? <CircularProgress color="inherit" size={20} /> : null}
											{params.InputProps.endAdornment}
										</>
									)
								}}
							/>
						)}
					/>
				)
			}}
		/>
	)
}
