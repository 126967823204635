import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

export function CandidateInfoCell(props) {
	const { firstname, lastname, phoneNumber } = props

	return (
		<div>
			<Typography>
				<strong>{lastname}</strong> {firstname}
			</Typography>
			<Typography variant="body2">{phoneNumber}</Typography>
		</div>
	)
}

CandidateInfoCell.propTypes = {
	firstname: PropTypes.string,
	lastname: PropTypes.string,
	phoneNumber: PropTypes.string
}

export default CandidateInfoCell
