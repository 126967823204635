import { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import FeedbackMessageSection from '../sections/FeedbackMessageSection'
import OrgaSkillSection from '../sections/OrgaSkillSection'
import Button from '@mui/material/Button'
import { makeStyles } from 'tss-react/mui'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { FeedbackRequestMode, useSendBrandFeedbackRequestMutation } from '@gqlTypes'
import { toState } from '../../../../helper/array.helper'
import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useFilters } from '@shared/hooks/useFilters'
import { searchQuery } from '@features/lists/components/Search'
import { useOrganization } from '@domains/organization/useOrganization'

export default function MassFeedbackModal({
	open,
	setOpen,
	onRequest,
	trackType,
	userIntegrationsIds,
	count,
	integrationCategory
}) {
	const { t } = useTranslation()
	const { classes } = useStyle()
	const { organization, hasOrgaSkills } = useOrganization()
	const [message, setMessage] = useState(
		organization?.defaultOrgaRequest !== '' ? organization?.defaultOrgaRequest : t('modal:massFeedback.message')
	)
	const [sendFeedbackRequestMutation, { loading: isLoading }] = useSendBrandFeedbackRequestMutation()
	const { filters } = useFilters()
	const [mode, setMode] = useState(FeedbackRequestMode.Auto)
	const [skills, setSkills] = useState(toState(organization?.orgaSkills))
	const selectedSkills = Object.keys(skills).filter((key) => skills[key] === true)
	const themes = selectedSkills.length

	const sendFeedbackRequest = async () => {
		const variables = {
			userIntegrationsIds,
			trackType,
			filters,
			skillToEvaluateIds: selectedSkills,
			message,
			mode,
			integrationCategory,
			searchQuery: searchQuery()
		}
		const result = await sendFeedbackRequestMutation({ variables })
		if (onRequest) onRequest(result.data.sendBrandFeedbackRequest.id)
		setOpen(false)
	}

	const handleMode = (_e, mode) => {
		if (mode !== null) setMode(mode)
	}

	const isSubmitDisable = (mode === FeedbackRequestMode.Custom && (message === '' || !message)) || !hasOrgaSkills

	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle>
				<Stack direction="row" justifyContent="space-between">
					{t('modal:massFeedback.title', { count })}
					<ToggleButtonGroup
						color="primary"
						value={mode}
						exclusive
						onChange={handleMode}
						aria-label="Mode"
						sx={{ alignSelf: 'center' }}
						size="small"
					>
						<ToggleButton value={FeedbackRequestMode.Auto}>{t('modal:massFeedback.mode.auto.button')}</ToggleButton>
						<ToggleButton value={FeedbackRequestMode.Custom}>{t('modal:massFeedback.mode.custom.button')}</ToggleButton>
					</ToggleButtonGroup>
				</Stack>
			</DialogTitle>
			<Divider />
			<DialogSection>
				<Stack gap={3}>
					{mode === FeedbackRequestMode.Auto ? (
						<Typography>{t('modal:massFeedback.mode.auto.text')}</Typography>
					) : (
						<>
							<FeedbackMessageSection message={message} setMessage={setMessage} />
							<div>
								<Typography gutterBottom>
									<b>{t('modal:massFeedback.title_theme', { count: themes })}</b>
								</Typography>
								<OrgaSkillSection skills={skills} setSkills={setSkills} />
							</div>
						</>
					)}
					<Button
						loading={isLoading}
						className={classes.button}
						onClick={() => sendFeedbackRequest()}
						disabled={isSubmitDisable}
						sx={{ alignSelf: 'center' }}
						variant="contained"
					>
						{t('modal:massFeedback.send')}
					</Button>
				</Stack>
			</DialogSection>
		</Dialog>
	)
}

const useStyle = makeStyles()(() => ({
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 12
	}
}))
