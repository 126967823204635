import { type ReactElement } from 'react'
import { type PositionStatus, type UserPositionListNodeFragment, useGetManagersQuery, useMeQuery } from '@gqlTypes'
import { Button, Dialog, DialogContent, DialogTitle, Grid, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EditField } from './EditField'
import { usePositionUpdate } from './usePositionUpdate'
import { IntegrationAvatar } from '@shared/components/IntegrationAvatar'
import { DialogCloseButton } from '@ui/dialog/DialogCloseButton'

interface Props {
	position: UserPositionListNodeFragment
	handleClose: () => void
	isOpen: boolean
}

export const StudentCardEditDialog = ({ position, handleClose, isOpen }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { update } = usePositionUpdate(position)

	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })
	const { data: dataManagers } = useGetManagersQuery()
	const managers = dataManagers?.getManagers ?? []

	if (loading || data?.me == null) return null
	const organization = data.me.organization
	const availablePromotions = organization.promotions.map((promotion: string) => ({
		value: promotion,
		label: promotion
	}))
	const availableLocations = organization.locations.map((loc: string) => ({ value: loc, label: loc }))
	const availableBusinessUnits = organization?.businessUnits.map((bu: string) => ({ value: bu, label: bu }))
	const availableManagers = managers.map(({ id, reverseFullName }) => ({ value: id, label: reverseFullName }))
	const availableStatus = organization.positionStatus.map((status: PositionStatus) => ({
		value: status.id,
		label: status.label
	}))

	const isBusinessUnitRestricted = (data.me.businessUnit ?? '') !== ''
	const islocationRestricted = (data.me.location ?? '') !== ''

	const userRef = position.userInfo
	const externalAssessorEmail = managers.find(({ id }) => position.externalAssessorId === id)?.email ?? ''
	if (userRef == null || !isOpen) return null

	return (
		<Dialog onClose={handleClose} open={isOpen}>
			<DialogCloseButton onClick={handleClose} />
			<DialogTitle color="primary" sx={{ mb: 3, fontWeight: 'bold' }}>
				{t('students:update.title')}
			</DialogTitle>
			<DialogContent>
				<IntegrationAvatar
					sx={{ mb: 5 }}
					userRef={userRef}
					title={position.orgaPosition?.label ?? ''}
					subtitle={position?.userInfo?.currentCompany ?? ''}
				/>
				<Grid container spacing={4}>
					<Grid item xs={12} sm={6}>
						<EditField
							value={position.businessUnit ?? ''}
							onSelect={(businessUnit) => {
								update({ businessUnit })
							}}
							noneValueLabel={t('students:cell.businessUnit.none')}
							options={availableBusinessUnits}
							label={t('students:columns.businessUnit')}
							isRestricted={isBusinessUnitRestricted}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<EditField
							value={position.promotion}
							onSelect={(promotion) => {
								update({ promotion })
							}}
							noneValueLabel={t('students:cell.promotion.none')}
							options={availablePromotions}
							label={t('students:columns.promotion')}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<EditField
							value={position.location ?? ''}
							onSelect={(location) => {
								update({ location })
							}}
							noneValueLabel={t('students:cell.location.none')}
							options={availableLocations}
							label={t('students:columns.campus')}
							isRestricted={islocationRestricted}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<EditField
							value={position.manager ?? ''}
							onSelect={(manager) => {
								update({ manager })
							}}
							noneValueLabel={t('students:cell.referent.none')}
							options={availableManagers}
							label={t('students:columns.manager')}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<EditField
							value={position.externalAssessorId ?? ''}
							onSelect={(externalAssessorId) => {
								update({ externalAssessorId, isExternalAssessorValidated: true })
							}}
							noneValueLabel={t('students:cell.tutor.none')}
							options={availableManagers}
							label={t('students:columns.tutor')}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Stack>
							<Typography display="block">{externalAssessorEmail}</Typography>
							{position.isExternalAssessorValidated ? null : (
								<Button
									variant="contained"
									size="small"
									onClick={() => {
										update({ isExternalAssessorValidated: true })
									}}
								>
									{t('students:actions.validate')}
								</Button>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} sm={6}>
						<EditField
							value={position.status ?? ''}
							onSelect={(status) => {
								update({ status })
							}}
							noneValueLabel=""
							options={availableStatus}
							label={t('students:columns.status')}
						/>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}
