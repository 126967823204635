import { type ReactElement } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type IntegrationType, useGetUserIntegrationSoftSkillRestitutionQuery } from '@gqlTypes'
import { useSnackbar } from 'notistack'
import { Severities } from '../../../constants/Severities'
import { SoftSkillRestitutionSection } from './SoftSkillRestitutionSection'

interface Props {
	userIntegrationId: string
	userIntegrationType: IntegrationType
}

export const ImageReturnTab = ({ userIntegrationId, userIntegrationType }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { enqueueSnackbar } = useSnackbar()
	const {
		loading: isLoading,
		data,
		error
	} = useGetUserIntegrationSoftSkillRestitutionQuery({ variables: { userIntegrationId, userIntegrationType } })

	if (isLoading) return <LocalLoader />
	if (error !== undefined || data?.getUserIntegrationSoftSkillRestitution === undefined) {
		enqueueSnackbar(t('sharedReturnTab:error'), { variant: Severities.error })
		return null
	}

	const softSkillRestitutions = data?.getUserIntegrationSoftSkillRestitution ?? []

	if (softSkillRestitutions.length === 0) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: 10,
					flexDirection: 'column',
					gap: 5
				}}
			>
				<img src="/assets/img/noFeedback.png" alt="No soft skills available" />
				<Typography color="primary" style={{ fontWeight: 'bold', fontSize: 18 }}>
					{t('sharedReturnTab:empty-image')}
				</Typography>
			</Box>
		)
	}

	return (
		<Stack spacing={1} sx={{ flexWrap: 'wrap' }}>
			{softSkillRestitutions.map((softSkillRestitution, index) => (
				<SoftSkillRestitutionSection
					key={softSkillRestitution.softSkill.id}
					softSkillRestitution={softSkillRestitution}
					isDefaultOpen={index === 0}
				/>
			))}
		</Stack>
	)
}
