import { Route, Switch } from 'react-router-dom'
import NotFound from '../Pages/NotFound'
import { routes } from './routes'
import { rolesHierarchy } from './roles'
import { firebaseAuth } from '../firebase/firebase.helper'

function RouterHandler() {
	return (
		<Switch>
			{routes.map((route) => {
				return (
					<Route path={route.path} exact={!route.notExact} key={route.path}>
						<SecureChildrenRender route={route}>{route.children}</SecureChildrenRender>
					</Route>
				)
			})}
			<Route path="*">
				<NotFound />
			</Route>
		</Switch>
	)
}

function SecureChildrenRender(props) {
	const { children, route } = props
	const currentUserRole = '' // TODO wire user role
	if (route.public) {
		return children
	} else if (!firebaseAuth().currentUser) {
		return children // (<Redirect to={ROUTE_LOGIN}/>);
	} else if (!hasRole(route.role, currentUserRole)) {
		return <div>not here</div> // (<Redirect to={ROUTE_HOME}/>);
	}
	return children
}

function hasRole(routeRole, userRole) {
	if (!routeRole) {
		// no role on the route display it
		return true
	}
	if (!userRole) {
		// route has a role but not the user, no need to go further
		return false
	}
	let allUserRoles = []
	allUserRoles.push(userRole)
	if (rolesHierarchy[userRole].length > 0) {
		allUserRoles = allUserRoles.concat(rolesHierarchy[userRole])
	}
	return allUserRoles.includes(routeRole)
}

export default RouterHandler
