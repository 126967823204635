export default function MarkerArrow({ color, isFilled }) {
	const fillColor = color ?? 'rgb(0,31,48)'
	const fillCircle = isFilled ? fillColor : 'white'

	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 20 31"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			fillRule="evenodd"
			clipRule="evenodd"
		>
			<g transform="matrix(1,0,0,1,1,1)">
				<g id="Plan-de-travail1" transform="matrix(1.16622,0,0,1.60131,-0.120525,0.731223)">
					<rect x="0.103" y="-0.457" width="15.56" height="18.314" fill="none" />
					<clipPath id="_clip1">
						<rect x="0.103" y="-0.457" width="15.56" height="18.314" />
					</clipPath>
					<g clipPath="url(#_clip1)">
						<g transform="matrix(0.857474,0,0,0.624487,0.157437,6.84183)">
							<path
								d="M9,16.5L1,0.5L2.363,3.225L6,6L9,6.5L13,5.5L15.847,2.806L17,0.5L9,16.5Z"
								fill={fillColor}
								fillRule="nonzero"
								stroke={fillColor}
								strokeWidth={1}
							/>
						</g>
						<g transform="matrix(0.971804,0,0,0.707752,-8.16006,-2.20179)">
							<circle
								cx="16.5"
								cy="10.5"
								r="7.5"
								fill={fillCircle}
								stroke={fillColor}
								strokeWidth={0.97}
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeMiterlimit={1.5}
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
