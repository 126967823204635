import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { DialogSection } from '@shared/components/modal/dialogSection'
import { type ReactElement } from 'react'
import { type InterviewFormInput } from '@features/details/types/interviewsForm.type'
import { type UseFormMethods } from 'react-hook-form'
import Button from '@mui/material/Button'

interface Props {
	open: boolean
	setOpen: (isOpen: boolean) => void
	onValidate?: (InterviewFormInput: InterviewFormInput) => void
	values: UseFormMethods<InterviewFormInput>['getValues']['prototype']
}

const InterviewFeedback = ({ open, setOpen, onValidate, values }: Props): ReactElement => {
	const { t } = useTranslation()
	const handleValidation = (isSendingFeedback: boolean): void => {
		if (onValidate === undefined) return

		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		onValidate({ ...values(), isSendingFeedback })
		setOpen(false)
	}

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false)
			}}
		>
			<DialogTitle>{t('modal:interviewFeedback.title')}</DialogTitle>
			<Divider />
			<DialogSection>
				<Typography>{t('modal:interviewFeedback.authorization')}</Typography>
			</DialogSection>
			<DialogSection>
				<Button
					variant="contained"
					onClick={() => {
						handleValidation(true)
					}}
				>
					{t('modal:interviewFeedback.validate')}
				</Button>
				<Button
					variant="contained"
					onClick={() => {
						handleValidation(false)
					}}
				>
					{t('modal:interviewFeedback.skip')}
				</Button>
			</DialogSection>
		</Dialog>
	)
}

export default InterviewFeedback
