import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const filterOperation = {
	EQUAL: '==',
	GREATER_THAN_EQUAL: '>=',
	GREATER_THAN: '>',
	LOWER_THAN: '<',
	LOWER_THAN_EQUAL: '<=',
	SEARCH_STRING: 'search',
	IN_ARRAY: 'in',
	NOT_IN_ARRAY: 'not in',
	DIFFERENT: '!=',
	CONTAIN: 'array-contains',
	CONTAIN_ANY: 'array-contains-any'
}
export const FilterContext = React.createContext({
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setFilter(filters) {},
	getFilter() {},
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	makeFilter(key, operation, value) {}
})

export default function FilterForm({ value, onChange, defaultValue, onSubmit, children }) {
	let [filters, setFilters] = useState(defaultValue || [])
	const [isControlled] = useState(value !== undefined)

	if (isControlled) filters = value

	function onFilterChanged(newValue) {
		if (onChange) onChange(newValue)
		if (!isControlled) setFilters(newValue)
	}

	function handleSubmit(e) {
		e.preventDefault()
		if (onSubmit) onSubmit(filters)
	}

	function getFilter() {
		return filters
	}

	function setFilter(newFilters) {
		onFilterChanged(newFilters)
	}

	function makeFilter(key, operation, value, isExclusive) {
		return { key, operation, value, isExclusive }
	}

	return (
		<FilterContext.Provider value={{ setFilter, getFilter, makeFilter }}>
			<form onSubmit={(e) => handleSubmit(e)}>{children}</form>
		</FilterContext.Provider>
	)
}

FilterForm.propTypes = {
	children: PropTypes.node,
	onSubmit: PropTypes.func
}

FilterForm.defaultProps = {}
