import { useCallback } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import BooleanInput from '@ui/core/BooleanInput'
import { generateRoute, ROUTE_ORGANIZATION_USER_DETAIL } from '../../../router/routes'
import NavButtonIcon from '@shared/components/buttons/NavButtonIcon'
import EditIcon from '@mui/icons-material/Edit'
import moment from 'moment'

export default function useOrganizationUsersColumns() {
	const { t } = useTranslation(['organizationUsers'])

	return useCallback(() => {
		return [
			{
				label: '',
				width: 50,
				renderCell: (user) => (
					<NavButtonIcon
						route={generateRoute(ROUTE_ORGANIZATION_USER_DETAIL, { userId: user.id })}
						icon={<EditIcon />}
						tooltipTitle={t('organizationUsers:list.columns.link')}
					/>
				)
			},
			{
				label: t('organizationUsers:list.columns.lastname'),
				renderCell: (user) => {
					const style = user.active ? null : { color: '#CCC' }
					return <Typography style={style}>{user?.lastname}</Typography>
				}
			},
			{
				label: t('organizationUsers:list.columns.firstname'),
				renderCell: (user) => {
					const style = user.active ? null : { color: '#CCC' }
					return <Typography style={style}>{user?.firstname}</Typography>
				}
			},
			{
				label: t('organizationUsers:list.columns.email'),
				renderCell: (user) => {
					const style = user.active ? null : { color: '#CCC' }
					return <Typography style={style}>{user?.email}</Typography>
				}
			},
			{
				label: t('organizationUsers:list.columns.creationDate'),
				renderCell: (user) => {
					const style = user.active ? null : { color: '#CCC' }
					return <Typography style={style}>{moment(user?.creationDate).format('DD/MM/YYYY')}</Typography>
				}
			},
			{
				label: t('organizationUsers:list.columns.language'),
				renderCell: (user) => {
					const style = user.active ? null : { color: '#CCC' }
					return <Typography style={style}>{user?.language}</Typography>
				}
			},
			{
				label: t('organizationUsers:list.columns.role'),
				renderCell: (user) => {
					const style = user.active ? null : { color: '#CCC' }
					return <Typography style={style}>{user?.role.name}</Typography>
				}
			},
			{
				label: t('organizationUsers:list.columns.isAssignable'),
				renderCell: (user) => <BooleanInput value={user.isAssignable} onChange={() => {}} noLabel />
			},
			{
				label: t('organizationUsers:list.columns.isManager'),
				renderCell: (user) => <BooleanInput value={user.isManager} onChange={() => {}} noLabel />
			},
			{
				label: t('organizationUsers:list.columns.isRestrictedAccess'),
				renderCell: (user) => <BooleanInput value={user.isRestrictedAccess} noLabel />
			},
			{
				label: t('organizationUsers:list.columns.hasMobileAccount'),
				renderCell: (user) => <BooleanInput value={user.hasMobileAccount} onChange={() => {}} noLabel />
			},
			{
				label: t('organizationUsers:list.columns.visioLink'),
				renderCell: (user) => {
					const style = user.active ? null : { color: '#CCC' }
					return <Typography style={style}>{user?.visioLink}</Typography>
				}
			}
		]
	}, [t])
}
