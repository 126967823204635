import { firestore } from '../firebase/firebase.helper'
import Collections from '../dao/Collections'
import { readQuerySnapshot } from '../firebase/firestore.helper'

export default class UserService {
	async getIndicators(userId) {
		const indicatorSnapshot = await firestore()
			.collection(Collections.Users.name)
			.doc(userId)
			.collection(Collections.Users.Indicators.name)
			.get()

		const final = {}
		readQuerySnapshot(indicatorSnapshot).forEach((indicator) => {
			final[indicator.id] = indicator.value
		})
		return final
	}
}
