import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import StarRatingInput from '@ui/core/StarRatingInput'
import Typography from '@mui/material/Typography'
import { TableRow } from '@mui/material'
import { type ReactElement } from 'react'

interface Props {
	title: string
	value: string
	isAvailable: boolean
	unAvailableLabel: string
}

export const StarLabel = ({ title, value, isAvailable, unAvailableLabel }: Props): ReactElement => {
	return (
		<TableRow>
			<InfoLayoutLabel light>{title}</InfoLayoutLabel>
			<InfoLayoutValue>
				{isAvailable && <StarRatingInput readOnly value={value} />}
				{!isAvailable && <Typography>{unAvailableLabel}</Typography>}
			</InfoLayoutValue>
		</TableRow>
	)
}
