import { type MotivationQuestionFragment } from '@gqlTypes'
import Typography from '@mui/material/Typography'
import { type ReactElement } from 'react'
import { Box, Grid } from '@mui/material'
import InputTextField from '@shared/components/forms/fields/inputTextField'

interface Props extends MotivationQuestionFragment {
	isEditable: boolean
}

export const MotivationQuestion = ({ id, question, answer, isEditable }: Props): ReactElement => {
	return (
		<Grid container>
			<Grid item xs={4}>
				<Typography gutterBottom whiteSpace="pre-line" style={{ fontWeight: 700 }}>
					{question}
				</Typography>
			</Grid>

			<Grid item xs={8}>
				<Box ml={5}>
					{isEditable ? (
						<InputTextField name={`answers[${id}]`} multiline />
					) : (
						<Typography
							whiteSpace="pre-line"
							color="primary"
							sx={{ border: '1px solid lightgray', borderRadius: 1, padding: 1, minHeight: 20 }}
						>
							{answer}
						</Typography>
					)}
				</Box>
			</Grid>
		</Grid>
	)
}
