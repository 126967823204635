import { useGetUserOfferQuery, useGetUserPositionQuery } from '@gqlTypes'
import { INTEGRATION_TYPE } from '../../../constants/INTEGRATION_TYPE'

export default function useDetails(id, subMenuTabs, type) {
	const isCandidacy = type === INTEGRATION_TYPE.CANDIDACY
	const useGetUserIntegration = isCandidacy ? useGetUserOfferQuery : useGetUserPositionQuery
	const variables = { id, userIntegrationType: isCandidacy ? 'offer' : 'position' }
	const { loading, data } = useGetUserIntegration({ variables })
	const userIntegrationData = data?.getUserIntegration
	const userInfo = userIntegrationData?.userInfo
	const integrationInfo = userIntegrationData
	const personalityTest = userIntegrationData?.userInfo?.personalityTest[0]
	const dimensions = personalityTest?.dimensions ?? []
	const latestInterview = userIntegrationData?.latestInterview
	const avatar = personalityTest?.avatar
	const fieldList = userIntegrationData?.fieldList ?? []

	// TODO Move to interview sections for optimization
	const isInterviewSendingFeedback = userIntegrationData?.trackIntegration?.isInterviewSendingFeedback
	const isInterviewAverageMandatoryOnly = userIntegrationData?.trackIntegration?.isInterviewAverageMandatoryOnly
	// const isFeedbackAverageVisible = getUserOffer?.isFeedbackAverageVisible

	const imageReturnResponsesCount = userInfo?.imageReturnCount

	return {
		avatar,
		subMenuTabs,
		dimensions,
		highestDimensions: personalityTest?.highestDimensions,
		isLoading: loading,
		imageReturnResponsesCount,
		profile: personalityTest?.profile,
		integrationInfo,
		userInfo,
		isInterviewSendingFeedback,
		isInterviewAverageMandatoryOnly,
		latestInterview,
		fieldList
	}
}
