import { useState } from 'react'
import BooleanUpdater from '../BooleanUpdater'
import { useTranslation } from 'react-i18next'

export default function ArchivedUpdateSection({ onValidate }) {
	const { t } = useTranslation(['lists'])
	const [isArchived, setIsArchived] = useState('')

	return (
		<BooleanUpdater
			onValidate={onValidate}
			value={isArchived}
			setvalue={setIsArchived}
			title={t('modal:massUpdate.archived')}
			field="isArchived"
		/>
	)
}
