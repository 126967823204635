import { type ReactElement } from 'react'
import Typography from '@mui/material/Typography'
import MDIIcon from '../../../Icon/MDIIcon'
import { Box } from '@mui/material'

interface Props {
	icon: string
	label: string
	color: string
	noSpace?: boolean
}

export const GraphRadarLegendItem = ({ icon, label, color, noSpace = false }: Props): ReactElement => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginRight: noSpace ? 0 : 3,
				marginLeft: noSpace ? 0 : 8,
				marginBottom: noSpace ? 0 : 2,
				breakInside: 'avoid'
			}}
		>
			<Box
				sx={{
					width: 20,
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<MDIIcon size="1.5rem" color={color} name={icon} />
			</Box>
			<Typography sx={{ lineHeight: 1, marginLeft: '20px' }}>{label}</Typography>
		</Box>
	)
}
