import { type ReactElement, type ReactNode } from 'react'
import IconButton from '@mui/material/IconButton'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { useFormContext } from 'react-hook-form'

interface Props {
	children: ReactNode
	min: number
	max: number
	isButtonsHidden: boolean
	isDarkMode: boolean
	name: string
	step: number
	onchangeValue?: () => void
}

export const SliderButtons = ({
	isDarkMode,
	children,
	isButtonsHidden,
	min,
	max,
	name,
	step,
	onchangeValue
}: Props): ReactElement => {
	const { setValue, getValues } = useFormContext()

	const color = isDarkMode ? 'grays.gray1' : 'grays.gray2'
	const increase = (): void => {
		const sliderValue = getValues()[name] as number
		if (sliderValue < max) setValue(name, sliderValue + step)
		if (onchangeValue != null) onchangeValue()
	}

	const decrease = (): void => {
		const sliderValue = getValues()[name]
		if (sliderValue > min) setValue(name, sliderValue - step)
		if (onchangeValue != null) onchangeValue()
	}

	if (isButtonsHidden) return <>{children}</>

	return (
		<>
			<IconButton sx={{ padding: 0, marginRight: '8px', color }} onClick={decrease}>
				<RemoveIcon style={{ fontSize: 24 }} />
			</IconButton>
			{children}
			<IconButton sx={{ padding: 0, marginLeft: '16px', color }} onClick={increase}>
				<AddIcon style={{ fontSize: 24 }} />
			</IconButton>
		</>
	)
}
