import { InfoCardContent } from '@ui/core/InfoCardContent'
import CardLayout from '@ui/core/CardLayout'
import KeyIndicatorsCardRow from './KeyIndicatorsCardRow'
import { useTranslation } from 'react-i18next'
import LegendItem from './LegendItem'
import theme from '@ui/theme'
import { Stack } from '@mui/material'
import useKeyIndicators from '@features/details/hooks/useKeyIndicators'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { type ReactElement } from 'react'

interface Props {
	userId: string
	title: string
	hasLegend: boolean
	footer: React.ReactNode
}

export const KeyIndicatorsBar = ({ userId, title = '', hasLegend, footer = null }: Props): ReactElement => {
	const { t } = useTranslation()
	const { keyIndicators, isLoading } = useKeyIndicators({ userId })

	const getColorTextKey = (type: string): string => `keyIndicators:legend.${type}.color`
	const getTextKey = (type: string): string => `keyIndicators:legend.${type}.text`

	if (isLoading) return <LocalLoader />

	return (
		<CardLayout title={title} noDivider footer={footer} direction="row">
			<InfoCardContent>
				<KeyIndicatorsCardRow indicators={keyIndicators} />
				{hasLegend && (
					<Stack sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
						<LegendItem
							title={t('keyIndicators:legend.title')}
							color={undefined}
							text={undefined}
							colorText={undefined}
						/>
						<LegendItem
							color={theme.palette.secondary.light}
							colorText={t(getColorTextKey('good'))}
							text={t(getTextKey('good'))}
							title={undefined}
						/>
						<LegendItem
							color={theme.palette.darkBlue.light}
							colorText={t(getColorTextKey('average'))}
							text={t(getTextKey('average'))}
							title={undefined}
						/>
						<LegendItem
							color={theme.palette.warning.light}
							colorText={t(t(getColorTextKey('bad')))}
							text={t(getTextKey('bad'))}
							title={undefined}
						/>
					</Stack>
				)}
			</InfoCardContent>
		</CardLayout>
	)
}
