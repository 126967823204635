import { useForm } from 'react-hook-form'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import DetailBar from './DetailBar'
import InformationCard from './InformationCard'
import AccessRightsCard from './AccessRightsCard'
import useUserDetails from './useUserDetails'
import { RestrictionsCard } from './RestrictionsCard'
import { Container, Grid, Stack } from '@mui/material'
import { ErrorHandler } from '#helpers/error.helper'
import { OrganizationUserDetailsTrackCard } from './positionTrack/OrganizationUserDetailsTrackCard'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROUTE_ORGANIZATION_USERS } from '../../../router/routes'

export default function UserDetail(): JSX.Element {
	const { t } = useTranslation()
	const { control, getValues, errors } = useForm({ mode: 'onChange' })
	const { userId } = useParams<{ userId: string }>()
	const { defaultValues, isLoading, isSaving, saveUser, roles, availablePositions, availableOffers, managers } =
		useUserDetails()
	const handleSubmit = (): void => {
		;(async () => {
			const data = getValues()
			await saveUser(data)
		})().catch(ErrorHandler)
	}
	return (
		<Container maxWidth="xl">
			<form>
				<DetailBar
					formIsValid
					isSaving={isSaving}
					handleSubmit={handleSubmit}
					title={t('organizationUsers:details.bar.title')}
					backTooltip={t('organizationUsers:list.bar.title')}
					save={t('organizationUsers:details.bar.button.save')}
					route={ROUTE_ORGANIZATION_USERS}
				/>
				<LocalLoader withPadding load={isLoading}>
					<Grid container spacing={3} mb={3} mt={0}>
						<Grid item xs={6}>
							<Stack spacing={3}>
								<InformationCard control={control} defaultValues={defaultValues} errors={errors} managers={managers} />
								<OrganizationUserDetailsTrackCard {...{ availablePositions, userId }} />
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Stack spacing={3}>
								<AccessRightsCard control={control} defaultValues={defaultValues} roles={roles} errors={errors} />
								<RestrictionsCard
									control={control}
									defaultValues={defaultValues}
									availableOffers={availableOffers}
									availablePositions={availablePositions}
								/>
							</Stack>
						</Grid>
					</Grid>
				</LocalLoader>
			</form>
		</Container>
	)
}
