import { type ReactElement, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { LocalLoader } from '@shared/components/layout/LocalLoader'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { Container, Grid, Stack } from '@mui/material'
import { type OfferInput, useGetOfferDetailsQuery } from '@gqlTypes'
import { OfferMainCard } from './main/OfferMainCard'
import { OfferFormTopBar } from './OfferFormTopBar'
import { OfferContentCard } from './content/OfferContentCard'
import { OfferExpectedSkillsCard } from './expectedSkills/OfferExpectedSkillsCard'
import { ExitFormWarning } from '@shared/components/ExitFormWarning'
import { pick } from '#helpers/object.helper'

interface Props {
	isSaving: boolean
	onSave: (offerInput: OfferInput, keepValues: () => void) => Promise<void>
	onSaveError: () => void
	saveLabel: string
}

export const OfferForm = ({ isSaving, onSave, saveLabel, onSaveError }: Props): ReactElement => {
	const { offerId = 'new' } = useParams<{ offerId: string }>()
	const { t } = useTranslation()
	useDocumentTitle(t('tracks:details.document.title'))
	const variables = { offerId }
	const { data, loading: isLoading } = useGetOfferDetailsQuery({ variables })
	const offer = data?.getOfferDetails
	const defaultValues = {
		label: '',
		zipCode: '',
		educationLevelIds: [],
		contractDurationIds: [],
		userFunction: '',
		contentPdfPath: '',
		url: '',
		description: '',
		topHardSkillIds: [],
		topSoftSkillIds: [],
		requiredLanguageIds: [],
		startQuarterIds: [],
		organizationId: ''
	}
	const formMethods = useForm<OfferInput>({ mode: 'onChange', defaultValues })
	const {
		reset,
		formState: { isDirty }
	} = formMethods

	const onSaveSuccess = async (offerInput: OfferInput): Promise<void> => {
		const keepValues = async (): Promise<void> => {
			reset(offerInput)
		}
		await onSave(offerInput, keepValues)
	}

	useEffect(() => {
		if (offer != null) {
			reset({
				...pick(
					offer,
					'label',
					'educationLevelIds',
					'contractDurationIds',
					'userFunction',
					'contentPdfPath',
					'url',
					'description',
					'topHardSkillIds',
					'topSoftSkillIds',
					'requiredLanguageIds',
					'startQuarterIds',
					'organizationId'
				),
				zipCode: offer.location.zipCode
			})
		}
	}, [reset, offer])

	return (
		<Container maxWidth="xl" sx={{ mb: 5 }}>
			<FormProvider {...formMethods}>
				<ExitFormWarning isModified={isDirty} />
				<form>
					<OfferFormTopBar
						isSaving={isSaving}
						save={onSaveSuccess}
						onSaveError={onSaveError}
						saveLabel={saveLabel}
						isActive={offer?.isActive ?? true}
						offerId={offer?.id ?? ''}
					/>
					<LocalLoader withPadding load={isLoading}>
						<Grid container spacing={3} sx={{ mt: 0 }}>
							<Grid item xs={12} md={6}>
								<Stack spacing={3}>
									<OfferMainCard />
									<OfferContentCard contentPdfUrl={offer?.contentPdfUrl ?? ''} />
								</Stack>
							</Grid>
							<Grid item xs={12} md={6}>
								<OfferExpectedSkillsCard />
							</Grid>
						</Grid>
					</LocalLoader>
				</form>
			</FormProvider>
		</Container>
	)
}
