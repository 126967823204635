import InputFilter from '@ui/filter/InputFilter'
import PhoneInput from '@ui/core/PhoneInput'
import { filterOperation } from '@ui/filter/FilterForm'
import { useTranslation } from 'react-i18next'

export default function PhoneSelect({ keyName, operation, label }) {
	const { t } = useTranslation(['lists'])

	return (
		<InputFilter keyName={keyName || 'userInfo.mobile'} operation={operation || filterOperation.EQUAL} isTextField>
			<PhoneInput label={label || t('lists:filter.phone')} variant="standard" />
		</InputFilter>
	)
}
