import { type ReactElement } from 'react'
import AutoCompleteFilter from '@features/lists/components/Filters/AutoCompleteFilter'
import { useMeQuery } from '@gqlTypes'

interface Props {
	value: string
	onSelect: (value: string) => void
	options: { value: string; label: string }[]
	noneValueLabel: string
	label: string
	isRestricted?: boolean
}

export const EditField = ({
	value,
	onSelect,
	options,
	noneValueLabel,
	label,
	isRestricted = false
}: Props): ReactElement | null => {
	const { data, loading } = useMeQuery({ fetchPolicy: 'cache-only' })
	if (loading || data?.me == null) return null
	const isDisabled = options.length < 2 && isRestricted

	const onChange = (promotion: string): void => {
		onSelect(promotion)
	}

	return (
		<AutoCompleteFilter
			options={options}
			noneValueLabel={noneValueLabel}
			value={value}
			onChange={(_: unknown, event: { value: string }) => {
				onChange(event.value)
			}}
			label={label}
			disabled={isDisabled}
		/>
	)
}
