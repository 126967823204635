import { useTranslation } from 'react-i18next'
import { filterOperation } from '@ui/filter/FilterForm'
import InputFilter from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'
import { useGetAssessorsQuery } from '@gqlTypes'
import { type ReactElement } from 'react'

interface Props {
	keyName: string
}

export const AssessorsFilter = ({ keyName }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { data } = useGetAssessorsQuery()
	const assessors = (data?.getAssessors ?? []).map(({ id, reverseFullName }) => ({
		value: id,
		label: reverseFullName
	}))
	if (assessors.length === 0) return null

	return (
		<InputFilter keyName={keyName} operation={filterOperation.EQUAL} isAutoComplete>
			<AutoCompleteFilter
				options={assessors}
				defaultValue=""
				label={t('lists:filter.assessor.label')}
				allValueLabel={t('lists:filter.assessor.allLabel')}
				width="100%"
			/>
		</InputFilter>
	)
}
