import { type FilterInput } from '@gqlTypes'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { type FilterStore, initialState } from '@shared/store/reducers/filters.reducer'
import { setFilters as setFiltersFunction } from '@shared/store/actions/filters.actions'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorHandler } from '#helpers/error.helper'

interface LocationState {
	routeFilters?: FilterInput[]
}

interface Props {
	manualDefaultFilters?: FilterInput[]
	refetch: (variables: object) => Promise<unknown>
}

export const useDefaultFilter = ({ manualDefaultFilters, refetch }: Props): void => {
	const location = useLocation<LocationState>()
	const dispatch = useDispatch()
	const storeFilters: FilterStore = useSelector(({ filters }: { filters: FilterStore }) => filters)

	const routeDefaultFilters = location.state?.routeFilters
	const pathname = location.pathname.substring(1)
	const setFilters = (filters: FilterInput[]): void => {
		dispatch(setFiltersFunction({ filters, location: pathname }))
	}

	const currentFilters = storeFilters[pathname]
	const storeDefaultFilters = initialState[pathname]

	useEffect(() => {
		;(async () => {
			const defaultFilters = manualDefaultFilters ?? storeDefaultFilters
			const routeFilters = routeDefaultFilters ?? []

			if (routeFilters.length > 0) {
				const uniqueFilters = storeDefaultFilters.filter((f) => !routeFilters.map((rf) => rf.key).includes(f.key))
				const filters = [...uniqueFilters, ...routeFilters]
				setFilters(filters)
				await refetch({ filters })
				return
			}
			if (currentFilters.length === 0 && defaultFilters.length > 0) setFilters(defaultFilters)
		})().catch(ErrorHandler)
	}, [manualDefaultFilters, routeDefaultFilters])
}
