import { useSelector } from 'react-redux'
import { LinearProgress } from '@mui/material'

function GlobalLoaderFeedback() {
	const loading = useSelector(({ loader }) => loader.load)
	if (!loading) return null
	return <LinearProgress variant="query" color="secondary" />
}

export default GlobalLoaderFeedback
