import { useTranslation } from 'react-i18next'
import { useGetTrackOffersLabelQuery, useMeQuery } from '@gqlTypes'
import { filterOperation } from '@ui/filter/FilterForm'
import InputFilter from '@ui/filter/InputFilter'
import AutoCompleteFilter from './AutoCompleteFilter'
import { type ReactElement } from 'react'

interface Props {
	keyName: string
	exclusiveValueToMatch?: boolean
	exclusiveValue?: boolean
	exclusiveValueDefault?: string
	labelAll?: string
	isMultiple: boolean
}

export const TrackOfferFilter = ({
	keyName,
	exclusiveValueToMatch,
	exclusiveValue,
	exclusiveValueDefault,
	labelAll = '',
	isMultiple
}: Props): ReactElement | null => {
	const { t } = useTranslation()

	const { data: dataMe } = useMeQuery()
	const { data, loading } = useGetTrackOffersLabelQuery()
	if (loading || dataMe?.me == null || data?.getTrackOffersLabel == null) return null
	const offersAccess = dataMe.me.offersAccess.map(({ id, label }) => {
		return { value: id, label }
	})
	const availableOffers = data.getTrackOffersLabel.map((offer) => ({ value: offer.value, label: offer.label }))
	const hasRestriction = dataMe.me.offersAccess.length === 1

	if (hasRestriction) return null

	return (
		<InputFilter
			keyName={keyName}
			operation={isMultiple ? filterOperation.IN_ARRAY : filterOperation.EQUAL}
			isAutoComplete
			exclusiveValue={exclusiveValue}
			exclusiveValueToMatch={exclusiveValueToMatch}
			exclusiveValueDefault={exclusiveValueDefault}
			isMultiple={isMultiple}
			isBooleanCheckbox={undefined}
		>
			<AutoCompleteFilter
				options={offersAccess.length === 0 ? availableOffers : offersAccess}
				defaultValue=""
				label={t('lists:filter.offer.label')}
				allValueLabel={labelAll}
				multiple={isMultiple}
			/>
		</InputFilter>
	)
}
