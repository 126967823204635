import { useCountInterviewsRemindersQuery, useSendInterviewsRemindersMutation } from '@gqlTypes'
import { MassReminderModal } from './MassReminderModal'
import { useFilters } from '@shared/hooks/useFilters'
import { type ReactElement } from 'react'
import { useGqlSnackBar } from '@shared/gql/hooks/useGqlSnackBar'
import { searchQuery } from '@features/lists/components/Search'

interface Props {
	isOpen: boolean
	setOpen: (isOpen: boolean) => void
	interviewIds: string[]
}

export const MassReminderInterview = ({ isOpen, setOpen, interviewIds }: Props): ReactElement => {
	const { filters } = useFilters()
	const { onError, onCompleted } = useGqlSnackBar('modal:massReminder.interview.snackBar')

	const variables = { interviewIds, filters, searchQuery: searchQuery() }
	const [sendInterviewsReminderMutation, { loading: isSaving }] = useSendInterviewsRemindersMutation({
		variables,
		onError,
		onCompleted
	})
	const { data, loading: isCountLoading } = useCountInterviewsRemindersQuery({ variables })

	const { itemCount, reminderCount } = data?.countInterviewsReminders ?? { itemCount: 0, reminderCount: 0 }

	const sendReminders = async (): Promise<void> => {
		await sendInterviewsReminderMutation()
		setOpen(false)
	}

	return (
		<MassReminderModal
			isOpen={isOpen}
			setOpen={setOpen}
			isLoading={isCountLoading}
			isSaving={isSaving}
			reminderCount={reminderCount}
			itemCount={itemCount}
			sendReminders={sendReminders}
			translationKey="interview"
		/>
	)
}
