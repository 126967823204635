import { OfferCreator } from './details/OfferCreator.page'
import { OfferUpdator } from './details/OfferUpdator.page'
import { JobMatchingBrowser } from './jobMatching/browser/JobMatchingBrowser.page'
import { JobMatchingList } from './jobMatching/list/JobMatchingList.page'
import { OfferList } from './list/OfferList.page'

export enum OffersPath {
	list = '/offers',
	create = '/offers/new',
	update = '/offers/:offerId',
	matchings = '/offers/:trackOfferId?/matchings/:statusId?',
	matchingBrowser = '/offers/:trackOfferId/matchings/:jobMatchingId/status/:statusId/user/:userId'
}
export const offersRoutes = [
	{
		path: OffersPath.matchings,
		children: <JobMatchingList />
	},
	{
		path: OffersPath.matchingBrowser,
		children: <JobMatchingBrowser />
	},
	{
		path: OffersPath.list,
		children: <OfferList />
	},
	{
		path: OffersPath.list,
		children: <OfferList />
	},
	{
		path: OffersPath.create,
		children: <OfferCreator />
	},
	{
		path: OffersPath.update,
		children: <OfferUpdator />
	}
]
