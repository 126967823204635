import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { history } from '../../store/MyStoreProvider'
import { generateRoute } from '../../../router/routes'

function ButtonRouter(props) {
	const { to } = props
	return (
		<Button
			variant="contained"
			color="primary"
			{...props}
			onClick={(e) => {
				e.preventDefault()
				history.push(generateRoute(to))
			}}
		/>
	)
}

ButtonRouter.propTypes = {
	to: PropTypes.string.isRequired
}

export default ButtonRouter
