import { Tab, Tabs } from '@mui/material'
import useRoleContext from '@shared/hooks/useRoleContext'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { TopBar } from '@ui/core/TopBar'
import { useMeQuery } from '@gqlTypes'

export default function TabBar({ infos, backRoute, backToolTip }) {
	const { data } = useMeQuery({ fetchPolicy: 'cache-only' })
	const isBrandFeedbackAnonymous = data?.me?.organization?.isBrandFeedbackAnonymous ?? false

	const roleContext = useRoleContext()
	const { t } = useTranslation(['translation'])
	const location = useLocation()

	const tabs = infos.subMenuTabs.reduce((acc, nextTab) => {
		if (isBrandFeedbackAnonymous && nextTab.id.includes('returnOrga')) return acc
		return roleContext.tabs.candidate.includes(nextTab.id) ? [...acc, nextTab] : acc
	}, [])

	let currentTabIndex = tabs.findIndex((tab) => tab.path === location.pathname)
	const ArrayOfIsInfoTab = tabs.map((tab) => tab.id.endsWith('info'))
	const hasInfoTab = ArrayOfIsInfoTab.includes(true)
	if (currentTabIndex === -1 && hasInfoTab) currentTabIndex = ArrayOfIsInfoTab.indexOf(true)

	return (
		<TopBar
			title={infos.integrationInfo?.userInfo?.firstName + ' ' + infos.integrationInfo?.userInfo?.lastName}
			backRoute={backRoute}
			tooltipTitle={backToolTip}
		>
			<Tabs value={currentTabIndex} centered indicatorColor="secondary">
				{tabs.map((tab) => (
					<Tab
						iconPosition="start"
						key={tab.id}
						component={Link}
						to={tab.path}
						icon={tab.icon}
						label={t(tab.translationKey)}
						sx={{ color: '#0A2E56' }}
					/>
				))}
			</Tabs>
		</TopBar>
	)
}
