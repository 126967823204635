import { useState } from 'react'
import { useDocumentTitle } from '@shared/hooks/useDocumentTitle'
import { useTranslation } from 'react-i18next'
import StudentsFilterBar from './StudentsFilterBar'
import useCheckRoleSecurity from '@shared/hooks/useRoleSecurity'
import { ScreenId, useGetStudentsListQuery } from '@gqlTypes'
import { useFilters } from '@shared/hooks/useFilters'
import { Container } from '@mui/material'
import { CardList } from '@features/lists/components/CardList'
import { StudentCard } from './StudentCard'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import { useDefaultFilter } from '@shared/hooks/useDefaultFilter'
import { searchQuery } from '@features/lists/components/Search'
import { useOrganization } from '@domains/organization/useOrganization'

export default function Students() {
	const { t } = useTranslation(['lists', 'students'])
	useDocumentTitle(t('students:title'))
	useCheckRoleSecurity(ScreenId.Students)
	const { filters } = useFilters()

	const [isAllRowsSelected, setIsAllRowsSelected] = useState(false)
	const { organizationId } = useOrganization()

	const variables = { organizationId, limit: 12, filters, searchQuery: searchQuery() }
	const { loading, data, refetch, fetchMore } = useGetStudentsListQuery({ variables })
	useDefaultFilter({ refetch })

	let nodes = []
	let cursor = ''
	let hasNextPage = false
	let count = 0
	if (data && data.getStudentsList) ({ nodes, cursor, hasNextPage, count } = data.getStudentsList)

	return (
		<Container maxWidth="xl">
			<CardList
				emptyStateLabel={t('students:dataGrid.empty')}
				renderItem={(item, isSelected, selectRow) => (
					<StudentCard key={item.id} position={item} isSelected={isSelected} selectRow={selectRow} />
				)}
				isAllRowsSelected={isAllRowsSelected}
				setIsAllRowsSelected={setIsAllRowsSelected}
				dataList={nodes}
				isLoading={loading}
				fetchMore={fetchMore}
				cursor={cursor}
				hasNextPage={hasNextPage}
				dataTestId="student-datatable"
				title={t('students:title', { count })}
				Icon={RecordVoiceOverIcon}
				refetch={refetch}
				hasSearch
				toolbar={
					<StudentsFilterBar isAllRowsSelected={isAllRowsSelected} refetch={refetch} dataList={nodes} count={count} />
				}
			/>
		</Container>
	)
}
