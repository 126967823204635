import { type ReactElement } from 'react'
import MaterialSlider from '@mui/material/Slider'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { SliderButtons } from './SliderButtons'
import theme from '../theme'
import { Controller, type ControllerRenderProps, useFormContext } from 'react-hook-form'
import { Box, Stack } from '@mui/material'
import { type ReactHookFormRules } from '#types/ReactHookFormRules'
import { getValue } from '#helpers/object.helper'

const READONLY_CLASSNAME = 'emageme-slider-readonly'

interface Props {
	name: string
	min: number
	max: number
	markers?: React.ReactNode
	step?: number
	isReadOnly?: boolean
	baseScore?: number
	isDarkMode?: boolean
	defaultValue?: number
	onChangeValue?: () => void
	value?: number
	rules?: ReactHookFormRules
	subLabels?: string[]
}

export const FormSlider = ({
	name,
	min,
	max,
	markers,
	step = 10,
	isReadOnly = true,
	baseScore,
	isDarkMode = false,
	defaultValue,
	value,
	rules,
	onChangeValue,
	subLabels = []
}: Props): ReactElement => {
	const { t } = useTranslation()
	const { control, watch } = useFormContext()
	const actualValue = value ?? watch(name)
	const isEvaluated = actualValue !== 0
	const subLabelValue = Math.round(actualValue / (baseScore ?? 1)).toString() ?? '0'
	const railColor = isDarkMode ? theme.palette.grays.gray1 : theme.palette.grays.gray2

	const styleProps = {
		isEvaluated,
		railColor,
		isDarkMode,
		isReadOnly,
		markers
	}
	const { classes: sliderClasses } = useSliderStyle(styleProps)
	const { errors } = useFormContext()
	const error = getValue(errors, name)
	const subLabelIndex = parseInt(subLabelValue ?? '0')
	const defaultSubLabel = t(`sliders:subLabel.${subLabelValue}`)
	const subLabel = subLabelIndex > 0 ? subLabels[subLabelIndex - 1] ?? defaultSubLabel : defaultSubLabel

	return (
		<Box sx={{ paddingTop: '35px' }}>
			<Box style={{ display: 'flex' }}>
				<SliderButtons
					isDarkMode={isDarkMode}
					isButtonsHidden={isReadOnly}
					name={name}
					min={min}
					max={max}
					step={step}
					onchangeValue={onChangeValue}
				>
					<span style={{ width: '100%', position: 'relative' }}>
						{markers}
						<Box style={{ width: '100%' }}>
							<Controller
								name={name}
								control={control}
								defaultValue={defaultValue}
								rules={rules}
								render={(props: ControllerRenderProps) => (
									<MaterialSlider
										{...props}
										marks={!isReadOnly}
										onChange={(_, value) => {
											if (value === actualValue) return
											props.onChange(value)
											if (onChangeValue != null) onChangeValue()
										}}
										value={value ?? props.value}
										disabled={isReadOnly}
										classes={sliderClasses}
										className={isReadOnly ? READONLY_CLASSNAME : ''}
										step={step}
										max={max ?? 5}
										min={min ?? 0}
										color="secondary"
									/>
								)}
							/>
						</Box>
					</span>
				</SliderButtons>
			</Box>
			<Stack direction="row" justifyContent="space-between">
				<Typography sx={{ ml: isReadOnly ? 0 : '34px' }}>{subLabel}</Typography>
				<Typography variant="body2" color="error">
					{error?.message}
				</Typography>
			</Stack>
		</Box>
	)
}

interface StyleProps {
	isEvaluated: boolean
	railColor: string
	isDarkMode: boolean
	isReadOnly: boolean
	markers: React.ReactNode
}

const useSliderStyle = makeStyles<StyleProps>()((theme, { isEvaluated, railColor, isDarkMode, isReadOnly }) => ({
	root: {
		height: 1,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	rail: {
		color: railColor,
		height: 6,
		opacity: isDarkMode ? 1 : 0.38
	},
	track: {
		height: 5,
		borderRadius: 6,
		color: theme.palette.secondary.main
	},
	thumb: {
		marginRight: 0,
		marginLeft: 5,
		marginTop: 0,
		width: 22,
		height: 22,
		backgroundColor: isEvaluated ? theme.palette.secondary.main : railColor,

		'&:not(.MuiSlider-active)': {
			display: 'none',
			pointerEvents: 'none'
		},

		'&[data-index="0"]': {
			display: 'flex'
		}
	},
	colorPrimary: {
		[`&$disabled.${READONLY_CLASSNAME}`]: {
			color: `${theme.palette.primary.main}`
		}
	},
	colorSecondary: {
		[`&$disabled.${READONLY_CLASSNAME}`]: {
			color: `${theme.palette.secondary.main}`
		}
	},
	disabled: {
		'& .MuiSlider-thumb': {
			display: 'none !important'
		}
	},
	mark: {
		opacity: isReadOnly ? 0 : 1,
		backgroundColor: '#ECE4E4',
		width: 12,
		height: 12,
		borderRadius: '100%'
	},
	markActive: {
		backgroundColor: theme.palette.secondary.main
	}
}))
