import PropTypes from 'prop-types'

import { InfoCardContent } from '@ui/core/InfoCardContent'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import CardLayout from '@ui/core/CardLayout'
import { useTranslation } from 'react-i18next'

export default function MessageInfoCard(props) {
	const { messages } = props
	const { t } = useTranslation()

	return (
		<CardLayout direction="row">
			<InfoCardContent>
				<Typography>{t('translation:discussionDetailPage.exchanged', { count: messages.length })}</Typography>
			</InfoCardContent>
			<InfoCardContent>
				<Typography>
					{t('translation:discussionDetailPage.first', {
						date: moment(messages[0].creationDate.toDate()).format('DD/MM/yyyy')
					})}
				</Typography>
			</InfoCardContent>
			<InfoCardContent>
				<Typography>
					{t('translation:discussionDetailPage.last', {
						date: moment(messages[messages.length - 1].creationDate.toDate()).format('DD/MM/yyyy')
					})}
				</Typography>
			</InfoCardContent>
		</CardLayout>
	)
}

MessageInfoCard.propTypes = {
	messages: PropTypes.array.isRequired
}
