import MaterialTextField from '@mui/material/TextField'
import { makeStyles } from 'tss-react/mui'
import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

const useStyles = makeStyles()(() => ({
	root: {},

	elevated: {
		display: ({ fullWidth }) => (fullWidth ? 'block' : 'inline-block'),
		padding: '5px 10px 0',
		borderRadius: 5
	},

	elevatedLabel: {},

	elevatedTextfield: {
		border: 'none',

		'& .MuiInputBase-root::after': {
			border: 'none'
		},

		'& .MuiInputBase-root::before': {
			border: 'none'
		}
	}
}))

export default function TextField(props) {
	const { classes } = useStyles(props)

	const { invisible, merged, variant, onChange, value, isBlurChange, ...materialProps } = props
	const [localValue, setLocalValue] = useState(value)

	if (isBlurChange) {
		materialProps.onBlur = () => onChange(localValue)
		materialProps.value = localValue
		materialProps.onChange = (e) => setLocalValue(e.target.value)
	} else {
		materialProps.onChange = onChange
		materialProps.value = value
	}

	useEffect(() => {
		setLocalValue(value)
	}, [value])

	let sx = {}
	if (merged === 'right' || merged === 'both') {
		sx = {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0
		}
	}
	if (merged === 'left' || merged === 'both') {
		sx = {
			...sx,
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0
		}
	}
	if (invisible) {
		sx = { ...sx, borderWidth: 0 }
	}

	if (variant === 'elevated') {
		return (
			<Paper elevation={2} className={classes.elevated}>
				<MaterialTextField
					{...materialProps}
					className={clsx(classes.root, classes.elevatedTextfield)}
					InputProps={{ disableUnderline: true, ...props.inputProps }}
					autoCapitalize="none"
					variant="standard"
					sx={{ '& fieldset': sx }}
				/>
			</Paper>
		)
	}

	return <MaterialTextField className={classes.root} {...materialProps} sx={{ '& fieldset': sx }} />
}

TextField.defaultProps = {
	...MaterialTextField.defaultProps,
	invisible: false
}

TextField.propTypes = {
	...MaterialTextField.propTypes,
	merged: PropTypes.oneOf([undefined, 'left', 'right', 'both']),
	variant: PropTypes.oneOf(['filled', 'outlined', 'standard', 'elevated']),
	invisible: PropTypes.bool
}
