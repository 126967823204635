interface ObjectWithId {
	id: string
}

export const computeAverage = (values: number[]): number => {
	if (values.length === 0) return 0

	const valuesSum = values.reduce((sum, score) => sum + score, 0)
	return valuesSum / values.length
}

export function indexById<
	T extends {
		id: string
	}
>(array: T[]): Record<string, T> {
	return array.reduce((obj, item) => ({ ...obj, [item.id]: item }), {})
}

export function indexPropertyById<T extends ObjectWithId, K extends keyof T>(array: T[], key: K): Record<string, T[K]> {
	return array.reduce((obj, item) => ({ ...obj, [item.id]: item[key] }), {})
}

export function indexByProperty<T extends ObjectWithId, K extends keyof T>(array: T[], key: K): Record<string, T> {
	return array.reduce((obj, item) => ({ ...obj, [item[key] as unknown as string]: item }), {})
}

export function toState(array: string[]): Record<string, boolean> {
	return Object.fromEntries(array.map((value) => [value, true]))
}

export const addIdFromIndex = <T extends object>(array: T[]): ({ id: string } & T)[] => {
	return array.map((obj, index) => ({ id: index.toString(), ...obj }))
}
