import { useState } from 'react'
import { useSnackbar } from 'notistack'
import {
	type InterviewAnswerInput,
	type InterviewSoftSkillInput,
	type UpdateInterviewInput,
	useUpdateInterviewMutation
} from '@gqlTypes'
import { Severities } from '../../../constants/Severities'
import { useTranslation } from 'react-i18next'
import { ScoreCalculation } from '#types/ScoreCalculation'
import { ErrorHandler } from '#helpers/error.helper'
import { type InterviewFormInput } from '../types/interviewsForm.type'

interface Props {
	interviewId: string
	isInterviewSendingFeedback: boolean
	getValues: () => InterviewFormInput
	trigger: (name?: string | string[] | undefined) => Promise<boolean>
}

interface UseUpdateInterview {
	sendData: (data: InterviewFormInput) => void
	setIsShareModalOpen: (value: boolean) => void
	isShareModalOpen: boolean
	updateDetails: (data: UpdateInterviewPayload) => Promise<void>
	handleSave: () => void
	saveDraft: (isDisplayFeedback?: boolean) => void
	save: (data: InterviewFormInput) => void
	isSaving: boolean
	isSavingDraft: boolean
}

interface UpdateInterviewPayload {
	field: string
	value: string | number | boolean | Date
}

export const useUpdateInterview = ({
	interviewId,
	isInterviewSendingFeedback,
	getValues,
	trigger
}: Props): UseUpdateInterview => {
	const [updateInterview] = useUpdateInterviewMutation()
	const { enqueueSnackbar } = useSnackbar()
	const [isShareModalOpen, setIsShareModalOpen] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [isSavingDraft, setIsSavingDraft] = useState(false)
	const { t } = useTranslation()

	const buildSoftSkills = (
		skillComments: Record<string, string>,
		skillScores: Record<string, number>
	): InterviewSoftSkillInput[] => {
		const skillIds = Object.keys(skillScores ?? {})
		return skillIds.map((id) => ({ id, score: skillScores[id] ?? 0, comment: skillComments[id] ?? '' }))
	}

	const sendData = (data: InterviewFormInput, isDisplayFeedback: boolean = true): void => {
		;(async () => {
			const {
				comment,
				isSendingFeedback,
				answers,
				isDraft,
				globalManualScore,
				globalCalculatedScore,
				skillComments,
				skillScores,
				scoreCalculation
			} = data
			const validatedScore = scoreCalculation === ScoreCalculation.Manual ? globalManualScore / 4 : 0
			const calculatedScore = scoreCalculation === ScoreCalculation.Average ? globalCalculatedScore / 4 : 0

			const softSkills = buildSoftSkills(skillComments, skillScores)
			const answersArray: InterviewAnswerInput[] = Object.entries(answers ?? {}).map(([id, answer]) => ({
				id,
				answer: answer ?? ''
			}))

			const interviewInput: UpdateInterviewInput = {
				comment,
				calculatedScore,
				validatedScore,
				softSkills,
				answers: answersArray,
				isDraft
			}
			const variables = { interviewId, interviewInput, isSendingFeedback }
			try {
				await updateInterview({ variables })
				if (isDisplayFeedback) {
					enqueueSnackbar(t('translation:interviewDetailPage.submitInterview.success'), { variant: Severities.success })
				}
			} catch (error) {
				enqueueSnackbar(t('translation:interviewDetailPage.submitInterview.error'), { variant: Severities.error })
			}
			setIsSaving(false)
			setIsSavingDraft(false)
		})().catch(ErrorHandler)
	}

	const save = (data: InterviewFormInput): void => {
		setIsSaving(true)
		sendData({ ...data, isDraft: false })
	}

	const updateDetails = async ({ field, value }: UpdateInterviewPayload): Promise<void> => {
		if (field === 'targetDate' && typeof value !== 'boolean') value = new Date(value)
		const variables = { interviewId, interviewInput: { [field]: value } }
		await updateInterview({ variables })
	}

	const handleSave = (): void => {
		;(async () => {
			const isValid = await trigger()
			if (!isValid) {
				setIsSaving(false)
				enqueueSnackbar(t('translation:interviewDetailPage.submitInterview.validationError'), {
					variant: Severities.error
				})
				return
			}
			isInterviewSendingFeedback ? setIsShareModalOpen(true) : save(getValues())
		})().catch(ErrorHandler)
	}

	const saveDraft = (isDisplayFeedback: boolean = true): void => {
		setIsSavingDraft(true)
		const data = { ...getValues(), isDraft: true }
		sendData(data, isDisplayFeedback)
	}

	return {
		handleSave,
		sendData,
		setIsShareModalOpen,
		isShareModalOpen,
		updateDetails,
		saveDraft,
		save,
		isSaving,
		isSavingDraft
	}
}
