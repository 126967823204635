import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { generateRoute } from '../../../../router/routes'
import { IconButton, Stack, Tooltip, Typography } from '@mui/material'
import { OffersPath } from '../../OffersRouter'
import CircleIcon from '@mui/icons-material/Circle'
import EditIcon from '@mui/icons-material/Edit'
import { useHistory } from 'react-router-dom'

interface Props {
	isHovered: boolean
	isArchived: boolean
	offerId: string
}

export const OfferCardActions = ({ isHovered, isArchived, offerId }: Props): ReactElement => {
	const { t } = useTranslation()
	const history = useHistory()
	const statusColor = isArchived ? 'error' : 'secondary'
	const iconColor = isHovered ? 'grays.gray2' : 'transparent'
	return (
		<Stack direction="row" alignItems="center" gap={1}>
			<Tooltip title={t('students:actions.edit.tooltip')}>
				<IconButton
					onClick={() => {
						history.push(generateRoute(OffersPath.update, { offerId }) as string)
					}}
				>
					<EditIcon sx={{ color: iconColor }} />
				</IconButton>
			</Tooltip>
			<Typography variant="body2" color={statusColor}>
				{t(`offers:active.${!isArchived}`)}
			</Typography>
			<CircleIcon sx={{ width: 10, height: 10 }} color={statusColor} />
		</Stack>
	)
}
