import { type ReactElement } from 'react'
import {
	type TrackType,
	useSendUserIntegrationsReminderMutation,
	useCountUserIntegrationsReminderQuery,
	type IntegrationCategory
} from '@gqlTypes'
import { MassReminderModal } from './MassReminderModal'
import { useFilters } from '@shared/hooks/useFilters'
import { useGqlSnackBar } from '@shared/gql/hooks/useGqlSnackBar'
import { searchQuery } from '@features/lists/components/Search'

interface Props {
	isOpen: boolean
	setOpen: (isOpen: boolean) => void
	userIntegrationsIds: string[]
	trackType: TrackType
	integrationCategory: IntegrationCategory
}

export const MassReminderIntegration = ({
	isOpen,
	setOpen,
	userIntegrationsIds,
	trackType,
	integrationCategory
}: Props): ReactElement => {
	const { filters } = useFilters()
	const { onError, onCompleted } = useGqlSnackBar('modal:massReminder.integration.snackBar')

	const variables = { userIntegrationsIds, trackType, filters, integrationCategory, searchQuery: searchQuery() }
	const [sendUserIntegrationsReminderMutation, { loading: isSaving }] = useSendUserIntegrationsReminderMutation({
		variables,
		onError,
		onCompleted
	})
	const { data, loading: isLoading } = useCountUserIntegrationsReminderQuery({ variables })
	const { itemCount, reminderCount } = data?.countUserIntegrationsReminder ?? { itemCount: 0, reminderCount: 0 }

	const sendReminders = async (): Promise<void> => {
		await sendUserIntegrationsReminderMutation()
		setOpen(false)
	}

	return (
		<MassReminderModal
			isOpen={isOpen}
			setOpen={setOpen}
			isLoading={isLoading}
			isSaving={isSaving}
			reminderCount={reminderCount}
			itemCount={itemCount}
			sendReminders={sendReminders}
			translationKey="integration"
		/>
	)
}
