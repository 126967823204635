import { TableLayout } from '@ui/core/TableLayout'
import { InfoLayoutLabel } from '@ui/core/InfoLayoutLabel'
import { InfoLayoutValue } from '@ui/core/InfoLayoutValue'
import BooleanInput from '@ui/core/BooleanInput'
import { useTranslation } from 'react-i18next'
import CardLayout from '@ui/core/CardLayout'
import InfoValue from '../../../features/details/components/informationsTab/InfoValue'
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront'
import InfoIcon from '@mui/icons-material/Info'
import { Button, TableRow } from '@mui/material'
import { useMeQuery } from '@gqlTypes'
import { type ReactElement } from 'react'

interface Props {
	isEnglish: boolean
	comment: string
	visioLink: string
}

export const StaticSpecificInfoCard = ({ isEnglish, comment, visioLink }: Props): ReactElement | null => {
	const { t } = useTranslation()
	const { loading: isMeLoading, data } = useMeQuery()
	if ((!isEnglish && (comment === '' || comment === null) && visioLink === '') || isMeLoading) return null

	const isExternal = data?.me?.role?.isExternal ?? true

	return (
		<CardLayout title={t('translation:candidates.informations.cardTitle.generalInfos')}>
			<TableLayout>
				<TableRow>
					<InfoLayoutLabel icon={<VideoCameraFrontIcon />} verticalAlign={'middle'}>
						{t('translation:candidates.informations.visioLink')}
					</InfoLayoutLabel>
					<InfoLayoutValue>
						<Button variant="contained" color="secondary" href={visioLink} target="_blank">
							{t('translation:candidates.informations.visioLinkLabel')}
						</Button>
					</InfoLayoutValue>
				</TableRow>

				{isExternal ? null : (
					<InfoValue icon={<InfoIcon />} title={t('translation:candidates.informations.note')} value={comment} />
				)}
				{isEnglish ? (
					<TableRow>
						<InfoLayoutLabel>{t('translation:candidates.informations.parcour')}</InfoLayoutLabel>
						<InfoLayoutValue>
							<BooleanInput value={isEnglish} label={t('translation:candidates.informations.english')} readOnly />
						</InfoLayoutValue>
					</TableRow>
				) : null}
			</TableLayout>
		</CardLayout>
	)
}
