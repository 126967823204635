import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import { TableCell, type TableCellProps } from '@mui/material'
import { type ReactElement, type ReactNode } from 'react'

const useStyle = makeStyles()(() => ({
	content: {
		'& ul': {
			padding: 0,
			listStyleType: 'none',
			margin: 0
		}
	}
}))

interface Props extends TableCellProps {
	children: ReactNode
}

export const InfoLayoutValue = ({ children, ...props }: Props): ReactElement => {
	const { classes } = useStyle()

	return (
		<TableCell sx={{ width: '100%' }} {...props}>
			<Typography component="div" className={classes.content}>
				{children}
			</Typography>
		</TableCell>
	)
}
