import { type ScreenAccesses, type BusinessRole } from '@gqlTypes'
import { useReduxSelector } from './useReduxSelector'

interface UseRole {
	hasPositionAccess: boolean
	hasOfferAccess: boolean
	isExternal: boolean
	isAdmin: boolean
	screens: ScreenAccesses
}

export const useRole = (): UseRole => {
	const role: BusinessRole = useReduxSelector(({ role }) => role)
	const screens = role.screens
	const hasOfferAccess = screens.candidates.hasReadAccess
	const hasPositionAccess =
		screens.employees.hasReadAccess || screens.students.hasReadAccess || screens.communityMembers.hasReadAccess
	const isExternal = role.isExternal
	const isAdmin = role.roleType === 'admin'

	return { hasPositionAccess, hasOfferAccess, isExternal, isAdmin, screens }
}
