import { useMeQuery } from '@gqlTypes'
import { Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export default function IndicatorComments({ comments }) {
	const { classes } = useStyle()
	const { data } = useMeQuery()
	const isBrandFeedbackAnonymous = data?.me?.organization?.isBrandFeedbackAnonymous ?? false

	if (!comments || comments.length === 0) return null

	return (
		<>
			{comments.map((comment, index) => {
				const subtitle = isBrandFeedbackAnonymous
					? `${comment.creationDate ?? ''}`
					: `${comment.lastname} ${comment.firstname} - ${comment.creationDate ?? ''}`
				return (
					<div key={index + comment.text}>
						<Typography style={{ wordBreak: 'break-word' }}>&quot;{comment.text}&quot;</Typography>
						<Typography className={classes.subtitle}>{subtitle}</Typography>
					</div>
				)
			})}
		</>
	)
}

const useStyle = makeStyles()((theme) => ({
	subtitle: {
		color: theme.palette.grays.gray2,
		marginBottom: 7
	}
}))
