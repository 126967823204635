import Marker from './Marker'
import { useTranslation } from 'react-i18next'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

export default function SelfEvaluationMarker({ value, selfEvaluatedDetailedLabel }) {
	const { t } = useTranslation()
	const title = t('translation:candidates.profile.selfEvaluation')

	const label =
		selfEvaluatedDetailedLabel != null && selfEvaluatedDetailedLabel !== ''
			? `${title} :\n${selfEvaluatedDetailedLabel}`
			: title

	return <Marker label={label} value={value} icon={<PersonOutlineOutlinedIcon fontSize="inherit" />} />
}
