import { makeStyles } from 'tss-react/mui'
import ButtonRouter from '../shared/components/layout/ButtonRouter'
import { ROUTE_HOME } from '../router/routes'
import { useTranslation } from 'react-i18next'
import { useDocumentTitle } from '../shared/hooks/useDocumentTitle'

const useStyles = makeStyles()(() => ({
	root: {
		padding: '1rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	image: {
		marginBottom: '20px',
		height: '70vh',
		maxHeight: '522px'
	}
}))

function NotFound() {
	const { classes } = useStyles()
	const { t } = useTranslation()

	useDocumentTitle(t('translation:notFound.title'))

	return (
		<div className={classes.root}>
			<img className={classes.image} alt="Page not found" src="/assets/img/404.png" />
			<ButtonRouter to={ROUTE_HOME}>{t('notFound.backHome')}</ButtonRouter>
		</div>
	)
}

export default NotFound
