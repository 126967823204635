import { useEffect, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { userService } from '../../../services/Services'

export default function useKeyIndicators({ userId }) {
	const errorHandler = useErrorHandler()

	const [isLoading, setIsLoading] = useState(true)
	const [keyIndicators, setKeyIndicators] = useState(null)

	// Get data for softkills indicators
	useEffect(() => {
		if (userId) {
			setIsLoading(true)
			userService
				.getIndicators(userId)
				.then((indicators) => {
					setKeyIndicators(indicators)
					setIsLoading(false)
				})
				.catch(errorHandler)
		}
	}, [errorHandler, userId])

	return {
		isLoading,
		keyIndicators
	}
}
